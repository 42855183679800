<div>
  <input type="text" id="myInput" (keyup)="applyFilter($event)" placeholder="Buscar">
  <table *ngIf="display" mat-table [dataSource]="dataSource3" class="mat-elevation-z8">

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef> Código </th>
      <td mat-cell *matCellDef="let element"> {{element.code}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="displayed">
      <th mat-header-cell *matHeaderCellDef> Exhibición </th>
      <td mat-cell *matCellDef="let element">
        <app-check-option [causes]="causes" [context]="context" [plural_context]="plural_context"
          [actualPlaceId]="actualPlaceId" [element]="element" element_type="displayed"></app-check-option>
      </td>
    </ng-container>

    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef> Tarjetón </th>
      <td mat-cell *matCellDef="let element">
        <app-check-option [causes]="causes" [context]="context" [plural_context]="plural_context"
          [actualPlaceId]="actualPlaceId" [element]="element" element_type="card"></app-check-option>
      </td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef> Imagen </th>
      <td mat-cell *matCellDef="let element">
        <app-btn-image [context]="context" [plural_context]="plural_context"
          [actualPlaceId]="actualPlaceId" [element]="element" (eventLoad)="getData()"></app-btn-image>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsData"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsData;"></tr>
  </table>
  <!-- <mat-paginator>
  </mat-paginator> -->
  <div role="group" class="mat-paginator">
    <div class="mat-paginator-outer-container">
      <div class="mat-paginator-container">
        <div class="custom-paginator">
          {{countData}} elementos.
        </div>
      </div>
    </div>
  </div>

</div>


<app-spinner [spinner]="spinner"></app-spinner>