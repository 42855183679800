<div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
    <ngx-dropzone-label>
        <p>Arrastra tus imágenes o haz click para seleccionar.</p>
    </ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true"
        (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
</div>
<button class="qualify-btn">Aceptar</button>
