<!-- <div class="headTitle">
    <h1 class="title-state">Dashboard <b class="bColor">Foto de Éxito</b></h1>
    <div class="logo" routerLink="/v4">
        <img src="assets/svg/logo-entel.svg">
    </div>
</div> -->


<div class="row filter-header">
    <div class="col">
        <h6 style="font-weight: bold; color: rgb(48, 47, 47);">Filtro</h6>
    </div>
    <div class="col formField">
        <form class="example-form">
            <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" placeholder="Mes evaluado" aria-label="Ingresa" matInput [formControl]="metricForm"
                    [matAutocomplete]="auto2">
                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                    <mat-option *ngFor="let element of actualStatSelector" [value]="element.name">
                        {{element.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="metricForm.setValue('')">clear</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <div class="name-content" *ngIf="showMetricForm">
        <div style="margin-left: 10px;"></div>
        <form class="store-select">
            <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" placeholder="Selecciona PDV" aria-label="Ingresa" matInput [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="optionSelected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name" [id]="option.id">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="myControl.setValue('')" (click)="clear()">clear</mat-icon>
            </mat-form-field>
        </form>
        <div *ngIf="lastUpdateFlag" class="lastUpdate">
            <p>Fecha de evaluación : <span>{{lastUpdate}}</span></p>
        </div>
    </div>
</div>


<div *ngIf="showCard" class="show-content">

    <div class="row chart-card" style="display: flex;">
        <div style="flex: 3; margin-right: 10px;">
            <div class="card card-adjust">
                <div class="row" style="display: flex; justify-content: flex-start;">
                    <div class="col" style="text-align: left; width: 80%;">
                        <h6 style="color: #002eff; font-weight: bold;">Detalle de Evaluación</h6>
                    </div>
                    <div class="info-icon" (mouseover)="shoTooltipTableMarks = true"
                            (mouseleave)="shoTooltipTableMarks = false">
                            <img src="assets/resources/Recurso7@3x.png" alt="Ícono de información" style="width: 30px;">
                            <div class="tooltip" *ngIf="shoTooltipTableMarks">
                                Tabla indica las notas actuales de la tienda.
                            </div>
                        </div>
                </div>
                <div class="row" style="padding: 10px">
                    <table mat-table [dataSource]="dataTable" class="mat-elevation-z8">

                        <!-- Equipos Column -->
                        <ng-container matColumnDef="devices">
                            <th class="table-mark-th" mat-header-cell *matHeaderCellDef>
                                Equipos </th>
                            <td class="table-mark-td" mat-cell *matCellDef="let element">
                                <b>{{element.devices}}%</b>
                            </td>
                        </ng-container>

                        <!-- Hogar Column -->
                        <ng-container matColumnDef="home">
                            <th class="table-mark-th" mat-header-cell *matHeaderCellDef>
                                Hogar </th>
                            <td class="table-mark-td-color" mat-cell *matCellDef="let element">
                                <b>{{element.home}}%</b>
                            </td>
                        </ng-container>

                        <!-- Accesorios Column -->
                        <ng-container matColumnDef="accesories">
                            <th class="table-mark-th" mat-header-cell *matHeaderCellDef>
                                Accesorios </th>
                            <td class="table-mark-td" mat-cell *matCellDef="let element">
                                <b>{{element.accesories}}%</b>
                            </td>
                        </ng-container>

                        <!-- Imagen Column -->
                        <ng-container matColumnDef="image">
                            <th class="table-mark-th" mat-header-cell *matHeaderCellDef>
                                Imagen </th>
                            <td class="table-mark-td-color" mat-cell *matCellDef="let element">
                                <b>{{element.image}}%</b>
                            </td>
                        </ng-container>

                        <!-- Nota Column -->
                        <ng-container matColumnDef="mark">
                            <th class="table-mark-th" mat-header-cell *matHeaderCellDef>
                                Nota </th>
                            <td class="table-mark-td" mat-cell *matCellDef="let element">
                                <b>{{element.mark}}%</b>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayTable"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayTable;"></tr>
                    </table>
                </div>

            </div>
        </div>
        <div style="flex: 3; margin-right: 10px">
            <div class="card card-adjust">
                <h6 style="color: #002eff; padding-left: 15px;"><b>Histórico de notas</b></h6>
                <div *ngIf="showCharts">
                    <canvasjs-chart [options]="markHistory" [styles]="{width: '100%', height: '165px'}">
                    </canvasjs-chart>
                    <div class="info-icon" (mouseover)="showTooltipHistoryChart = true"
                        (mouseleave)="showTooltipHistoryChart = false">
                        <img src="assets/resources/Recurso7@3x.png" alt="Ícono de información" style="width: 30px;">
                        <div class="tooltip" *ngIf="showTooltipHistoryChart">
                            Nota final de la tienda de los últimos 6 meses.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="flex: 6;">
            <div class="card card-adjust second">
                <h6 style="color: #002eff; padding-left: 10px"><b>Notas franquicia</b></h6>
                <div *ngIf="showCharts">
                    <canvasjs-chart [options]="chartOptionsJs" [styles]="{width: '100%', height:'165px'}">
                    </canvasjs-chart>
                    <div class="info-icon" (mouseover)="showTooltipFranquiseChart = true"
                        (mouseleave)="showTooltipFranquiseChart = false">
                        <img src="assets/resources/Recurso7@3x.png" alt="Ícono de información" style="width: 30px;">
                        <div class="tooltip" *ngIf="showTooltipFranquiseChart">
                            Nota final de otras tiendas de la franquicia en el último mes.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-image">
        <div class="row content_image">
            <!-- RESUME IMAGES -->
            <div *ngFor="let photo of currentImages let i=index" style="position: relative; margin-left: 10px;">
                <img style="cursor: pointer; height: 100px; width: 150px; border-radius: 10px;" [src]="photo.image"
                    (click)="openImageDialog(photo.image, photo.comment, photo.place)">
                <!-- <button class="deleteButton" mat-icon-button>
                    <mat-icon aria-label="Delete">close</mat-icon>
                </button> -->
            </div>
        </div>
    </div>

    <div class="card card-table">
        <div style="display: flex; align-items: center; justify-content: center;">
            <h6 style="color: #002eff; margin-right: 20px; margin-top: 15px; font-size: 24px;"><b>Total Incidencias:</b>
            </h6>
            <h2 style="margin-top: 30px;">{{contDashboard}}<span class="modern">/{{totCountElement + emptyPositions +
                    outOfPortfolio }}</span></h2>
            <!-- TOTAL ELEMENTS -->

        </div>
    </div>

    <div class="card card-table">
        <div *ngIf="contDashboard === 0" style="margin-top: 80px; padding: 20px; ;">
            <!-- <p style="color: #002eff; font-size: 38px; font-weight: bold; text-align: center; ">¡Tu tienda ha
                sido evaluada!</p> -->
            <p *ngIf="generalMark === 100"
                style="color: #002eff; font-size: 38px; text-align: center;  padding-top: 10px; margin-bottom: 20px;">
                "Tu tienda fue evaluada con éxito, tuviste un 100%, sigue así"</p>
        </div>
        <app-table-expand [subsidiary]="subsidiary_id" [metricID]="metricID">
        </app-table-expand>
    </div>

    <div class="card card-table">
        <div class="row">
            <div class="col">
                <h6 style="margin-top: 15px;color: #002eff; margin-left: -10px;"><b>Otras Incidencias</b></h6>
            </div>
            <table mat-table [dataSource]="dataSourceIncidence" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="section">
                    <th mat-header-cell *matHeaderCellDef> Sección </th>
                    <td mat-cell *matCellDef="let element"> {{element.section}} </td>
                </ng-container>

                <ng-container matColumnDef="emptyPosition">
                    <th mat-header-cell *matHeaderCellDef> Posiciones Vacías </th>
                    <td mat-cell *matCellDef="let element"> {{element.emptyPosition}} </td>
                </ng-container>

                <ng-container matColumnDef="outOfPortfolio">
                    <th mat-header-cell *matHeaderCellDef> Fuera de Portafolio </th>
                    <td mat-cell *matCellDef="let element"> {{element.outOfPortfolio}} </td>
                </ng-container>

                <ng-container matColumnDef="exhibitionOrder">
                    <th mat-header-cell *matHeaderCellDef> Cumplimiento Orden de Exhibición </th>
                    <td mat-cell *matCellDef="let element"> {{element.exhibitionOrder}}%</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['section', 'emptyPosition', 'outOfPortfolio', 'exhibitionOrder']">
                </tr>
                <tr mat-row
                    *matRowDef="let row; columns: ['section', 'emptyPosition', 'outOfPortfolio', 'exhibitionOrder']">
                </tr>
            </table>
            <div class="info-icon" (mouseover)="showTableTooltip = true" (mouseleave)="showTableTooltip = false">
                <img src="assets/resources/Recurso7@3x.png" alt="Ícono de información" style="width: 30px;">
                <div class="tooltipIncidence" *ngIf="showTableTooltip">
                    <p style="font-weight: bold;">Medición para Equipos o Accesorios considera:</p>
                    <ul>
                        <li>- Posiciones vacías: espacios de exhibiciones sin SKU o tarjetón de precio</li>
                        <li>- Posiciones fuera de portfolio: SKU exhibidos que no están dentro del portfolio del mes.
                        </li>
                        <li>- Cumplimiento orden de Exhibición: exhibición según orden definido en Foto de Éxito.</li>
                        <li>* para Equipos: ordenados por precio del mayor a menor desde ingreso de la Tienda.</li>
                        <li>* para Accesorios: ordenados por Categoría, y dentro de esta por tamaño de caja de menor a
                            mayor tamaño.</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <app-table-incidence [subsidiary]="subsidiary_id" [metricID]="metricID" [exhibitionObject]="exhibitionObject"></app-table-incidence> -->
    </div>
</div>



<app-spinner [spinner]="spinner"></app-spinner>