<!-- <div class="headTitle">
    <h1 class="title-state">Dashboard <b class="bColor">Foto de Éxito</b></h1>
    <div class="logo" routerLink="/v4">
        <img src="assets/svg/logo-entel.svg">
    </div>
</div> -->

<div class="row filter-header">
    <div class="col">
        <h6 style="font-weight: bold; color: rgb(48, 47, 47);">Filtro</h6>
    </div>
    <div class="col formField">
        <form class="example-form">
            <mat-form-field class="example-full-width" appearance="fill" >
                <input type="text" placeholder="Mes evaluado" aria-label="Ingresa" matInput
                    [formControl]="metricForm" [matAutocomplete]="auto2" class="input-text">
                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                    <mat-option *ngFor="let element of actualStatSelector" [value]="element.name">{{element.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
    <div class="col" *ngIf="franchiseValidate" [ngStyle]="{'display': filterEnabled ? 'block' : 'none'}">
        <form class="store-select example-form">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Selecciona una Franquicia</mat-label>
                <input type="text" placeholder="Selecciona una Franquicia" aria-label="Number" matInput
                    [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="franchiseSelected()">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option}}</mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="myControl.setValue('')" (click)="clear('franchise')">clear</mat-icon>
            </mat-form-field>
        </form>
    </div>
</div>

<div class="second-row">
    <div class="col-left">
        <div class="card">
            <div class="col export-excel-container">
                <button (click)="exportToExcel()" class="btn btn-success export-excel-btn">
                    <i class="fa fa-file-excel-o"></i> Exportar a Excel
                </button>
            </div>
            <mat-tab-group>
                <mat-tab label="EVALUACIÓN FRANQUICIA">
                    <div class="tab-adjust">
                        <div class="no-background">
                            <table mat-table [dataSource]="dataSource6" class="mat-elevation-z8 table-design" matSort id="franchise">

                                <!-- Franchise Column -->
                                <ng-container matColumnDef="franchise">
                                    <th mat-header-cell *matHeaderCellDef> Franquicia </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>

                                <!-- Device Column -->
                                <ng-container matColumnDef="device">
                                    <th mat-header-cell *matHeaderCellDef> Equipo </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.device_final_mark >= 39.45 ) ? (greenColor) : (element.device_final_mark >= 31.45) ? (yellowColor) : (redColor),
                                        'color': (element.device_final_mark < 31.45) ? 'white' : 'black'
                                    }">
                                        <b>{{element.device_final_mark}}%</b>
                                    </td>
                                </ng-container>

                                <!-- Home Column -->
                                <ng-container matColumnDef="home">
                                    <th mat-header-cell *matHeaderCellDef> Hogar </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.home_final_mark >= 9.45 ) ? (greenColor) : (element.home_final_mark >= 7.45) ? (yellowColor) : (redColor),
                                        'color': (element.home_final_mark < 7.45) ? 'white' : 'black'
                                    }">
                                        <b>{{element.home_final_mark}}%</b>
                                    </td>
                                </ng-container>

                                <!-- Accesories Column -->
                                <ng-container matColumnDef="accesories">
                                    <th mat-header-cell *matHeaderCellDef> Accesorios </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.accessory_final_mark >= 34.45 ) ? (greenColor) : (element.accessory_final_mark >= 29.45) ? (yellowColor) : (redColor),
                                        'color': (element.accessory_final_mark < 29.45) ? 'white' : 'black'
                                    }">
                                        <b> {{element.accessory_final_mark}}%</b>
                                    </td>
                                </ng-container>

                                <!-- Image Column -->
                                <ng-container matColumnDef="image">
                                    <th mat-header-cell *matHeaderCellDef> Imagen </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.image_final_mark >= 14.45 ) ? (greenColor) : (element.image_final_mark >= 9.45) ? (yellowColor) : (redColor),
                                        'color': (element.image_final_mark < 9.45) ? 'white' : 'black'
                                    }">
                                        <b>{{element.image_final_mark}}%</b>
                                    </td>
                                </ng-container>

                                <!-- Mark Column -->
                                <ng-container matColumnDef="mark" >
                                    <th mat-header-cell *matHeaderCellDef> Nota </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.final_mark >= 99.45 ) ? (greenColor) : (element.final_mark >= 69.45) ? (yellowColor) : (redColor),
                                        'color': (element.final_mark < 69.45) ? 'white' : 'black'
                                    }">
                                        <b>{{element.final_mark}}%</b>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsFranchise"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsFranchise;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons
                                aria-label="Select page of periodic elements">
                            </mat-paginator>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="EVALUACIÓN PDV">
                    <div class="tab-adjust">
                        <div class="no-background">
                            <table mat-table [dataSource]="dataSource7" class="mat-elevation-z8 evaluation-example" matSort id="pdv">
                                <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef> PDV </th>
                                    <td mat-cell *matCellDef="let element"> {{element.internal_id}}</td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Nombre PDV</th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
                                </ng-container>
                                <ng-container matColumnDef="franchise">
                                    <th mat-header-cell *matHeaderCellDef> Franquicia </th>
                                    <td mat-cell *matCellDef="let element"> {{element.franchise_name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="device">
                                    <th mat-header-cell *matHeaderCellDef> Equipo </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.device_final_mark >= 39.45 ) ? (greenColor) : (element.device_final_mark >= 31.45) ? (yellowColor) : (redColor),
                                        'color': (element.device_final_mark < 31.45) ? 'white' : 'black'
                                    }">
                                        <b>{{element.device_final_mark}}%</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="home">
                                    <th mat-header-cell *matHeaderCellDef> Hogar </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.home_final_mark >= 9.45 ) ? (greenColor) : (element.home_final_mark >= 7.45) ? (yellowColor) : (redColor),
                                        'color': (element.home_final_mark < 7.45) ? 'white' : 'black'
                                    }">
                                        <b>{{element.home_final_mark}}%</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="accesories">
                                    <th mat-header-cell *matHeaderCellDef> Accesorios </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.accessory_final_mark >= 34.45 ) ? (greenColor) : (element.accessory_final_mark >= 29.45) ? (yellowColor) : (redColor),
                                        'color': (element.accessory_final_mark < 29.45) ? 'white' : 'black'
                                    }">
                                        <b> {{element.accessory_final_mark}}%</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="image">
                                    <th mat-header-cell *matHeaderCellDef> Imagen </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.image_final_mark >= 14.45 ) ? (greenColor) : (element.image_final_mark >= 9.45) ? (yellowColor) : (redColor),
                                        'color': (element.image_final_mark < 9.44) ? 'white' : 'black'
                                    }">
                                        <b>{{element.image_final_mark}}%</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="mark">
                                    <th mat-header-cell *matHeaderCellDef
                                        sortActionDescription="Sort by mark"> Nota </th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="{'background-color': (element.final_mark >= 99.45 ) ? (greenColor) : (element.final_mark >= 69.45) ? (yellowColor) : (redColor),
                                        'color': (element.final_mark < 69.45) ? 'white' : 'black'
                                    }">
                                        <b> {{element.final_mark}}%</b>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsSubsidiary"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsSubsidiary;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[8, 10, 20]" showFirstLastButtons
                                aria-label="Select page of periodic elements">
                            </mat-paginator>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </div>
    <div class="col-right">
        <div class="card">
            <h6 style="color: #0425ff"><b>Escala de evaluación</b></h6>
            <div class="no-background">
                <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8 evaluation-example">

                    <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->

                    <!-- scale Column -->
                    <ng-container matColumnDef="scale">
                        <th mat-header-cell *matHeaderCellDef>Índice</th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="{'background-color': (element.scale === '>') ? (greenColor) : (element.scale === '=') ? (yellowColor) : (redColor),
                            'color': (element.scale === '<' || element.scale === '<=') ? 'white' : 'black'
                        }">
                            <b>{{element.scale}}</b>
                        </td>
                    </ng-container>

                    <!-- Devices Column -->
                    <ng-container matColumnDef="devices">
                        <th mat-header-cell *matHeaderCellDef> Equipos</th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="{'background-color': (element.devices >= 39.45) ? (greenColor) : (element.devices >= 31.45) ? (yellowColor) : (redColor),
                            'color': (element.devices < 31.45) ? 'white' : 'black'
                        }">
                            <b>{{element.devices}}%</b>
                        </td>
                    </ng-container>

                    <!-- Home Column -->
                    <ng-container matColumnDef="home">
                        <th mat-header-cell *matHeaderCellDef> Hogar </th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="{'background-color': (element.home >= 9.45) ? (greenColor) : (element.home >= 7.45) ? (yellowColor) : (redColor),
                            'color': (element.home < 7.45) ? 'white' : 'black'
                        }">
                            <b>{{element.home}}%</b>
                        </td>
                    </ng-container>

                    <!-- Accesories Column -->
                    <ng-container matColumnDef="accesories">
                        <th mat-header-cell *matHeaderCellDef> Accesorios </th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="{'background-color': (element.accesories >= 34.45) ? (greenColor) : (element.accesories >= 29.45) ? (yellowColor) : (redColor),
                            'color': (element.accesories < 29.45) ? 'white' : 'black'
                        }">
                            <b>{{element.accesories}}%</b>
                        </td>
                    </ng-container>

                    <!-- Image Column -->
                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef> Imagen </th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="{'background-color': (element.image >= 14.45) ? (greenColor) : (element.image >= 9.45) ? (yellowColor) : (redColor),
                            'color': (element.image < 9.45) ? 'white' : 'black'
                        }">
                            <b>{{element.image}}%</b>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                </table>
            </div>
        </div>
        
        <div class="card advice-text">
            <h6 style="color: #002eff"><b>Consejos</b></h6>
            <p id="advice-one">
                Te recomendamos revisar el documento donde te
                indicamos cómo se deben implementar los distintos
                materiales que disponemos en nuestros puntos de venta.
            </p>
            <p id="advice-two" style="display: none;">
                Recuerda que la implementación es un KPI
                objetivo del negocio y esto ayudará a mejorar tus ventas.
            </p>
            <br>
            <div style="display: flex; justify-content: space-between; margin-top: 10px; padding-right: 10px;">
                <button style="background-color: #002eff" type="button" class="btn btn-secondary" (click)="showAdvice('advice-one')">←</button>
                <button style="background-color: #002eff" type="button" class="btn btn-secondary" (click)="showAdvice('advice-two')">→</button>
            </div>
        </div>
    </div>
</div>




<app-spinner [spinner]="spinner"></app-spinner>