<div class="filter-buttons">
  <button class="filter-btn" mat-raised-button color="primary" mat-button
    [matMenuTriggerFor]="sectionMenu">{{sectionName}}</button>
  <mat-menu #sectionMenu="matMenu">
    <button mat-menu-item (click)="applyFilter('EQUIPOS', 'EQUIPOS')">Equipos</button>
    <button mat-menu-item (click)="applyFilter('ACCESORIOS', 'ACCESORIOS')">Accesorios</button>
    <button mat-menu-item (click)="applyFilter('HOGAR', 'HOGAR')">Hogar</button>
    <button mat-menu-item (click)="applyFilter('IMAGEN', 'IMAGEN')">Imagen</button>
  </mat-menu> 


  <button class="filter-btn" mat-raised-button color="primary" mat-button
    [matMenuTriggerFor]="optionsMenu">{{categoryNameFilter}}</button>
  <mat-menu #optionsMenu="matMenu">
    <button mat-menu-item *ngFor="let option of optionsMenuOptions" (click)="applyFilter(option)">{{ option }}</button>
  </mat-menu>

  <button class="delete-btn" mat-raised-button mat-icon-button color="warn" id="clearFiltersButton"
    (click)="clearFilters()">
    <mat-icon class="custom-icon">delete</mat-icon>
  </button>

</div>
<!-- 
<div>
  <mat-form-field>
    <mat-label>Sección</mat-label>
    <mat-select (selectionChange)="applyFilter($event.value, categoriaSelector.value)">
      <mat-option *ngFor="let seccion of seccionOptions" [value]="seccion">{{seccion}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Categoría</mat-label>
    <mat-select #categoriaSelector>
      <mat-option *ngFor="let categoria of categoriaOptions[seccionSelector.value]" [value]="categoria">{{categoria}}</mat-option>
    </mat-select>
  </mat-form-field>
</div> -->

<table mat-table [dataSource]="dataSource3" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="Seccion">
    <th mat-header-cell *matHeaderCellDef> Sección </th>
    <!-- <td mat-cell *matCellDef="let element"> {{ getStatusTranslation(element.cause_off.element_type) }} </td> -->
    <td mat-cell *matCellDef="let element">{{element.seccionName.toUpperCase()}} </td>

    <!-- <td mat-cell *matCellDef="let element"> {{element.status == 'accessory' ? 'Accesorios' : 'Accesorios'}} </td> -->
  </ng-container>

  <ng-container matColumnDef="Categoria">
    <th mat-header-cell *matHeaderCellDef> Categoría </th>
    <!-- <td mat-cell *matCellDef="let element"> {{element.element.place.name}} </td> -->
    <td mat-cell *matCellDef="let element"> {{element.categoryName.toUpperCase()}} </td>

  </ng-container>

  <ng-container matColumnDef="Estado">
    <th mat-header-cell *matHeaderCellDef> SKU - Nombre </th>
    <!-- <td mat-cell *matCellDef="let element">   {{element.status === 'pending' ? 'Pendiente' : 'Pendiente'}} -->
    <!-- <td mat-cell *matCellDef="let element">   {{element.element.code + ' - ' + element.element.name}}</td> -->
    <td mat-cell *matCellDef="let element">{{element.typeOf.toUpperCase()}}</td>

  </ng-container>

  <ng-container matColumnDef="Tipo">
    <th mat-header-cell *matHeaderCellDef> Incidencia</th>
    <!-- <td mat-cell *matCellDef="let element"> {{element.item_type}}</td> -->
    <td mat-cell *matCellDef="let element"> {{element.incidence.toUpperCase()}}</td>

  </ng-container>

  <ng-container matColumnDef="Imagen">
    <th mat-header-cell *matHeaderCellDef> Imagen </th>
    <td mat-cell *matCellDef="let element">
      <!-- <img *ngIf="element.image !== null && element.image !== undefined" src="assets/resources/Recurso-imagen.jpeg" class="logo-login" (click)="openImageDialog(element)"> -->
      <img *ngIf="element.image !== null && element.image !== undefined" [src]="element.image" class="incidence-img" (click)="openImageDialog(element)">

    </td> 
  </ng-container>


  <ng-container matColumnDef="states">
    <th mat-header-cell *matHeaderCellDef> Estado </th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [checked]="element.is_checked === true" (change)="toggleChanged(element, $event)">
      </mat-slide-toggle>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsData"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsData;"></tr>
</table>


<!-- <mat-paginator [pageSizeOptions]="[8, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements"> -->
<!-- </mat-paginator> -->


<app-spinner [spinner]="spinner"></app-spinner>