import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/services.service';
import { MatMenu } from '@angular/material/menu';
import { Subjects } from 'src/app/utils/subjects';
import * as _ from 'lodash';
import { MetricModel } from 'src/app/utils/metric-data.interface';

@Component({
  selector: 'app-circle-option',
  templateUrl: './circle-option.component.html',
  styleUrls: ['./circle-option.component.scss']
})
export class CircleOptionComponent implements OnInit, OnChanges {

  @Input() element: any;
  @Input() element_type: any;
  @Input() actualPlaceId;
  @Input() plural_context;
  @Input() context;
  @Input() causes;

  @Output() spin: EventEmitter<any> = new EventEmitter();

  options: any[];
  defaultColor = '#ccc';
  checked = false;
  showColor = true;
  items: any = []
  id_cause: number;
  tooltipColor: any = "No evaluado";

  constructor(
    private service: ApiService,
    private subject: Subjects,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['element']) {
      // console.log("this context ? ", this.plural_context);
      this.options = this.causes;
      // console.log("this options ? ", this.options);
      this.setToggles()
    }
  }

  setToggles() {
    // set default toggles 
    // let currentTogglesToSet = this.subject.currentItems$.getValue();

    let currentTogglesToSet = this.subject.metricDataSubject$.getValue()[this.plural_context];
    let actualPlaceId = this.actualPlaceId.value; 
    let place_obj = _.find(currentTogglesToSet, function (obj) {
      if (obj.place_id === actualPlaceId) { // Use the stored actualPlaceId here
        return true; // Return true to indicate a match
      }
    });
    if (place_obj){
      currentTogglesToSet = place_obj.items;
    }
    // console.log("current elements to set ?", currentTogglesToSet);

    if (currentTogglesToSet != null && currentTogglesToSet.length > 0) {
      //// console.log("if statemet passing != null and > 0 ");

      for (let i = 0; i < currentTogglesToSet.length; i++) {
        //// console.log("inside for ", currentTogglesToSet[i]);
        let current = currentTogglesToSet[i];
        //// console.log("if statemet current.element and element.id", current.element , this.element.id);
        // console.log("- current elements to set ", current);
        // console.log("- element id ? - item_type : ", this.element.id, this.element_type)
        if (current.element === this.element.id && current.item_type_code === this.element_type) {
          // console.log("current elements to set ", current);
          this.setToggleStatus(current);
        }
      }
    } else {
      //console.log("current toggles of default ")
    }
  }

  setToggleStatus(current: any) {
    // console.log("current :", current);
    //console.log("display on :", current.display_on);

    // we set the toggles that was charged on our data.
    if (current.display_on === true) {
      this.checked = true;
      this.showColor = false;
      this.tooltipColor = "Completado";
    } else {
      if (current.cause_off === 1 || current.cause_off === 4 || current.cause_off === 7 || current.cause_off === 10) {
        this.id_cause = current.cause_off;
        this.defaultColor = 'red';
        let colorOptions = this.options;
        this.tooltipColor = colorOptions[0].name;
        // console.log("color options ", colorOptions);
      } else {
        if (current.cause_off === 2) {
          this.id_cause = 2;
          this.defaultColor = 'yellow';
          let colorOptions = this.options;
          this.tooltipColor = colorOptions[1].name;
        } else {
          this.defaultColor = '#3AB4E8'
          this.id_cause = 3;
          let colorOptions = this.options;
          this.tooltipColor = colorOptions[2].name;
        }
      }
    }
  }

  selectedColor(color: string) {
    // 
    this.subject.spinnerTable$.next(true);

    // if the color already exist will be eliminated
    this.validateIfColorExist();
    let flag = false;

    // knowing what's color was selected
    if (color === 'blue_light') {
      // console.log("COLOR SELECTED : BLUE LIGHT !!!!")
      this.defaultColor = '#3AB4E8'
      this.id_cause = 3;
      let colorOptions = this.options;
      this.tooltipColor = colorOptions[2].name;
    } else {
      if (color === 'red') {
        // console.log("COLOR SELECTED defautl: !!!!   REEED");
        // this.id_cause = 1;
        this.id_cause = this.options[0].id;
        this.defaultColor = color;
        let colorOptions = this.options;
        this.tooltipColor = colorOptions[0].name;
      } else {
        if (color === 'yellow') {
          // console.log("COLOR SELECTED defautl: Yellow!!!!");
          this.id_cause = 2;
          this.defaultColor = color;
          let colorOptions = this.options;
          this.tooltipColor = colorOptions[1].name;
        }
      }
    }
    //// console.log("element : ", this.element);
    //// console.log("element tyoe : ", this.element_type)
    //// console.log("color -------------------->>>>>>>", color);
    let currentPlaceId = this.actualPlaceId.getValue();
   

    let object = {
      element: this.element.id,
      item_type_code: this.element_type,
      display_on: false,
      cause_off: this.id_cause,
    }
    setTimeout(() => {
      // console.log("Delayed for 1.5 second.");
      this.subject._nextMetricPlaceDevice(currentPlaceId, null, null, null, object, this.plural_context)
    }, 500);
    
    // console.log("subject before color setting : ", this.subject.metricDataSubject$.getValue());

  }

  validateIfColorExist() {
    let currentPlaceId = this.actualPlaceId.getValue();
    let subject = _.cloneDeep(this.subject.metricDataSubject$.getValue());
    // let subject = this.subject.metricDataSubject$.getValue();

    //console.log("subject -> ", subject)
    // delete if passing false to true and it was setted a color (red, yellow or blue)
    let elementX = subject[this.plural_context];
    for (let item of elementX) {
      //console.log("item of subject devices : ", item);
      if (item.place_id === currentPlaceId) {
        for (let i = 0; i < item.items.length; i++) {
          if (item.items[i].element === this.element.id && item.items[i].item_type_code === this.element_type) {
            let element = item.items;
            element.splice(i, 1);
            break;
          }
        }
        break;
      }
    }
    this.subject.metricDataSubject$.next(subject);
  }


  onToggleChange(event: any, defaultItems: null) {
    this.subject.spinnerTable$.next(true);
    let flag = false;
    // if event is true the color is setted as green and cause_off to null
    console.log("event .checked---> ", event);
    // //console.log("from generic element id=", this.element.id)
    // //console.log("from generic element type =", this.element_type)
    if ((event.checked) || defaultItems != null) {
      this.showColor = false;
      this.tooltipColor = "Completado";
      let currentPlaceId = this.actualPlaceId.getValue();
      // console.log("current place id subject from circle component", currentPlaceId);
      //console.log("CURRENT PLACE ID :", currentPlaceId);
      //current object of true event with display equal to true and cause equals to null (colors goes green)
      //console.log("object added when goes true :", object);
      //console.log("element -> ", this.element)
      // let subject = this.subject.metricDataSubject$.getValue();

      let subject = _.cloneDeep(this.subject.metricDataSubject$.getValue());
      // let subject = this.subject.metricDataSubject$.getValue();
      console.warn("subject from circle option component -> ", subject);
      // delete if passing false to true and it was setted a color (red, yellow or blue)
      let elementX = subject[this.plural_context];
      for (let item of elementX) {
        //console.log("item of subject devices : ", item);
        if (item.place_id === currentPlaceId) {
          for (let i = 0; i < item.items.length; i++) {
            if (item.items[i].element === this.element.id && item.items[i].item_type_code === this.element_type) {
              let element = item.items;
              element.splice(i, 1);
              this.subject.metricDataSubject$.next(subject);
              // this.subject.metricDataSubject$.next(subject);
              // let object = {
              //   element: this.element.id,
              //   item_type_code: this.element_type,
              //   display_on: true,
              //   cause_off: null
              // }
              // this.subject._nextMetricPlaceDevice(currentPlaceId, null, null, null, object)
              // flag = true;
              break;
              // let object = {
              //   element: this.element.id,
              //   item_type_code: this.element_type,
              //   display_on: true,
              //   cause_off: null,
              // }

              // item.items[i] = object as any;

              // this.subject.metricDataSubject$.next(subject)
              // this.subject._nextMetricPlaceDevice(currentPlaceId, null, null, null, object)
              // flag = true;
            }
          }
        }
      }

      let item = {
        element: this.element.id,
        item_type_code: this.element_type,
        display_on: true,
        cause_off: null
      }

      setTimeout(() => {
        // console.log("Delayed for 1.5 second.");
        this.subject._nextMetricPlaceDevice(currentPlaceId, null, null, null, item, this.plural_context)
      }, 500);

      // if (!flag) {
      //   let object = {
      //     element: this.element.id,
      //     item_type_code: this.element_type,
      //     display_on: true,
      //     cause_off: null
      //   }
      //   this.subject._nextMetricPlaceDevice(currentPlaceId, null, null, null, object)
      // }

      // this.subject._nextMetricPlaceDevice(currentPlaceId, null, null, null, object)
      //console.log("toggle subject : ", this.subject.metricDataSubject$.getValue());
      // this.postIfrnformation();

    } else {
      // when toggle is passing from true to false we delete the actual item of the element 
      // we set a default color and we show the mat menu option
      this.defaultColor = '#ccc';
      this.showColor = true;
      this.tooltipColor = "No evaluado";
      let currentPlaceId = this.actualPlaceId.getValue();
      let subject = this.subject.metricDataSubject$.getValue();

      // search the true element to delete and set with the disabled toggle value
      let elementX = subject[this.plural_context];
      for (let item of elementX) {
        if (item.place_id === currentPlaceId) {
          for (let i = 0; i < item.items.length; i++) {
            // fuound the element by his ID and by the type_code (displayed or card)
            if (item.items[i].element === this.element.id && item.items[i].item_type_code === this.element_type) {
              let element = item.items;
              element.splice(i, 1);
            }
          }
        }
      }
      // update the subject without the deleted element
      //console.log("updated element from true to false ", this.subject.metricDataSubject$.getValue());
      this.subject.metricDataSubject$.next(subject);
    }

    // this.postInformation();

  }

}
