import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/services.service';
import { Subjects } from 'src/app/utils/subjects';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
  selector: 'app-add-place-image',
  templateUrl: './add-place-image.component.html',
  styleUrls: ['./add-place-image.component.scss']
})
export class AddPlaceImageComponent implements OnInit {


  imageUrl: string;
  currentImages = [];
  disabled = false;
  uploadButtonDisabled = true;
  myTextarea = new FormControl('', Validators.required);
  myFile = new FormControl(null, Validators.required);
  
  constructor( 
    private metric : Subjects,
    private dialog : MatDialog,
    private apiservice : ApiService,
    public dialogRef: MatDialogRef<AddPlaceImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      actualPlace : any, plural_context: any
    }
    ) { }

  ngOnInit(): void {
  
  }

  onSave() {
    let image = {
      'image' : this.imageUrl,
      'comment' : this.myTextarea.value,
      'id' : this.metric.currentImages$.getValue().length,
      'place' : this.data.actualPlace.id,
    }
    console.table(image);

    this.metric._nextMetricPlaceDevice(this.data.actualPlace.id, null,null,image, null, this.data.plural_context);

    let images = _.cloneDeep(this.metric.currentImages$.getValue());
    images.unshift(image);
    this.metric.currentImages$.next(images);
    this.dialog.closeAll()
  }

  // FILES
  onSendUpload(url: string) {
    this.imageUrl = url;
    this.currentImages.unshift(this.imageUrl);
    this.disabled = true;
    this.uploadButtonDisabled = false;
  }

  removeImage(index: number) {
    this.currentImages.splice(index, 1);
  }

  // DIALOGS
  openImageDialog(imageUrl: string) {
    this.dialog.open(ImageDialogComponent, {
      data: { imageUrl },
      width: 'auto',
    });
  }

}
