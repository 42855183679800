<div>
    <h2 mat-dialog-title>SUBIR ARCHIVOS</h2>

    <div mat-dialog-content class="dialog-content">
        <div class="full-width">
            <button mat-flat-button class="select-button" [disabled]="disabled">
                SELECCIONAR ARCHIVO
                <app-upload-files (upload)="onSendUpload($event)"></app-upload-files>
            </button>
        </div>
        <div *ngFor="let photo of currentImages" class="preview-image">
            <img style="cursor: pointer; height: 100px; width: 150px; border-radius: 10px;" [src]="photo"
                (click)="openImageDialog(photo)">
        </div>
        <div class="full-width">
            <mat-form-field>
                <textarea matInput placeholder="Observaciones" [formControl]="myTextarea"></textarea>
                <mat-error *ngIf="myTextarea.invalid">Observaciones requeridas</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
        <button mat-button color="primary" (click)="onSave()" [disabled]="uploadButtonDisabled">GUARDAR</button>
        <button mat-button mat-dialog-close>CANCELAR</button>
    </div>
</div>