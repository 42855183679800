import { Component, AfterViewInit, ViewChild, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../services/services.service';
import { Subjects } from '../utils/subjects';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageComponent } from '../component/show-image/show-image.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';


export interface TableData {
  Seccion: string;
  Incidencia: string;
  Categoria: string;
  Estado: string;
  Tipo: any;
}

export interface PeriodicElement {
  position: number;
  PDV: string;
  Categoria: string;
  Incidencia: string;
  Estado: any;
  tipo: any;
  imagen: any;
  states: any;
}

@Component({
  selector: 'app-table-expand',
  templateUrl: './table-expand.component.html',
  styleUrls: ['./table-expand.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TableExpandComponent implements OnInit, OnChanges {

  @Input() subsidiary = null;
  @Input() spinner: boolean = false;
  expandedElement: PeriodicElement | null;

  dataSource: TableData[] = [];
  columnsData = ['Seccion', 'Categoria', 'Estado', 'Tipo', 'Imagen', 'states'];
  dataSource3 = new MatTableDataSource<TableData>(this.dataSource);
  @ViewChild(MatSort) sort: MatSort;
  myControl = new FormControl('');
  filteredOptions: Observable<string[]>;
  options: string[] = [];
  backupOptions: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  optionsMenuOptions: string[];
  @Input() metricID: any;
  actualSectionFilter;
  sectionName = "SECCIÓN";
  categoryNameFilter = "CATEGORÍA";


  constructor(
    private service: ApiService,
    private subjects: Subjects,
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['subsidiary']) {
      let param = {
        element_check__stat__subsidiary_id: this.subsidiary,
        'stat_programming': this.metricID
      }

      // console.log("params ", param);

      this.service.getIncidences(param).subscribe(
        data => {
          if (data) {
            this.backupOptions = data;
            // this.dataSource = data;
            this.dataSource3.sort = this.sort;
            // this.dataSource3.data = data;
            console.log("data in table -<>", data);

            // Assuming 'apiResponse' is the array of objects you receive from the API
            const modifiedData = data.map(item => {
              const { seccion, cause_off, element, item_type, image, comment, is_checked, id } = item;

              // Extract properties from 'cause_off', 'element', and 'subsidiary'
              const seccionName = this.getStatusTranslation(element.place.element_type);
              let categoryName = element.place.name;
              let typeOf = element.code + ' - ' + element.name;
              if (categoryName == 'DEFAULT-IMAGE') {
                categoryName = 'IMAGEN'
                typeOf = element.name;
              } else {
                if (categoryName == "DEFAULT-HOME") {
                  categoryName = 'HOME'
                  typeOf = element.name;
                }
              }
              const item_ty = item_type;
              let incidence = cause_off.name
              // console.log("section name", seccionName);
              // console.log("incidence name", incidence);

              // CHANGE THE NAME OF THE INCIDENCE - BURNING DATA
              if ((seccionName == "Equipos") && (item_ty == "Exhibición")) {
                incidence = "NO EXHIBIDO"
              } else if ((seccionName == "Equipos") && (item_ty == "Tarjetón")) {
                incidence = "SIN TARJETÓN"
              }

              // console.log("TYPE?", item_ty);
              if ((seccionName == "Accesorios") && (item_ty == "Exhibición")) {
                incidence = "NO EXHIBIDO"
              } else if ((seccionName == "Accesorios") && (item_ty == "Tarjetón")) {
                incidence = "SIN TARJETÓN"
              }
              if (seccionName == "Hogar") {
                incidence = "NO EXHIBIDO"
              }
              if (seccionName == "Imagen") {
                incidence = "NO CUMPLE"
              }



              this.getStatusTranslation(seccionName);



              // console.log("category name translated : ", categoryName);
              return {
                seccionName,
                categoryName,
                incidence,
                typeOf,
                item_ty,
                image,
                comment,
                is_checked,
                id,
              };
            });


            // Now 'modifiedData' contains the restructured data
            this.dataSource = modifiedData;
            this.dataSource3.data = modifiedData;
            // console.log(modifiedData);
            this.backupOptions = modifiedData;



            // console.log("data in table -> ", data);
            this.subjects.contDashboardTable$.next(this.dataSource3.data.length);
          }
        }

      )
    }

  }

  ngOnInit(): void {
  }

  execDataSource() {
    setTimeout(() => {
      this.dataSource3 = new MatTableDataSource(this.dataSource);
      this.dataSource3.paginator = this.paginator;
      this.dataSource3.sort = this.sort;
    }, 100);
  }

  seccionOptions: string[] = ['EQUIPOS', 'ACCESORIOS', 'HOGAR', 'IMAGEN'];
  sectionOptionsMap: { [key: string]: string[] } = {
    'EQUIPOS': ['GENERICA', 'TOPFIVE', 'SAMSUNG', 'APPLE'],
    'ACCESORIOS': ['AUDIO', 'WEREABLES', 'MULTIMEDIA', 'ENERGIA', 'TABLETS', 'PROTECCION'],
    'HOGAR': ['HOGAR'],
    'IMAGEN': ['IMAGEN'],
  };


  applyFilter(filterValue: string, section?: string) {
    // Apply section-based filtering

    if (section) {
      this.sectionName = section;
      this.categoryNameFilter = "CATEGORÍA";
      this.dataSource3.data = this.backupOptions; // Restore the original data
      // this.dataSource3 = new MatTableDataSource();
      console.log("data ", this.dataSource3);
      this.actualSectionFilter = section;
      console.log("section ", section);
      this.optionsMenuOptions = this.sectionOptionsMap[section];

      // Aplicar el filtro
      this.dataSource3.filter = section;

      // Crear una nueva instancia de MatTableDataSource con los datos filtrados
      const filteredData = this.dataSource3.filteredData;
      this.dataSource3 = new MatTableDataSource(filteredData);

      console.log("data ", this.dataSource3);
      console.log("data filter", this.dataSource3.filter);
    } else {
      console.log("apply filter without section but section is : ", this.actualSectionFilter);
      console.log("actual data source else ", this.dataSource3);
      console.log("actual data source else filter ", this.dataSource3.filter);
      console.log("filter value ", filterValue);
      this.categoryNameFilter = filterValue;
      this.dataSource3.filter = filterValue;

    }

    // if (filterValue) {
    //   console.log("filter value ", filterValue);
    // }


    // console.log("data source to show in table ", this.dataSource3.filter);
    // console.log("filter value ", filterValue);
    // let datasourceFiltered = this.dataSource3.filteredData;
    // console.log("data without .filter ", this.dataSource3.filteredData);
    // // Apply option-based filtering
    // if (filterValue) {
    //   this.dataSource3.filter = filterValue.trim();
    // }
  }


  clearFilters() {
    this.dataSource3.filter = ''; // Clear the filter
    this.dataSource3.data = this.backupOptions;
    this.sectionName = "SECCIÓN";
    this.categoryNameFilter = "CATEGORÍA";
    this.optionsMenuOptions = [];
    // If you want to restore the original data only when the filter input is empty
    if (this.myControl.value === '') {
      this.dataSource3.data = this.backupOptions; // Restore the original data
    }
  }



  getStatusTranslation(status: string): string {
    switch (status) {
      case 'accessory':
        return 'Accesorios';
      case 'image':
        return 'Imagen';
      case 'home':
        return 'Hogar';
      case 'device':
        return 'Equipos';
      default:
        return status; // Return original status if no translation found
    }
  }

  openImageDialog(element: any): void {
    console.log("element to popup : ", element);
    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: {
        image: element.image,
        comment: element.comment,
      }
    });
  }

  toggleChanged(element: any, event: any): void {

    if (event.checked === false) {
      const response = this.dialog.open(NotificationPopupComponent, {
        width: 'auto',
        data: { title: "¿Desea modificar el estado de la incidencia ?" }
      });
      response.afterClosed().toPromise().then(result => {
        if (result !== "yes") {
          event.source.checked = true;
          console.log("-> ", event.source.checked);
        } else {
          this.spinner = true;
          console.log("change to -> ", event.checked);
          console.log("callling service eleemtn ", element)
          this.service.changeStatusIncidenceCheck(element.id, event.checked).subscribe(
            response => {
              if (response) {
                this.spinner = false;
                this._toast("Se ha cambiado el estado de la incidencia");
              }
            },
            error => {
              this.spinner = false;
              this._toast("error al cambiar el estado de la incidencia");
            }
          );
        }
      });
    } else if (event.checked === true) {
      console.log("-> ", event.checked);
      const response = this.dialog.open(NotificationPopupComponent, {
        width: 'auto',
        data: { title: "¿Desea modificar el estado de la incidencia ?" }
      });

      response.afterClosed().toPromise().then(result => {
        if (result !== "yes") {
          event.source.checked = false;
          console.log("-> ", event.source.checked);
        } else {
          // USER ACCEPT
          this.spinner = true;
          this.service.changeStatusIncidenceCheck(element.id, event.checked).subscribe(
            response => {
              if (response) {
                this.spinner = false;
                this._toast("Se ha cambiado el estado de la incidencia");
              }
            },
            error => {
              this.spinner = false;
              this._toast("error al cambiar el estado de la incidencia");
            }
          );
        }
      });
    }

  }


  _toast(message: string, action: string = null, duration: number = null) {
    action = (action) ? action : 'OK';
    duration = (duration) ? duration : 2500;
    this.snack.open(message, action, {
      duration: duration,
    });
  }

}




