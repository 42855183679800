import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { nextSortDir } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { exit } from 'process';
import { element } from 'protractor';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AddPlaceImageComponent } from 'src/app/component/add-place-image/add-place-image.component';
import { ImageDialogComponent } from 'src/app/component/image-dialog/image-dialog.component';
import { NotificationPopupComponent } from 'src/app/notification-popup/notification-popup.component';
import { ApiService } from 'src/app/services/services.service';
import { MetricModel, Tab } from 'src/app/utils/metric-data.interface';
import { Subjects } from 'src/app/utils/subjects';
import { Utils } from 'src/app/utils/utils';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})


export class DevicesComponent implements OnInit, OnDestroy, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() spinner: boolean = false;

  @Input() context = 'device'
  @Input() plural_context = 'devices'
  @Input() typeOfSubsidiary = ''
  @Input() metricID = null;
  @Output() saveClicked: EventEmitter<void> = new EventEmitter<void>();

  // @Output() sendCurrentItems = new EventEmitter<any[]>();

  data = null;
  choosePlaceName: string;
  placeData;

  placeControl = new FormControl('');
  exhibitionMarkControl = new FormControl('');
  placesList: string[] = [];
  filteredOptions: Observable<string[]>;
  entelData: any = [];
  showPlacesOption = false;
  devicePlaces: any;
  marks = [0, 50, 100]
  nameContext: any;

  imageUrl: string;
  currentImages = [];
  currentItems = [];
  countImages = 0;
  currentPlaceID: number;
  actualSubsidiaryName: any;
  tablePlaceName: string;
  btnDropImageDisabled = true;
  subsidiaryName: any;
  tabName: any;

  options_two: string[] = ['90%'];
  FilteredOption_two: Observable<string[]>;
  finalMarkFER: number = 0;
  placeID: number;
  showTable = false;
  dataSource3 = new MatTableDataSource<any>(this.entelData);

  // summary
  totQty = 0;
  totQtyDisplayedFinal = 0;
  totQtyCardFinal = 0;

  totQtyPlace = 0;
  totQtyDisplayedPlaceMark = 0;
  totQtyCardPlaceMark = 0;

  totQtyPercentPlace = 0;
  totQtyDisplayed = 0;
  totQtyCard = 0;
  totQtyExhibition = 100;
  // total points
  totQtyGrayPoint = 0;
  totQtyGreenPoint = 0;
  totQtyRedPoint = 0;

  totQtyYellowPoint = 0;
  totQtyYellowPointDisplayed = 0;
  totQtyYellowPointCard = 0;

  totQtyBluePoint = 0;
  totQtyBluePointDisplayed = 0;
  totQtyBluePointCard = 0;


  totQtyPlaceDisplayed = 0;
  totQtyPlaceCard = 0;

  totQtyDisplayedPercent = 0;
  totQtyCardPercent = 0;
  totQtyPlaceDisplayedPercent = 0;
  totQtyPlaceCardPercent = 0;
  totQtyPlaceProm = 0;
  totQtyFinalMark: any = 0;
  totQtyFinal = 0;
  // CIRCLES RESUME TABLE COLORS 
  // GRAY
  currentGrayColor = 0;
  totalGrayColor = 0;
  // GREEN
  currentGreenColor = 0;
  totalGreenColor = 0;
  // YELLOW
  currentYellowColor = 0;
  totalYellowColor = 0;
  // RED
  currentRedColor = 0;
  totalRedColor = 0;



  // EXHIBITION MARKS AND EMPTY POSITION SECTION 
  value: number = 0;
  // NEW
  emptyPositions: number = 0;
  outOfPortafolio: number = 0;
  totalPositions: number = 0;
  // OLD
  valueEmptyPosition: number = 0;
  markValue = 0;
  // TOTAL EXHIBITION MARK
  totalExhibitionPercent = 0;
  backupTotalExhibitionPercent = 0;




  accesoriesTab = false;
  deviceTab = false;
  selected = new FormControl(0);
  validate = true;
  validate_mark = false;
  totQtyPlaceTotal = 0;
  actualPlaceId$ = new BehaviorSubject(null);
  actualPlaceObj;
  causes = [];
  subsidiaryInfo = false;

  //PARTIAL 
  contDisplayed = 0;
  contCard = 0;

  element_count = null;
  instanceID: any = '0000';
  subsidiary = null;
  percentageOptions;
  percentageSelected = 0;


  constructor(
    private dialog: MatDialog,
    private metricData: Subjects,
    private services: ApiService,
    private snack: MatSnackBar,
    private utils: Utils
  ) {

    this.instanceID = utils.generateRandomNumber();
  }
  ngAfterViewChecked(): void {
    //// console.log("NG -> after view checked", this.context)
  }
  ngAfterContentChecked(): void {
    //// console.log("NG -> after content checked", this.context)

  }
  ngAfterContentInit(): void {
    //// console.log("NG -> after content init", this.context)

  }
  ngDoCheck(): void {
    //// console.log("NG -> do check?", this.context)

  }


  ngOnDestroy(): void {
    //// console.log("NG -> on destrooy", this.context)

  }

  ngAfterViewInit() {
    //// console.log("NG -> after view init", this.context)
    // this.dataSource3.paginator = this.paginator;
    // this.dataSource3.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes devices', changes);
    this.resetSummary();
    if (changes['context']) {
      this.metricData.allCauses$.subscribe(
        data => {
          if (data) {
            let causes = _.filter(data, c => {
              return c.element_type == this.context;
            })
            this.causes = causes;
            // console.log("CAUSES FILTER ", this.causes);
          }
        }
      )
    }
    this.init();
  }

  private refreshContent() {
    this.clear()
  }

  init() {
    if (this.context === 'device') {
      this.nameContext = 'EQUIPOS';
      this.choosePlaceName = 'MESA';
      this.deviceTab = true;
      this.totQtyPlaceTotal = 2;
      this.exhibitionMarkControl.setValue('');
      this.totalExhibitionPercent = 0;
      this.totQtyFinalMark = 0;
      this.percentageOptions = ['0', '10', '20'];
      // this.totQtyPlaceTotal = this.totQtyDisplayedPlaceMark * 2;
    } else {
      if (this.context === 'accessory') {
        this.choosePlaceName = 'CATEGORÍA'
        this.nameContext = "ACCESORIOS";
        this.accesoriesTab = true;
        this.totQtyPlaceTotal = 2;
        this.totalExhibitionPercent = 0;
        this.totQtyFinalMark = 0;
        this.percentageOptions = ['0', '5', '10', '25'];
        this.exhibitionMarkControl.setValue('');
      } else {
        if (this.context === 'home') {
          this.choosePlaceName = 'HOGAR'
          this.nameContext = "HOGAR"
          this.totQtyPlaceTotal = 1;
          this.totQtyFinal = 0;
          this.validate = false;
          this.validate_mark = true;
        } else {
          if (this.context === 'image') {
            this.choosePlaceName = 'IMAGEN'
            this.nameContext = "IMAGEN";
            this.totQtyPlaceTotal = 1;
            this.totQtyFinal = 0;
            this.validate = false;
            this.validate_mark = true;
          }
        }
      }
    }
  }

  ngOnInit(): void {
    // console.log("ng on init ", this.context)
    console.log("set value", this.exhibitionMarkControl);

    // Change Subsidiary
    this.metricData.currentSubsidiaryName$.subscribe(
      data => {
        console.log('currentSubsidiaryName ', this.context, data);
        if (data) {
          this.subsidiaryName = this.extractSubsidiaryName(data).toUpperCase();
          this.subsidiaryInfo = true;
          // get MetricDataSuscription
          this.element_count = null;
          this.clear();
        }
      }
    )

    // Places Suscriptor (filter per context)
    this.metricData.allPlaces$.subscribe(
      data => {
        if (data) {
          this.devicePlaces = data;
          let options = [];
          this.devicePlaces.forEach(element => {
            // filer context
            if (element.element_type == this.context) {
              options.push(element.name)
            }
          });

          this.placesList = options;
          console.log('deviceplaces x', this.context, this.placesList);

          this.showPlacesOption = true;
        }
      }
    )

    // MetricData Suscriptor
    this.metricData.metricDataSubject$.subscribe(
      data => {
        if (data.subsidiary) {
          this.subsidiary = data.subsidiary;
          this.data = data;
          console.log('METRIC DATA => ', this.context, this.instanceID, data);
          this.resetSummary();
          this.setMarks(data);
          this.getElementCount();         // this.addResumeInfo()
          this.showImages();
          this.setEmptyPositions(data[this.context]);
          // this.spinner = false;
          // this.countIncidencePoints();

        } else {
          // this.spinner = true;
        }
      }
    )

    // Place Suscriptor
    this.actualPlaceId$.subscribe(data => {
      console.log('actualPlaceId suscriptor', data);
      if (data) {
        this.showTable = true;
        this.btnDropImageDisabled = false;
        this.placeID = data;
        this.showElement();
        this.resetSummary();
        this.getElementCount();
      }
    });

    this.metricData.tabManage$.subscribe(
      data => {
        if (data) {
          this.clear();
        }
      }
    )

    // Filters
    this.filteredOptions = this.placeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }


  setEmptyPositions(current: any) {
    if (current) {
      this.totalPositions = current.total_empty_positions;
      this.emptyPositions = current.empty_position;
      this.outOfPortafolio = current.out_of_portafolio;
    }
    console.log("current what ", current);
  }

  setMarks(data: any) {
    let setMark = data[this.context];
    this.exhibitionMarkControl.setValue('');
    this.totalExhibitionPercent = 0;
    this.totQtyFinalMark = 0;
    this.totQtyFinal = 0;
    console.log("set marks ---> ", data);
    if (setMark) {
      if (setMark.exhibition_mark) {
        this.totalExhibitionPercent = setMark.exhibition_mark;
        this.backupTotalExhibitionPercent = setMark.exhibition_mark;
      }
      console.warn("seting setmark ? ", setMark.exhibition_order);
      if (setMark.exhibition_order) {
        console.warn("seting setmark ", setMark);
        this.exhibitionMarkControl.setValue(setMark.exhibition_order)
      }
      console.log("NAN final mark?", this.totQtyFinalMark);
      console.log("NAN final?", this.totQtyFinal);

      if (setMark.final_mark) {
        this.totQtyFinalMark = Number(setMark.final_mark).toFixed(1);
      }

      console.log("NAN final mark? 2", this.totQtyFinalMark);
      console.log("NAN final? 2", this.totQtyFinal);
    } else {
      this.totalExhibitionPercent = 0;
      this.totQtyFinalMark = 0;
      this.exhibitionMarkControl.setValue('');
    }
  }

  _toast(message: string, action: string = null, duration: number = null) {
    action = (action) ? action : 'OK';
    duration = (duration) ? duration : 2500;
    this.snack.open(message, action, {
      duration: duration,
    });
  }


  finalMark() {

    setTimeout(() => {
      // console.log("Delayed for 1.5 second.");
      this.metricData._nextMetricPlaceDevice(this.currentPlaceID, null, null, null, null, this.plural_context, this.valueEmptyPosition, this.value)
      this.spinner = false;
    }, 100);
  }

  postExhibition() {

    if (this.totalPositions === undefined || this.emptyPositions === undefined || this.outOfPortafolio === undefined || this.exhibitionMarkControl == undefined) {
      this._toast("Por favor, complete los campos requeridos.");
      return;
    }

    if (this.totalPositions == null || this.emptyPositions == null || this.outOfPortafolio == null || this.exhibitionMarkControl == null) {
      this._toast("Por favor, complete los campos requeridos.");
      return;
    }

    this.totalPositions = Number(this.totalPositions);
    this.emptyPositions = Number(this.emptyPositions);
    this.outOfPortafolio = Number(this.outOfPortafolio);
    let exhibitionMarkValue = 0
    if (this.emptyPositions > this.totalPositions || this.outOfPortafolio > this.totalPositions) {
      this._toast("El valor de posiciones vacías o fuera de portafolio no puede superar o ser menor a la cantidad total de posiciones", null, 6000);
      return;
    }

    let calculatePositions = (Number(this.emptyPositions) + Number(this.outOfPortafolio));
    if (this.totalPositions < (calculatePositions)) {
      this._toast("El total de posiciones no puede ser menor que la suma entre posiciones vacías y fuera de portafolio.");
      return;
    }

    var exhibitionMark = 50;
    console.log("total positions : ", this.totalPositions);
    console.log("total empty  : ", this.emptyPositions);
    console.log("total out of : ", this.outOfPortafolio);
    exhibitionMarkValue = Number(this.exhibitionMarkControl.value);
    console.log("order exhibition", exhibitionMarkValue);
    console.log("TOTAL EXHIBITION before", this.totalExhibitionPercent);
    console.log("calculate ", calculatePositions);
    this.totalExhibitionPercent = (((this.totalPositions - calculatePositions) * 50) / this.totalPositions);
    console.log("TOTAL EXHIBITION PERCE", this.totalExhibitionPercent);

    if (exhibitionMarkValue != 0) {
      console.log("percentae", exhibitionMarkValue);
      exhibitionMark = exhibitionMark - exhibitionMarkValue;
    }
    console.log("exhibition mark ", exhibitionMark);
    this.totalExhibitionPercent = (this.totalExhibitionPercent) + (exhibitionMark);

    this.totalExhibitionPercent = Number(this.totalExhibitionPercent.toFixed(1));

    const response = this.dialog.open(NotificationPopupComponent, {
      width: 'auto',
      data: { title: "¿Desea calificar con un " + this.totalExhibitionPercent + "% ?" }
    });
    response.afterClosed().toPromise().then(result => {
      if (result === "yes") {
        this.postCalculate(this.totalExhibitionPercent)
        this.dialog.closeAll();
      } else {
        this.totalExhibitionPercent = this.backupTotalExhibitionPercent;
      }
    });
  }

  postCalculate(value) {
    if (this.totQtyFinal == 0) {
      this.totQtyFinalMark = 0;
    } else {
      let total = this.totQtyFinal + Number(value)
      this.totQtyFinalMark = (total / 2);
    }
    // UPDATE BD
    console.log("param_object for context ", this.context)
    let param_object = {
      partial_mark: this.totQtyFinal,
      exhibition_mark: this.totalExhibitionPercent,
      final_mark: this.totQtyFinalMark,
      out_of_portafolio: this.outOfPortafolio,
      empty_position: this.emptyPositions,
      total_empty_positions: this.totalPositions,
      exhibition_order: this.exhibitionMarkControl.value
    }
    console.warn("param_object ", param_object);
    this.metricData._nextMetricTabTotalMarks(this.context, param_object);
  }

  getPlaceObject(place_id: any) {
    let place = _.find(this.devicePlaces, function (obj) {
      // console.log("obj id", obj.id, place_id)
      if (obj.id == place_id) {
        return obj.name;
      }
    });
    return place;
  }

  addResumeInfo() {
    //console.log("ADD RESUME INFO");
    this.resetSummary()
    this.getSummary()
    this.finalMark();
  }


  // Show generic element: 
  // Ex: devices | accessories | homes | images
  getElements() {
    let elements: Tab[] = _.cloneDeep(this.data[this.plural_context]);
    return elements;
  }

  getElement() {
    // search element per placeId
    let elements = this.getElements();
    let $ = this;
    let element = _.find(elements, function (el) {
      return el.place_id === $.currentPlaceID;
    })
    return element;
  }

  // show elements image | table
  showElement() {

    let element = this.getElement();
    if (element) {
      // set images
      this.showImages()
      // set items
      this.currentItems.unshift(...element.items as any[]);
      //console.log('currentItems', this.currentItems);
      this.metricData.currentItems$.next(this.currentItems);
    }

  }

  showImages() {
    let elements = this.getElements();
    this.currentImages = [];
    this.countImages = 0;
    for (let item of elements) {
      if (item.images) {
        // this.currentImages.unshift(...item.images as any[]);
        for (let img of item.images) {
          let img_: any = img
          img_.id = this.countImages++;
          img_.place = item.place_id;
          this.currentImages.push(...[img_]);
        }
      }
    }
    this.metricData.currentImages$.next(this.currentImages);


  }
  // Var resets
  resetSummary() {
    this.totQtyDisplayed = 0;
    this.totQtyCard = 0;
    this.totQtyPlaceCard = 0;
    this.totQtyPlaceDisplayed = 0;
    //reset total points
    this.totQtyGrayPoint = 0;
    this.totQtyGreenPoint = 0;
    this.totQtyRedPoint = 0;

    this.totQtyYellowPoint = 0;
    this.totQtyYellowPointDisplayed = 0;
    this.totQtyYellowPointCard = 0;

    this.totQtyBluePoint = 0;
    this.totQtyBluePointDisplayed = 0;
    this.totQtyBluePointCard = 0;

  }

  // Summary
  getSummary() {

    let elements: Tab[] = _.cloneDeep(this.data[this.plural_context]);
    let $ = this;
    let restDisplay = 0;
    let restCard = 0;

    _.forEach(elements, function (el) {
      // calculate general items
      let items = _.cloneDeep(el.items);
      $.totQtyDisplayed += _.filter(items, { 'item_type_code': 'displayed', 'display_on': true }).length;
      $.totQtyCard += _.filter(items, { 'item_type_code': 'card', 'display_on': true }).length;

      // calculate place items
      if (el.place_id == $.currentPlaceID) {
        let items_place = _.cloneDeep(el.items);

        // DISPLAYED VALUES 
        $.totQtyGreenPoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': true }).length;
        $.totQtyYellowPoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 2 }).length;
        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 1 }).length;

        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 4 }).length;
        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 7 }).length;
        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 10 }).length;


        $.totQtyBluePoint += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 3 }).length;
        $.totQtyYellowPointDisplayed += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 2 }).length;
        $.totQtyBluePointDisplayed += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': false, 'cause_off': 3 }).length;

        // CARD VALUES
        $.totQtyGreenPoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': true }).length;
        $.totQtyYellowPoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 2 }).length;
        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 1 }).length;

        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 4 }).length;
        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 7 }).length;
        $.totQtyRedPoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 10 }).length;

        $.totQtyBluePoint += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 3 }).length;
        $.totQtyYellowPointCard += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 2 }).length;
        $.totQtyBluePointCard += _.filter(items_place, { 'item_type_code': 'card', 'display_on': false, 'cause_off': 3 }).length;


        $.totQtyPlaceCard += _.filter(items_place, { 'item_type_code': 'card', 'display_on': true }).length;
        $.totQtyPlaceDisplayed += _.filter(items_place, { 'item_type_code': 'displayed', 'display_on': true }).length;
      }

    });
    //console.log("GENERAL DISPLAYED ? : ", $.totQtyDisplayed);
    //console.log("GENERAL CARD ? : ", $.totQtyCard);
    //console.log("NORMAL DISPLAYED ? : ", $.totQtyPlaceDisplayed);
    //console.log("NORMAL CARD ? : ", $.totQtyPlaceCard);

    this.getSummaryTotal();
    this.getSummaryTotalPlace();

  }

  getSummaryTotal() {
    // general percents
    // this.totQtyDisplayedPercent = this.percent(this.totQtyDisplayed, this.totQty);
    // this.totQtyCardPercent = this.percent(this.totQtyCard, this.totQty);

    this.totQtyDisplayedPercent = this.percent(this.totQtyDisplayed, this.totQtyDisplayedFinal);
    this.totQtyCardPercent = this.percent(this.totQtyCard, this.totQtyCardFinal);



    if ((this.context == 'device') || (this.context == 'accessory')) {
      this.totQtyFinal = this.prom(this.totQtyDisplayedPercent, this.totQtyCardPercent);
    } else {
      this.totQtyFinal = this.totQtyDisplayedPercent;
    }
    this.totQtyFinal = Number(this.totQtyFinal.toFixed(1));

    if (this.context == 'home' || this.context == 'image') {
      console.warn("calling postCalculate init")
      this.postCalculate(100);
    }
  }

  getSummaryTotalPlace() {
    // place percents
    // debugger;
    //console.log('this.totQtyPlaceDisplayed, this.totQtyPlace', this.totQtyPlaceDisplayed, this.totQtyPlace)
    // this.totQtyPlaceDisplayedPercent = this.percent(this.totQtyPlaceDisplayed, this.totQtyPlace);
    this.totQtyPlaceDisplayedPercent = this.percent(this.totQtyPlaceDisplayed, (this.totQtyDisplayedPlaceMark - this.totQtyYellowPointDisplayed - this.totQtyBluePointDisplayed));


    // this.totQtyPlaceCardPercent = this.percent(this.totQtyPlaceCard, this.totQtyPlace);
    this.totQtyPlaceCardPercent = this.percent(this.totQtyPlaceCard, (this.totQtyCardPlaceMark - this.totQtyYellowPointCard - this.totQtyBluePointCard));


    //PARCIAL MARK 
    if (this.context == 'home' || this.context == 'image') {
      this.totQtyPlaceProm = (Number(this.totQtyPlaceDisplayedPercent));
      this.totQtyPlaceProm = Number(this.totQtyPlaceProm.toFixed(1));
    } else {
      this.totQtyPlaceProm = this.prom(Number(this.totQtyPlaceDisplayedPercent), Number(this.totQtyPlaceCardPercent));
      this.totQtyPlaceProm = Number(this.totQtyPlaceProm.toFixed(1));
    }



    // this.finalMark();
  }

  percent(num1: number, num2: number): number {
    return (num1 / num2) * 100;
  }

  prom(num1, num2) {
    if (isNaN(num1) || isNaN(num2)) { // check args
      return 0; // error get 0
    } else {
      return (num1 + num2) / 2; // percent
    }
  }

  // get qty of element in the table
  setCountElement(event: number) {
    console.log('count table', event);
    if (event) {
      this.totQtyPlace = event;
      this.totQtyDisplayedPlaceMark = event;
      this.totQtyCardPlaceMark = event;
      this.getSummaryTotalPlace();
    }
  }

  // End summary
  optionSelected(event: MatAutocompleteSelectedEvent) {
    console.log("optionSelected", event);
    this.tabName = event.option.value;
    //// console.log("devices places = ", this.devicePlaces);
    let place = _.find(this.devicePlaces, function (obj) {
      if (obj.name === event.option.value) {
        // console.log("obj id = ", obj.name);
        //// console.log("event option vaue = ", event.option.value);
        return obj.id;
      }
    });
    this.actualPlaceObj = place;
    place = place.id;
    this.currentPlaceID = place;
    this.actualPlaceId$.next(this.currentPlaceID);
    // console.log("current place name ? = ", place);
    // console.log("current place id ? = ", this.currentPlaceID);
    // console.log("actual place id subject = ", this.actualPlaceId$.getValue());
    this.metricData.disabledBtn$.next(true);
  }

  exhibitionPercentSelected(event: any) {
    this.percentageSelected = event.option.value;
    this.exhibitionMarkControl.setValue(this.percentageSelected);
    console.log('Opción seleccionada:', event.option.value);
  }



  // get total elements
  getElementCount() {

    if (this.element_count != null) {
      this.calculateElement(this.element_count);
      return;
    }

    let params = {
      place__element_type: this.context,
      'subsidiary': this.metricData.metricDataSubject$.getValue().subsidiary,
      'stat_programming': this.metricID,
    }

    console.log("getElementCount Params:", params)
    this.services.getElementofTableCount(params).subscribe(
      data => {
        this.element_count = data.count;
        this.calculateElement(this.element_count);

      }, error => {
        //console.log("unhandled local error", error);
      }
    )
  }

  calculateElement(count) {
    this.totQty = count;
    // call summary
    this.contDisplayed = this.countItemsWithCauseOff(this.metricData.metricDataSubject$.getValue(), 'displayed');
    this.contCard = this.countItemsWithCauseOff(this.metricData.metricDataSubject$.getValue(), 'card');
    this.totQtyDisplayedFinal = this.totQty - this.contDisplayed;
    this.totQtyCardFinal = this.totQty - this.contCard;
    this.getSummary();
  }

  // DIALOGS
  openImageDialog(imageUrl: string, comment: string, place: any) {
    const plural = this.plural_context;
    let canEdit = true;
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: { imageUrl, comment, place, plural, canEdit },
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getElements();
      this.showImages();
    });


  }

  openDialog() {
    let actualPlace = this.actualPlaceObj
    let plural_context = this.plural_context;
    const dialogRef = this.dialog.open(AddPlaceImageComponent, {
      data: { actualPlace, plural_context },
      width: '500px',
    });
  }

  // FUNCTION FILTERS
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.placesList.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filter_two(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options_two.filter(options_two => options_two.toLowerCase().includes(filterValue));
  }

  deleteImage(photo: any) {
    // let elements = this.getElements();
    let element = this.metricData.metricDataSubject$.getValue();
    let elements = element[this.plural_context]
    let imageSearch = photo.image
    for (let element of elements) {
      // console.log("current images : ", elements);
      // console.log("acutal firebase? ", photo);
      let images = element.images;
      if (images != null) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].image == imageSearch) {
            images.splice(i, 1);
            break;
          }
        }
      }
    }

    this.showImages();
  }

  countItemsWithCauseOff(obj: any, item_type_code): number {
    let count = 0;

    // FOR EACH AT DEVICES
    _.each(obj[this.plural_context], (tab: any) => {
      // FILTER THE ITEMS THAT HAS "cause_off" and "item_type_code" IN THEIR CONDITIONS
      const filteredItems = _.filter(tab.items, (item: any) =>
        [2, 3, 5, 6, 8, 9, 11, 12].includes(item.cause_off || 0) && item.item_type_code === item_type_code
      );

      // INCREMENT THE COUNT WITH THE FILTERED ITEMS
      count += filteredItems.length;
    });


    return count;
  }

  clear() {
    this.placeControl.setValue('');
    this.showTable = false;
    this.metricData.disabledBtn$.next(false);
  }


  extractSubsidiaryName(fullName: string): string {
    const parts = fullName.split(" - ");
    if (parts.length > 1) {
      return parts[1].trim();
    } else {
      return fullName;
    }
  }

  onSaveClick() {
    this.saveClicked.emit();
  }


}


// window.addEventListener("beforeunload", (event) => {
//   event.returnValue = true;
// });
