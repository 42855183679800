import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MetricModel } from "./metric-data.interface";
import * as _ from 'lodash';
import { result } from "lodash";

@Injectable({
    providedIn: 'root'
})

export class Subjects {

    contDashboardTable$ = new BehaviorSubject(null);
    disabledBtn$ = new BehaviorSubject(null);
    tabManage$ = new BehaviorSubject(null);
    currentItems$ = new BehaviorSubject(null);
    currentSubsidiaryName$ = new BehaviorSubject(null);
    currentImages$ = new BehaviorSubject(null);
    spinnerTable$ = new BehaviorSubject(null);
    allCauses$ = new BehaviorSubject(null);
    allPlaces$ = new BehaviorSubject(null);
    userActive$ = new BehaviorSubject(false);
    userInfo$ = new BehaviorSubject(null);
    metricDataSubject$ = new BehaviorSubject<MetricModel>(<MetricModel>(
        {
            subsidiary: null,
            stat_programming: null,
            comment: null,
            devices: [

            ],
            custom_date: null,
        }
    ));

    userPermission$ = new BehaviorSubject(null);

    _nextMetricSub(subsidiary_id: number) {
        // console.log("subsidiary choosed: ", subsidiary_id);
        this.metricDataSubject$.next({
            ...this.metricDataSubject$.value,
            subsidiary: subsidiary_id
        });
        // console.log(this.metricDataSubject$.getValue());
    }

    // _nextMetricPlaceDevice(place: number, index: any = null, custom_score: number = null, image_url: any = null, comment_image: any, item_data : any = null) {
    //     let md = this.metricDataSubject$.getValue()
    //     let custom = 0;
    //     let validate = 0;
    //     let obj = {
    //         place_id: place,
    //         custom_score: 0,
    //         images:[],
    //         items: [],
    //     }
    //     for (let item of md.devices) {
    //         if (item.place_id === place) {
    //             // if  exists ->  modify inner variables.
    //             item.custom_score = custom_score;
    //             item.items.push(item_data);
    //             if (image_url) {
    //                 item.images.push({
    //                     image : image_url,
    //                     comment : comment_image
    //                 });
    //             }
    //             validate = 1;
    //             break;
    //         }
    //     }
    //     // if doesn't exist -> create and push a new empty object.
    //     if (validate === 0) {
    //         md.devices.push(obj)
    //     }

    //     this.metricDataSubject$.next(md);
    //    // console.log(this.metricDataSubject$.getValue());
    // }

    _nextMetricTabTotalMarks(context: any, params: any) {
        let md = this.metricDataSubject$.getValue()
        md[context] = params;
    }

    _nextMetricSubsidiaryDate(custom_date: string){
        let md = this.metricDataSubject$.getValue()
        md['custom_date'] = custom_date;
        console.log("md date : ", md);
    }

    _nextMetricPlaceDevice(place: number, index: any = null,
        custom_score: string = null, image_url: any = null, item_data: any = null, plural_context: any,
        empty_position = null, out_of_brief = null
    ) {
        let md = this.metricDataSubject$.getValue()
        let validate = 0;

        let obj = {
            place_id: place,
            custom_score: 0,
            images: [],
            items: [],
            empty_position: 0,
            out_of_brief: 0,
        }

        let element = md[plural_context];
        let checkPlace = _.findIndex(element, function (el) {
            // console.log("place id? - place ", el['place_id'], place);

            return el['place_id'] == place;
        })
        console.log("element md? ", element);
        // console.log("checkplace? ", checkPlace);

        if (checkPlace < 0) {
            element.push(obj)
        }

        console.log("CHEEEEK ", checkPlace);

        for (let item of element) {

            if (item.place_id === place) {
                if (custom_score) {
                    item.custom_score = custom_score;
                }
                if (item_data) {
                    item.items.push(item_data);
                }
                if (image_url) {
                    console.log("image url content ", image_url);
                    if (image_url.place) {
                        console.log("saving in place : ", image_url.place);
                        if (item.images == null) {
                            item.images = [];
                        }
                        delete image_url.place;
                        delete image_url.id;
                        item.images.push(image_url);
                    } else {
                        console.log("saving in especific : ");
                        let elementId = image_url.element;
                        let itemsToSetImage = _.filter(item.items, function (el) {
                            return el['element'] == elementId;
                        });
                        console.log("item to set image ", itemsToSetImage);
                        itemsToSetImage.forEach(function (itemToSetImage) {
                            itemToSetImage['image'] = image_url.image;
                            itemToSetImage['comment'] = image_url.comment;
                        });
                    }
                }
                if (empty_position) {
                    item.empty_position = empty_position;
                } else {
                    // item.empty_position = 0;
                }
                if (out_of_brief) {
                    item.out_of_brief = out_of_brief;
                } else {
                    // item.out_of_brief = 0;
                }
                validate = 1;
                break;
            }
        }

        // if doesn't exist -> create and push a new empty object.
        // if (validate === 0) {
        //     element.push(obj)
        // }


        console.log('_nextMetricPlaceDevice check-option =>');

        this.metricDataSubject$.next(md);
        // console.log(this.metricDataSubject$.getValue());
    }

    getElementItem(element_id, plural_context, place_id) {
        console.log("elemtns ", element_id, plural_context, place_id);
        let metric = _.cloneDeep(this.metricDataSubject$.getValue())
        console.log("metrics from normal", this.metricDataSubject$.getValue());

        let places = metric[plural_context];
        let place = _.find(places, item => {
            return item.place_id == place_id;
        })
        console.log("PLACE ITEMS =", place);
        let items = null;
        if (place) {
            items = _.filter(place.items, item => {
                return item.element == element_id
            })
        }

        console.log("only item", items);
        return items;
    }
}