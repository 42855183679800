<!-- 
<ngx-spinner
bdColor="rgba(0, 92, 255, 0.5)"
size="medium"
color="#fff"
type="ball-clip-rotate"
fullScreen = "false"
>
<p style="font-size: 16px; color: white">
Cargando...
</p>
</ngx-spinner> -->

<ngx-spinner
  bdColor="rgba(0, 92, 255, 0.05)"
  size="medium"
  color="#1261fb"
  type="ball-clip-rotate"
  fullScreen="false">
  
  <p style="font-size: 16px; color: #7592cd">Cargando...</p>
</ngx-spinner>
