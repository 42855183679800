import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subjects } from 'src/app/utils/subjects';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
  selector: 'app-add-photo',
  templateUrl: './add-photo.component.html',
  styleUrls: ['./add-photo.component.scss']
})
export class AddPhotoComponent implements OnInit {

  imageUrl: string;
  currentImages = [];
  disabled = false;
  uploadButtonDisabled = true;
  myTextarea = new FormControl('', Validators.required);
  myFile = new FormControl(null, Validators.required);

  constructor(
    private metric: Subjects,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddPhotoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      actualPlace: any, plural_context: any, element: any;
    }
  ) { }

  ngOnInit(): void {
    console.table(this.data);
  }

  onSave() {

    let image = {
      'image': this.imageUrl,
      'comment': this.myTextarea.value,
      'element': this.data.element.id
    }

    this.metric._nextMetricPlaceDevice(this.data.actualPlace, null, null, image, null, this.data.plural_context);

    let images = _.cloneDeep(this.metric.currentImages$.getValue());
    // images.unshift(image);
    this.metric.currentImages$.next(images);
    this.dialog.closeAll()
    // console.log("current images save ", this.metric.currentImages$.getValue());


  }

  

  // FILES
  onSendUpload(url: string) {
    this.imageUrl = url;
    this.currentImages.unshift(this.imageUrl);
    this.disabled = true;
    this.uploadButtonDisabled = false;
  }

  removeImage(index: number) {
    this.currentImages.splice(index, 1);
    //not removing on firebase store.
  }

  // DIALOGS
  openImageDialog(imageUrl: string) {
    this.dialog.open(ImageDialogComponent, {
      data: { imageUrl },
      width: 'auto',
    });
  }


}
