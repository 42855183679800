<div *ngIf="!permission" class="forbidden">
    <h2>Acceso Denegado</h2>
</div>


<!-- <div class="headTitle" *ngIf="permission">
    <h1 class="title-state">Dashboard <b class="bColor">Foto de Éxito</b></h1>
    <div class="logo" routerLink="/v4">
        <img src="assets/svg/logo-entel.svg">
    </div>
</div> -->

<mat-card *ngIf="permission">
    <!-- <div *ngIf="setChart" class="card card-adjust">
        <div>
            <canvasjs-chart [options]="chartOptions" 
            [styles]="{width: '100%', height:'360px'}">
        </canvasjs-chart>    
    
        </div>
    </div> -->
    <div class="row" style="display: flex; align-items: center;">
        <div class="col initial-text" style="text-align: left; width: 50%;">
            <p><span class="bold">Evaluación </span>de tiendas</p>
        </div>
        <div class="col" style="display: flex; justify-content: center; align-items: center; margin-top: -10px; height: 40px;">
            <!-- <h2 class="incidence">{{totalChecked}}<span class="modern">/{{totalItemsShow}}</span></h2> -->
            <h2 class="incidence">{{totalChecked}}<span class="modern">/{{totalNotChecked}}</span></h2>

        </div>
        <div class="col progress-container" style="text-align: right; width: 50%;">
            <div class="progress-bar" [style.width.%]="progress"></div>
            <div class="progress-text-left">{{ progress }}%</div>
            <div class="progress-text-complete" *ngIf="progress === 100">Completado</div>
            <div class="progress-text-right">{{ 100 - progress }}%</div>
        </div>
    </div>


    <table mat-table [dataSource]="dataSource3" class="mat-elevation-z8">

        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> PDV </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Evaluada </th>
            <!-- <td mat-cell *matCellDef="let element">
                <mat-icon class="{{ element.isOK ? 'icon-ok' : 'icon-cancel' }}">{{ element.isOK ? 'check_circle' :
                    'cancel' }}</mat-icon>
            </td> -->
            <td mat-cell *matCellDef="let element" class="mat-cell-image">
                <img *ngIf="element.isOK" src="assets/resources/Recurso5@3x.png" alt="Ok">
                <img *ngIf="!element.isOK" src="assets/resources/Recurso6@3x.png" alt="Not Ok">
            </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle [checked]="element.isActive" (change)="onSlideToggleChange($event, element)">
                </mat-slide-toggle>
                
            </td>
        </ng-container>

        <ng-container matColumnDef="wasNotified">
            <th mat-header-cell *matHeaderCellDef> Tienda Notificada </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.wasNotified" class="icon-ok">check_circle</mat-icon>
                <mat-icon *ngIf="!element.wasNotified" class="icon-cancel">cancel</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsData"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsData;"></tr>
    </table>


</mat-card>


<app-spinner [spinner]="spinner"></app-spinner>