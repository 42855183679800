import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss']
})
export class ShowImageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShowImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      image: any,
      comment: any,
    }) { 
      this.data = data;
    }

  ngOnInit(): void {
    console.log("data ? ", this.data);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
