import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddPhotoComponent } from 'src/app/component/add-photo/add-photo.component';
import { ApiService } from 'src/app/services/services.service';
import { Subjects } from 'src/app/utils/subjects';
import { Utils } from 'src/app/utils/utils';
import { CircleOptionComponent } from './circle-option/circle-option.component';

export interface TableData {
  code: string;
  name: string;
  displayed: string;
  card: string;
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  @Input() placeID: number;
  @Input() spinner: boolean;
  @Input() actualPlaceId;
  @Input() plural_context;
  @Input() causes;
  @Input() metricID;
  @Input() subsidiary;


  @Output() countElement: EventEmitter<number> = new EventEmitter();
  @Input() context = ''

  options: any[];
  default = 0;
  // spinner: boolean;
  columnsData: string[] = ['code', 'name', 'displayed', 'card', 'image'];
  tableData: TableData[] = [];
  dataSource3 = new MatTableDataSource<TableData>(this.tableData);
  display = true;
  instanceID = '0000';
  countData = 0;

  constructor(
    private service: ApiService,
    private subject: Subjects,
    private utils: Utils,
    private dialog: MatDialog,
  ) { 

    this.instanceID = utils.generateRandomNumber();
  }

  ngOnInit(): void {
    
    this.spinner = true;
  //  // console.log("se ha iniciado ")
  //   // this.countElement.emit(0);

    this.subject.spinnerTable$.subscribe(
      data => {
        if (data) {
          this.spinner = true;
          setTimeout(() => {
            this.spinner = false;
          }, 100);
        }
      },
      error => {
       // console.log("error spinner charger", error);
      }
    )
  }


  // ngAfterViewInit() {
  //   this.dataSource3.paginator = this.paginator;
  //   this.dataSource3.sort = this.sort;
  //   this.paginator._intl.itemsPerPageLabel = "Items por Página";
  // }

  ngOnChanges(changes: SimpleChanges) {
    console.log("GenericTableComponent changes", changes);
    if (changes['context']) {
      if (this.context === 'home' || this.context === 'image') {
        this.columnsData = ['code', 'name', 'displayed', 'image'];
      }
    }
    if (changes['placeID']) {
      this.getData()
    }
  }

  getData() {
    let params = {
      'place': this.placeID,
      'subsidiary': this.subsidiary
      // 'is_active' : true,
    }
    if (this.metricID){
      params['stat_programming'] = this.metricID;
    }
    console.log("Params element gen", params)

    this.tableData = [];
    this.execDataSource();
   // console.log('params table', params);
    this.service.getElementofTable(params).subscribe(
      data => {
        this.tableData = data;
       console.log("data in table -> ", this.instanceID,  data);
        this.countData = this.tableData.length;
        this.countElement.emit(this.tableData.length);
        //// console.log("data table : ", this.tableData);
        // this.setElement()
        this.execDataSource();
        this.display = true;
        this.spinner = false;
      }, error => {
       // console.log("error data table api ", error);
      }
    )
  }

  execDataSource() {
    console.log("EXE DATA SOURCE ");
    setTimeout(() => {
      this.dataSource3 = new MatTableDataSource(this.tableData);
      this.dataSource3.paginator = this.paginator;
      this.dataSource3.sort = this.sort;
    }, 100);
  }

  // SEARCH BAR
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource3.filter = filterValue.trim().toLowerCase();
  // }

  applyFilter(event: Event) {
   // console.log("evvent?",event);
   // console.log("this data", this.dataSource3);
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
   // console.log("this filter",filterValue);

    this.dataSource3.data = this.tableData.slice().sort((a, b) => {
      const searchStringA = a.code.toLowerCase() + a.name.toLowerCase();
      const searchStringB = b.code.toLowerCase() + b.name.toLowerCase();
      return searchStringA.includes(filterValue) ? -1 : searchStringB.includes(filterValue) ? 1 : 0;
    });
   // console.log("after point data", this.dataSource3);

  }

  

}

