<div class="dialog-container">
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <img class="image-style" [src]="data.image">
  <div class="content-info">
    <h5>Observación: {{ data.comment }}
    </h5>
  </div>
</div>