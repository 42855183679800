export const environment = {
  production: false,
  org: 3,
  version: 'v4',
  urlElasticSearch: '/_search',
  urlElasticAuth: 'https://0abd1e5658914e9e9c49a2ea436ce279.us-west1.gcp.cloud.es.io/_security/oauth2/token/',
  apiKeyElasticSearch: 'M2F6a2dub0JqR3d1aWxkMV9NSnQ6U091aEhibl9TdEdsaXo2QnpXWlFYQQ==',
  urlBase: 'https://rinnolab.cl/',
  localUrlBase: 'https://pdv.rinnolab.cl/',

  firebase: {
    apiKey: "AIzaSyAshdL3iVYjthSu-KazxSiMQctg7PP6tys",
    authDomain: "rinno-dashboard.firebaseapp.com",
    databaseURL: "https://rinno-dashboard.firebaseio.com",
    projectId: "rinno-dashboard",
    storageBucket: "rinno-dashboard.appspot.com",
    messagingSenderId: "1044823546162",
    appId: "1:1044823546162:web:1e35c47761512ff81f9f2b"
  },

  // New firebase for development only 
  // const firebaseConfig = {
  //   apiKey: "AIzaSyAwXITHGjfNteUi-zEEwzMufGjqiT_BESg",
  //   authDomain: "pdv-entel.firebaseapp.com",
  //   projectId: "pdv-entel",
  //   storageBucket: "pdv-entel.appspot.com",
  //   messagingSenderId: "692041903871",
  //   appId: "1:692041903871:web:5c940b98d242b0b6ce66ca"
  // };

  certificatePem: `-----BEGIN CERTIFICATE-----
  MIIDSTCCAjGgAwIBAgIUDZ/AJ/4F2Hi+obFW05L97dNpygQwDQYJKoZIhvcNAQEL
  BQAwNDEyMDAGA1UEAxMpRWxhc3RpYyBDZXJ0aWZpY2F0ZSBUb29sIEF1dG9nZW5l
  cmF0ZWQgQ0EwHhcNMjIxMTIwMDQ0NDQ4WhcNMjUwMTA4MDQ0NDQ4WjA0MTIwMAYD
  VQQDEylFbGFzdGljIENlcnRpZmljYXRlIFRvb2wgQXV0b2dlbmVyYXRlZCBDQTCC
  ASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBALRMtY3PydxMDoKquYdoDi5W
  O6ewpUsrhQn3Svijf0mewNjcw0KfxgsZ+Np6QVnHiSlRoMzlBBZU7Fy+7sz88I9i
  fLlfR2aaNpbmqhSFQNKauqHmJqT8O2h1rfCE4VUsFsZ10BvBT+6i982JZtypaLVM
  btCRXbM0X9PSGPSn4o/EEXe65/TkZM0n3kvaL3/R03CLhEF3fyZPnir5vsJPb/2j
  TAcmPIeHd5FYiv3FmYTQGEJ8WutLlGQxDa+zNOjfDWk5tz824/+Rv8NajtaWjwps
  s/5nxWx4ryjQL9fRiTh9CyA1aK8kQnJq+p9crC2qd9tjxHdFkAh5OIcCDdZTWQ8C
  AwEAAaNTMFEwHQYDVR0OBBYEFOIUlvcGRkJxu8WEzT+lmnIK2xeXMB8GA1UdIwQY
  MBaAFOIUlvcGRkJxu8WEzT+lmnIK2xeXMA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZI
  hvcNAQELBQADggEBAEg30ACXN7gKUftPDfMtxskZZa25LAgGc7xRZ8EaUA+FDYS2
  OrNG4DmmHPBtPt8lS2d0fR05CicjPweGhzJOQmLIUxVg+QpvyT7HfxmTWZ5K7L52
  qfPEi9ToJnWKFSq3Rac5PT/yOEhKvwjtWx7LDN1vX7L1VBauQtW1HoC4BUKBSxMI
  44dg6W/lZdpGfAwcM+Mxkqv2/LokeZp79Ri5Digrq6HFOjhldoJFnlShcW840+35
  l2hPynXhYe6TBv5DWlH520jZbAu85cpHjVOMtpVDYIHXF8inNu3o1GPWA/dUnPdM
  aKxRulTIsjlHEMCQvtUAFmhKRcHH+8HCRUS1SuM=
  -----END CERTIFICATE-----
  `
};
