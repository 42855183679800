import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddPhotoComponent } from 'src/app/component/add-photo/add-photo.component';
import { ShowImageComponent } from 'src/app/component/show-image/show-image.component';
import { Subjects } from 'src/app/utils/subjects';
import * as _ from 'lodash';
import { ImageDialogComponent } from 'src/app/component/image-dialog/image-dialog.component';
import { EditImageDialogComponent } from '../edit-image-dialog/edit-image-dialog.component';


@Component({
  selector: 'app-btn-image',
  templateUrl: './btn-image.component.html',
  styleUrls: ['./btn-image.component.scss']
})
export class BtnImageComponent implements OnInit {


  @Input() element: any;
  @Input() actualPlaceId;
  @Input() plural_context;
  @Input() context;
  @Output() eventLoad: EventEmitter<boolean> = new EventEmitter();

  btnDropImageDisabled = true;
  object = null;

  constructor(
    private dialog: MatDialog,
    private subjects: Subjects,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['context']) {
      this.object = this.statusImage({ 'element': this.element.id, 'plural_context': this.plural_context, 'actualPlaceId': this.actualPlaceId.value })
      console.log("object ???", this.object);
    }
  }

  statusImage(obj: any) {
    try {
      let element_id = obj.element;
      let plural_context = obj.plural_context;
      let place_id = obj.actualPlaceId;
      let status = this.subjects.getElementItem(element_id, plural_context, place_id);
      if (status) {
        status = _.cloneDeep(status);
        let imageToShow = _.find(status, item => {
          return item.image != null;
        });
        if (imageToShow) {
          return imageToShow;
        }
      }

      return null;
    } catch (error) {
      console.error("Error en statusImage: ", error);
      return null;
    }
  }

  openDialog() {
    let actualPlace = this.actualPlaceId.getValue();
    let plural_context = this.plural_context;
    console.log("actual place -->", actualPlace);
    console.log("plural context -->", plural_context);
    console.log("element ? ", this.element);
    let element = this.element;
    const dialogRef = this.dialog.open(AddPhotoComponent, {
      data: { actualPlace, plural_context, element },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El diálogo se ha cerrado');
      // this.getData()
      this.eventLoad.emit(true);
    });
  }

  deteleImage(object: any) {
    console.log("deleting image.", object);
    let data = this.subjects.metricDataSubject$.getValue();
    console.log("data for delete image ", data);
    let elements = data[this.plural_context];
    console.log("data for delete image context ", elements);
    console.log("actual place", this.actualPlaceId.value);
    let place = _.find(elements, item => {
      return item.place_id == this.actualPlaceId.value;
    })
    console.log("PLACE places for delete =", place);
    let items = null;
    if (place) {
      items = _.filter(place.items, item => {
        return item.element == this.element.id
      })
    }

    for(let item of items){
      item.image = null;
      item.comment = null;
    }

    this.object = null;

    console.log("PLACE ITEMS for delete =", items);
    console.log("subject --> ", this.subjects.metricDataSubject$.getValue());


  }

  // openImageDialog(element: any): void {
  //   console.log("element to popup : ", element);
  //   const dialogRef = this.dialog.open(ShowImageComponent, {
  //     width: 'auto',
  //     data: {
  //       image: element.image,
  //       comment: element.comment,
  //     }
  //   });
  // }

  openImageDialog(element: any) {
    let plural = this.plural_context;
    let imageUrl = element.image;
    let comment = element.comment;
    let place = this.actualPlaceId.value;
    let canEdit = true;
    console.log("editing : ", imageUrl, comment, place, plural, canEdit);
    const dialogRef = this.dialog.open(EditImageDialogComponent, {
      data: { imageUrl, comment, place, plural, canEdit },
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('El diálogo se ha cerrado');
      // this.getData()
      this.eventLoad.emit(true);
    });
  }
}
