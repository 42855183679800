import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertComponent } from '../alert/alert.component';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { AuthenticationService } from '../services/authentication.service';
import { ApiService } from '../services/services.service';
import { Subjects } from '../utils/subjects';
import * as XLSX from 'xlsx';

export interface EntelData {
  name: string;
  position: number;
  weight: number;
  symbol: number;
  accesories: number;
  image: number;
  rank: number;
}

export interface EvaluationScale {
  scale: string;
  devices: number;
  home: number;
  accesories: number;
  image: number;
}

export interface Data_ {
  name: string;
  code: number;
  cell_value: number;
}

export interface SubData_ {
  name: string;
  code: number;
  device_final_mark: number;
  home_final_mark: number;
  accessory_final_mark: number;
  image_final_mark: number;
}

const ELEMENT_DATA: EntelData[] = [
  { position: 1, name: 'Comunicaciones Ketels', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 98 },
  { position: 2, name: 'CTF Tecnología SPA', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 97 },
  { position: 3, name: 'Cygnus Móvil', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 90 },
  { position: 4, name: 'Entel', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 89 },
  { position: 5, name: 'Express ltda.', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 85 },
  { position: 6, name: 'Fulltech', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 85 },
  { position: 7, name: 'Hbdtel', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 80 },
  { position: 8, name: 'Imaxtel', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 78 },
  { position: 9, name: 'Inversiones Farellones', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 77 },
  { position: 10, name: 'OBW Franco SPA', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 76 },
  { position: 11, name: 'Phonemarket', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 72 },
  { position: 12, name: 'Protein Chile SPA', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 65 },
  { position: 13, name: 'Quintel', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 50 },
  { position: 14, name: 'Sociedad Comercial Arretx ltda.', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 45 },
  { position: 15, name: 'Telefonia Celular', weight: 32, symbol: 32, accesories: 32, image: 32, rank: 20 },
];

// -> FILL WITH VARIABLES (DEVICES, HOME, ETC) AND THEN CHANGE IT INTO THE HTML COMPONENT.
// const export_data: EvaluationScale[] = [
//   { scale: '>', devices: 31.5, home: 27.5, accesories: 19.5, image: 19.5 },
//   { scale: '=', devices: 23.5, home: 20.5, accesories: 14.5, image: 14.5 },
//   { scale: '<', devices: 23.4, home: 20.4, accesories: 14.4, image: 14.4 },
// ]
const export_data: EvaluationScale[] = [
  { scale: '>', devices: 39.45, home: 9.45, accesories: 34.45, image: 14.45 },
  { scale: '=', devices: 31.45, home: 7.45, accesories: 29.45, image: 9.45 },
  { scale: '<', devices: 31.44, home: 7.44, accesories: 29.44, image: 9.44 },
];


@Component({
  selector: 'app-pdv',
  templateUrl: './pdv.component.html',
  styleUrls: ['./pdv.component.scss']
})

export class PdvComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() spinner: boolean;

  // API
  progress_spinner: boolean = false;
  entelData: any = [];
  entelDataSub: any = [];
  message_tmp: any;
  status_tmp: any;

  // VARIABLE COLORS DISPLAYED FOR TABLE OLD
  // greenColor = '#baefb8';
  // yellowColor = '#edf2b2';
  // redColor = '#fac2c5';

  // VARIABLE COLORS DISPLAYED FOR TABLE
  greenColor = '#41e8b4';
  yellowColor = '#a1fdee';
  redColor = '#fd3e00';
  show = "%"

  // AUTOCOMPLETE SEARCHING AND DROPDOWN BAR 
  myControl = new FormControl('');
  myControlPdv = new FormControl('');
  options: string[] = [];
  backupOptions: any[] = [];
  optionsPdv: string[] = [];
  filteredOptions: Observable<string[]>;
  filteredOptionsPdv: Observable<string[]>;
  franchiseValidate = false;
  pdvValidate = false;
  filterEnabled = true;

  franchiseNumber = 0;
  franchiseParam = 0;
  pdvFranchise: any;
  franchiseChoosed = false;
  flagData = true;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'accesories', 'image', 'rank'];
  dataSource = ELEMENT_DATA;

  displayedColumns2: string[] = ['scale', 'devices', 'home', 'accesories', 'image'];
  dataSource2 = export_data;

  displayedColumnsEntel: string[] = ['code', 'name', 'franchise', 'device', 'home', 'accesories', 'image', 'mark']
  dataSource3 = new MatTableDataSource<any>(this.entelData);


  displayedColumnsSubsidiary: string[] = ['code', 'name', 'franchise', 'device', 'home', 'accesories', 'image', 'mark']
  dataSource7 = new MatTableDataSource<any>(this.entelDataSub);

  // displayedColumnsFranchise: string[] = ['code', 'franchise', 'device', 'home', 'accesories', 'image', 'mark']
  displayedColumnsFranchise: string[] = ['franchise', 'device', 'home', 'accesories', 'image', 'mark']
  dataSource6 = new MatTableDataSource<any>(this.entelDataSub);
  backupSource6 = new MatTableDataSource<any>(this.entelDataSub);


  actualStatSelector: any;
  metricForm = new FormControl(null);
  showMetricForm = false;
  metricID = null;
  franchise2 = null;



  // NOTIFICATIONS FOR THE BELL ICON SECTION
  notifications = [
    { title: 'Nuevo cambio en iphone X', timestamp: '2 hours ago' },
    { title: 'Actualizar Equipos', timestamp: '1 day ago' },
    { title: 'Actualizar Dispositivos', timestamp: '2 days ago' },
    { title: 'Actualizar Dispositivos', timestamp: '2 days ago' }
  ];

  constructor(
    private apiService: ApiService,
    private alert: AlertComponent,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private subjects: Subjects,
    private auth: AuthenticationService,
    private metricData: Subjects
  ) { }


  ngOnInit(): void {
    this.spinner = true;
    console.log("user pdv");
    this.auth.saveUser('/pdv');

    this.apiService.getActualStat().subscribe(
      data => {
        console.log("month stats = ", data);
        this.actualStatSelector = data;
        this.spinner = false;
        this.metricFormSuscriptor();
        this.metricForm.setValue(this.actualStatSelector[0].name)
      }
    )

    // this.myControl.statusChanges.subscribe(
    //   data => {
    //     if(data){
    //       console.log("option selected? ", data);
    //       // this.permissions();
    //     }
    //   }
    // )

  }

  // metricFormSuscriptor() {
  //   this.metricForm.valueChanges.subscribe(
  //     data => {
  //       if (data) {

  //         console.log("metricForm data", data);
  //         // const $ = this
  //         let idMetricStat = _.find(this.actualStatSelector, function (obj) {
  //           const value = data;
  //           if (obj.name === value) {
  //             return obj
  //           }
  //         });

  //         this.metricID = idMetricStat.id;
  //         console.group("metric id", this.metricID)
  //         this.myControl.setValue('')
  //         this.dataSource7 = null;
  //         this.getFranchise();
  //         this.getMetrics();
  //       }
  //     }
  //   )
  // }

  metricFormSuscriptor() {
    this.metricForm.valueChanges.subscribe(
      data => {
        if (data) {
          console.log("metricForm data", data);
          let idMetricStat = _.find(this.actualStatSelector, obj => obj.name === data);

          if (idMetricStat) {
            this.metricID = idMetricStat.id;
            console.group("metric id", this.metricID);
            this.myControl.setValue('');
            this.dataSource7 = null;
            this.getFranchise();
            this.entelDataSub = null;
            this.getMetrics();
          }
        }
      }
    );
  }


  // permissions() {
  //   this.metricData.userPermission$.subscribe(
  //     data => {
  //       if (this.flagData) {
  //         if (data) {
  //           let validation: string = data.pdv_view;
  //           if (validation.includes("FRANQ")) {
  //             let franchise = _.find(this.backupOptions, {
  //               id: data.franchise
  //             })
  //             this.filterEnabled = false;
  //             this.optionSelected(null, franchise.name);

  //           }
  //         }
  //         this.init();
  //         this.flagData = false;
  //       }
  //     }
  //   )
  // }


  permissions() {
    let data = this.metricData.userPermission$.getValue()
    console.log("persmission data", data);
    if (data) {
      let validation: string = data.pdv_view;
      if (validation.includes("TODOS")) {
        console.log("this mycontrol", this.myControl.value);
        if (this.myControl.value) {
          // this.getSubsidiaries(this.getFranchiseId(this.myControl.value));
          this.optionSelected(this.myControl.value, null);
        }
      }
      if (validation.includes("FRANQ")) {
        let franchise = _.find(this.backupOptions, {
          id: data.franchise
        })
        // this.backupOptions = [franchise];
        console.log("backup opts", this.backupOptions)
        console.log("franchise name ", franchise);
        // this.filterEnabled = false;
        this.optionSelected(franchise.name, null);

      }
    }
    this.init();
    this.flagData = false;
  }


  init() {
    // SEARCH FILTER
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.filteredOptionsPdv = this.myControlPdv.valueChanges.pipe(
      startWith(''),
      map(value => this._filterPdv(value || '')),
    );
  }

  getFranchise() {
    this.apiService.getFranchise().subscribe(
      data => {
        let permission = this.metricData.userPermission$.getValue()
        console.log("persmission data", permission);
        if (permission) {
          let validation: string = permission.pdv_view;
          if (validation.includes("FRANQ")) {
            this.franchise2 = permission.franchise;
          }
        }
        this.backupOptions = data;
        for (let i = 0; i < data.length; i++) {
          if (this.franchise2) {
            if (this.franchise2 == data[i].id) {
              this.options.push(data[i].name);
            }
          } else {
            this.options.push(data[i].name);
          }
        }
        this.franchiseValidate = true;
        this.permissions();
      }
    )
  }

  // ENTEL DATA API FOR FRANCHISE
  getMetrics() {
    let params = {
      'stat_programming': this.metricID
    }
    this.dataSource6 = null;
    this.backupOptions = null;
    this.entelDataSub = null;
    this.spinner = true;
    console.log("evaluation mark params ", params);
    this.apiService.getEvaluationMark(params).subscribe(
      data => {
        this.backupOptions = data.results.franchise;
        console.log("dataaaa???", data)
        this.entelDataSub = data.results.franchise
        this.dataSource6 = this.entelDataSub;
        this.backupSource6 = this.entelDataSub;
        this.spinner = false;
      },
      error => {
        // console.log('error', error);
        this.message_tmp = '¡Ocurrió un problema, por favor intente nuevamente!';
        this.status_tmp = 'alert-danger';
        this.alert.changeFromAlert();
      }
    );
  }

  getEvaluationMark(franchiseParam) {
    let params = {
      'stat_programming': this.metricID
    }
    if (franchiseParam) {
      params['franchise'] = franchiseParam;
    }
    this.apiService.getEvaluationMark(params).subscribe(
      data => {
        if (data) {
          console.log("get evaluation mark", data);
          this.pdvFranchise = data.results.franchise;
          this.optionsPdv = [];
          let listOpt = [];
          for (let i = 0; i < this.pdvFranchise.length; i++) {
            listOpt.push(this.pdvFranchise[i].name)
          }
          this.optionsPdv.push(...listOpt);
          // this.filteredOptionsPdv.
          this.pdvValidate = true;
          // console.log("franchise filter data", this.pdvFranchise);
          this.franchiseChoosed = true;
          this.dataSource6 = this.pdvFranchise;

          this.entelDataSub = data.results.pdv
          if (this.franchise2) {
            this.entelDataSub = _.filter(this.entelDataSub, {
              franchise: this.franchise2
            })
          }

          this.dataSource7 = this.entelDataSub.map(obj => {
            const nameParts = obj.name.split(" - ");
            obj.name = nameParts.length > 1 ? nameParts[1] : obj.name;
            return obj;
          });

        }
      }
    )
  }

  getSubsidiaries(franchiseParam) {
    // ENTEL DATA API FOR SUBSIDIARY
    let params = {
      franchise: franchiseParam,
      'stat_programming': this.metricID
    }

    this.apiService.getEntelSubsidiaryData(params).subscribe(
      data => {
        this.entelDataSub = data.results.pdv

        this.dataSource7 = this.entelDataSub.map(obj => {
          const nameParts = obj.name.split(" - ");
          obj.name = nameParts.length > 1 ? nameParts[1] : obj.name;
          return obj;
        });
        // console.log("data source 7", this.dataSource7);


        // this.spinner = false;
      },
      error => {
        // console.log('error', error);
        this.message_tmp = '¡Ocurrió un problema, por favor intente nuevamente!';
        this.status_tmp = 'alert-danger';
        this.alert.changeFromAlert();
      }
    );
  }

  pdvOptionSelected(event: MatAutocompleteSelectedEvent) {
    let event_pdv = (event.option.value);
    // console.log("optionn selected working", event_pdv)
  }

  // FILTER METHOD BY FRANCHISE
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  // FILTER METHOD BY PDV
  private _filterPdv(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsPdv.filter(options => options.toLowerCase().includes(filterValue));
  }

  optionSelected(event: any, param = null) {

    // let event_pdv = event != null ? event.option.value : param;

    console.log("optionn selected working", event)
    console.log("BAck", this.backupOptions);
    this.franchiseParam = this.getFranchiseId(event);
    this.getEvaluationMark(this.franchiseParam);
    // this.getSubsidiaries(this.franchiseParam);

  }

  franchiseSelected() {
    this.permissions();
  }

  getFranchiseId(franchiseName: any) {
    let franchiseId;
    for (let i = 0; i < this.backupOptions.length; i++) {
      if (franchiseName == this.backupOptions[i].name) {
        franchiseId = this.backupOptions[i].internal_id;
        return franchiseId;
      }
    }
    return franchiseId;
  }

  execDataSource() {
    setTimeout(() => {
      this.dataSource3 = new MatTableDataSource(this.entelData);
      this.dataSource3.paginator = this.paginator;
      this.dataSource3.sort = this.sort;
    }, 100);
  }

  //SEARCH BAR
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

  clear(name: any) {
    this.dataSource6 = this.backupSource6;
    if (name == 'franchise') {
      this.myControlPdv.setValue('');
      this.optionsPdv = [];
      this.filteredOptionsPdv = of([]);
      this.pdvValidate = false;
    }
  }

  name = 'ExcelSheet.xlsx';

  exportToExcel(): void {
    console.log("datasource 6 ->", this.dataSource6);
    console.log("datasource 7 ->", this.dataSource7);
    const book: XLSX.WorkBook = XLSX.utils.book_new();

    const columnMapFranchise = {
      'name': 'Franquicia',
      'device_final_mark': 'Equipo',
      'home_final_mark': 'Hogar',
      'accessory_final_mark': 'Accesorios',
      'image_final_mark': 'Imagen',
      'final_mark': 'Nota',
    };

    const columnMapPDV = {
      'internal_id': 'PDV',
      'name': 'Nombre PDV',
      'franchise_name': 'Franquicia',
      'device_final_mark': 'Equipos',
      'home_final_mark': 'Hogar',
      'accessory_final_mark': 'Accesorios',
      'image_final_mark': 'Imagen',
      'final_mark': 'Nota',
    };

    let franchiseData: any = this.dataSource6;
    let pdvData: any = this.dataSource7;

    
    // const filteredFranchiseData = franchiseData.map((row) => {
    //   const filteredRow = {};
    //   console.log("complete row", row);
    //   for (const originalKey in row) {
    //     console.log("data -> ", originalKey);
    //     if (columnMapFranchise.hasOwnProperty(originalKey)) {
    //       filteredRow[columnMapFranchise[originalKey]] = row[originalKey];
    //     }
    //   }
    //   return filteredRow;
    // });

    const filteredFranchiseData = franchiseData.map((row) => {
      const filteredRow = {};
      Object.keys(columnMapFranchise).forEach((originalKey) => {
        if (row.hasOwnProperty(originalKey)) {
          if (originalKey === 'device_final_mark' || originalKey === 'home_final_mark' || originalKey === 'accessory_final_mark' || originalKey === 'image_final_mark' || originalKey === 'final_mark') {
            filteredRow[columnMapFranchise[originalKey]] = `${row[originalKey]}%`; // Agregar el símbolo de porcentaje
          } else {
            filteredRow[columnMapFranchise[originalKey]] = row[originalKey];
          }
        }
      });
      return filteredRow;
    });
    

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredFranchiseData);
    XLSX.utils.book_append_sheet(book, worksheet, 'Franquicia');


    // if (pdvData) {
    //   const filteredPdvData = pdvData.map((row) => {
    //     const filteredRow = {};
    //     Object.keys(columnMapPDV).forEach((originalKey) => {
    //       if (row.hasOwnProperty(originalKey)) {
    //         filteredRow[columnMapPDV[originalKey]] = row[originalKey];
    //       }
    //     });
    //     return filteredRow;
    //   });
    //   const worksheetTwo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredPdvData);
    //   XLSX.utils.book_append_sheet(book, worksheetTwo, 'PDV');
    // }
    if (pdvData) {
      
      const filteredPdvData = pdvData.map((row) => {
        const filteredRow = {};
        Object.keys(columnMapPDV).forEach((originalKey) => {
          if (columnMapPDV.hasOwnProperty(originalKey)) {
            if (originalKey === 'device_final_mark' || originalKey === 'home_final_mark' || originalKey === 'accessory_final_mark' || originalKey === 'image_final_mark' || originalKey === 'final_mark') {
              filteredRow[columnMapPDV[originalKey]] = `${row[originalKey]}%`; 
            } else {
              filteredRow[columnMapPDV[originalKey]] = row[originalKey];
            }
          }
        });
        return filteredRow;
      });
      const worksheetTwo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredPdvData);
      XLSX.utils.book_append_sheet(book, worksheetTwo, 'PDV');
    }
    

    // Save the workbook as an Excel file
    XLSX.writeFile(book, this.name);

  }


  showAdvice(idAdvice) {
    document.getElementById('advice-one').style.display = 'none';
    document.getElementById('advice-two').style.display = 'none';
    document.getElementById(idAdvice).style.display = 'block';
}


  // name = 'ExcelSheet.xlsx';
  // exportToExcel(): void {
  //   let element = document.getElementById('franchise');
  //   let element2 = document.getElementById('pdv');
  //   let htmlElement: any = _.cloneDeep(element);
  //   let htmlElementPdv: any = _.cloneDeep(element2);
  //   let worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(htmlElement || htmlElementPdv);

  //   const book: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

  //   XLSX.writeFile(book, this.name);
  // }


}
