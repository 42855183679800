import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ImageDialogComponent } from '../component/image-dialog/image-dialog.component';
import { AuthenticationService } from '../services/authentication.service';
// import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { ApiService } from '../services/services.service';
import { Subsidiary, Tab } from '../utils/metric-data.interface';
import { Subjects } from '../utils/subjects';
import * as moment from 'moment';

export interface data {
  [key: string]: any;
}

// LIST OF INCIDENCES 
export interface PeriodicElement {
  position: number;
  name: string;
  incidence: string;
  area: string;
  state: any;
}

export interface TableData {
  section: string;
  emptyPosition;
  outOfPortfolio;
  exhibitionOrder;
}

export interface EvaluationScale {
  devices: number;
  home: number;
  accesories: number;
  image: number;
  mark: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 25, name: 'Chacabuco', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: "Pendiente" },
  { position: 28, name: 'Puerto Montt', incidence: "Posiciones Vacías", area: "Mesa 2", state: 'Pendiente' },
  { position: 37, name: 'Costanera Center', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: 'Corregido' },
  { position: 43, name: 'Torre Entel', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: 'Verificado' },
  { position: 25, name: 'Chacabuco', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: 'Pendiente' },
  { position: 18, name: 'Maipú', incidence: "No cumple orden completo de menor a mayor.", area: "Mesa 1", state: 'Corregido' },
  { position: 67, name: 'Chillén Exprés', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: 'Pendiente' },
  { position: 122, name: 'Plaza Egaña', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: 'Pendiente' },
  { position: 25, name: 'Chacabuco', incidence: "Exhibe promo de Apple", area: "Mesa 1", state: 'Verificado' },
];

const export_data: EvaluationScale[] = [
  { devices: 0, home: 0, accesories: 0, image: 0, mark: 0 },
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  plural_context = 'device'

  currentImages = [];

  //FIRST CHART 
  greenColor = '#00e7ab';
  yellowColor = '#edf2b2';
  redColor = '#fac2c5';
  dataTable = [];
  displayTable: string[] = ['devices', 'home', 'accesories', 'image', 'mark'];
  showTooltipHistoryChart: boolean = false;
  showTooltipFranquiseChart: boolean = false;
  shoTooltipTableMarks: boolean = false;


  markHistory = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: false,
    axisY: {
      includeZero: true,
      valueFormatString: "#,##0",
      maximum: 100,
    },
    data: [{
      type: "column", //change type to bar, line, area, pie, etc
      yValueFormatString: "#,##0",
      indexLabelPlacement: "inside",
      indexLabelFontColor: "#fff",
      indexLabelFontWeight: "bold",
      indexLabelFormatter: function (e) { return e.dataPoint.y; },
      dataPoints: [
        { label: "Ene", y: 0, color: "#005cff" },
        { label: "Feb", y: 32, color: "#005cff" },
        { label: "Mar", y: 90, color: "#005cff" },
        { label: "Abr", y: 24, color: "#005cff" },
        { label: "May", y: 32, color: "#005cff" },
        { label: "Jun", y: 24, color: "#005cff" },
        { label: "Jul", y: 10, color: "#005cff" },
        { label: "Ago", y: 22, color: "#005cff" },
        { label: "Sep", y: 87, color: "#005cff" },
        { label: "Oct", y: 32, color: "#005cff" },
        { label: "Nov", y: 23.5, color: "#005cff" },
        { label: "Dic", y: 23.4, color: "#005cff" }
      ],
      // indexLabelPlacement: "inside", // Position the numbers inside the columns
      // indexLabelFontColor: "#fff", // Font color of the numbers
      // indexLabelFormatter: function (e) {
      //   return e.dataPoint.y;
      // }
    }]
  }



  incidenceHistory = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: false,
    axisY: {
      includeZero: true,
      valueFormatString: "#,##0"
    },
    data: [{
      type: "column", //change type to bar, line, area, pie, etc
      yValueFormatString: "#,##0",
      color: "#005cff",
      dataPoints: [
        { label: "Ene", y: 45 },
        { label: "Feb", y: 31.5 },
        { label: "Mar", y: 90 },
        { label: "Abr", y: 23.5 },
        { label: "May", y: 31.4 },
        { label: "Jun", y: 23.4 },
        { label: "Jul", y: 10 },
        { label: "Ago", y: 22 },
        { label: "Sep", y: 40 },
        { label: "Oct", y: 31.5 },
        { label: "Nov", y: 23.5 },
        { label: "Dic", y: 23.4 }
      ],
    }]
  }

  // { label: "Ene", y: 0 },
  // { label: "Feb", y: 0 },
  // { label: "Mar", y: 0 },
  // { label: "Abr", y: 0 },
  // { label: "May", y: 0 },
  // { label: "Jun", y: 0 },
  // { label: "Jul", y: 0 },
  // { label: "Ago", y: 0 },
  // { label: "Sep", y: 0 },
  // { label: "Oct", y: 0 },
  // { label: "Nov", y: 0 },
  // { label: "Dic", y: 0 }
  // { label: "Ene", y: 272, color: "#baefb8" },
  // { label: "Feb", y: 389, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Mar", y: 301, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Abr", y: 340, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "May", y: 266, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Jun", y: 396, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Jul", y: 358, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Ago", y: 267, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Sep", y: 375, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Oct", y: 352, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Nov", y: 156, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },
  // { label: "Dic", y: 264, color: (y) => y < 100 ? "#005cff" : (y > 300 ? "#ff0000" : "#000000") },






  splineChart = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    axisY: {
      includeZero: true,
      valueFormatString: "#,##0"
    },
    data: [{
      type: "spline", //change type to bar, line, area, pie, etc
      yValueFormatString: "#,##0",
      // color: "#01b8aa",
      color: "#005cff",
      dataPoints: [
        // { label: "Lun", y: 0 },
        // { label: "Mar", y: 0 },
        // { label: "Mie", y: 0 },
        // { label: "Jue", y: 0 },
        // { label: "Vie", y: 0 },
        // { label: "Sáb", y: 0 },
        // { label: "Dom", y: 0 },
        { label: "Lun", y: 142 },
        { label: "Mar", y: 359 },
        { label: "Mie", y: 196 },
        { label: "Jue", y: 158 },
        { label: "Vie", y: 127 },
        { label: "Sáb", y: 82 },
        { label: "Dom", y: 46 },
      ]
    }]
  }

  visitorsChartDrilldownHandler = (e: any) => {
    this.chart.options = this.visitorsDrilldownedChartOptions;
    this.chart.options.data = this.options[e.dataPoint.name];
    this.chart.options.title = { text: e.dataPoint.name }
    this.chart.render();
    this.isButtonVisible = true;
  }

  visitorsDrilldownedChartOptions = {
    animationEnabled: true,
    theme: "light2",
    axisY: {
      gridThickness: 0,
      lineThickness: 1
    },
    data: []
  };

  newVSReturningVisitorsOptions = {
    animationEnabled: true,
    theme: "light2",
    subtitles: [{
      backgroundColor: "#2eacd1",
      fontSize: 16,
      fontColor: "white",
      padding: 5
    }],
    data: []
  };

  options: data = {
    "New vs Returning Visitors": [{
      type: "pie",
      name: "New vs Returning Visitors",
      startAngle: 90,
      cursor: "pointer",
      explodeOnClick: false,
      showInLegend: true,
      legendMarkerType: "square",
      click: this.visitorsChartDrilldownHandler,
      indexLabelPlacement: "inside",
      indexLabelFontColor: "white",
      dataPoints: [
        { y: 551160, name: "Tiendas KA", color: "#005cff", indexLabel: "62.56%" },
        { y: 329840, name: "Tiendas BC", color: "rgb(33, 221, 27)", indexLabel: "37.44%" }
      ]
    }],
    "New Visitors": [{
      color: "#058dc7",
      name: "New Visitors",
      type: "column",
      dataPoints: [
        { label: "Jan", y: 42600 },
        { label: "Feb", y: 44960 },
        { label: "Mar", y: 46160 },
        { label: "Apr", y: 48240 },
        { label: "May", y: 48200 },
        { label: "Jun", y: 49600 },
        { label: "Jul", y: 51560 },
        { label: "Aug", y: 49280 },
        { label: "Sep", y: 46800 },
        { label: "Oct", y: 57720 },
        { label: "Nov", y: 59840 },
        { label: "Dec", y: 54400 }
      ]
    }],
    "Returning Visitors": [{
      color: "#005cff",
      name: "Returning Visitors",
      type: "column",
      dataPoints: [
        { label: "Jan", y: 21800 },
        { label: "Feb", y: 25040 },
        { label: "Mar", y: 23840 },
        { label: "Apr", y: 24760 },
        { label: "May", y: 25800 },
        { label: "Jun", y: 26400 },
        { label: "Jul", y: 27440 },
        { label: "Aug", y: 29720 },
        { label: "Sep", y: 29200 },
        { label: "Oct", y: 31280 },
        { label: "Nov", y: 33160 },
        { label: "Dec", y: 31400 }
      ]
    }]
  };


  chartOptionsJs = {
    animationEnabled: true,
    theme: "light2",
    axisY: {
      title: "",
      includeZero: true,
      labelAngle: -90,
    },
    axisY2: {
      // title: "Total Revenue",
      maximum: 100, // Set the maximum value for the y-axis
      includeZero: true,
      labelFormatter: (e: any) => {
        var suffixes = ["", "K", "M", "B"];

        var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
        if (order > suffixes.length - 1)
          order = suffixes.length - 1;

        var suffix = suffixes[order];
        return '' + (e.value / Math.pow(1000, order));
      }
    },
    legend: {
      cursor: "pointer",
      itemclick: function (e: any) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chartJs.render();
      }
    },
    data: [{
      type: "column",
      showInLegend: false,
      color: "#10069f",
      name: null,
      axisYType: "secondary",
      yValueFormatString: "#,###",
      indexLabelPlacement: "inside",
      indexLabelFontColor: "#fff",
      indexLabelFontWeight: "bold",
      indexLabelFormatter: function (e) { return e.dataPoint.y; },
      dataPoints: [
        { label: "Jan", y: 25000, color: "#10069f" },
        { label: "Feb", y: 43100, color: "#10069f" },
        { label: "Mar", y: 64600, color: "#10069f" },
        { label: "Apr", y: 52200, color: "#10069f" },
        { label: "May", y: 46400, color: "#10069f" },
        { label: "Jun", y: 47000, color: "#10069f" },
        { label: "Jul", y: 53400, color: "#10069f" },
        { label: "Aug", y: 40700, color: "#10069f" },
        { label: "Sep", y: 48400, color: "#10069f" },
        { label: "Oct", y: 46500, color: "#10069f" },
        { label: "Nov", y: 42400, color: "#10069f" },
        { label: "Dec", y: 23100, color: "#10069f" }
      ]
    }, {
      type: "spline",
      showInLegend: true,
      name: "Variación",
      color: "#10069f",
      dataPoints: [
        // { label: "Ene", y: 0 },
        // { label: "Feb", y: 0 },
        // { label: "Mar", y: 0 },
        // { label: "Abr", y: 0 },
        // { label: "May", y: 0 },
        // { label: "Jun", y: 0 },
        // { label: "Jul", y: 0 },
        // { label: "Ago", y: 0 },
        // { label: "Sep", y: 0 },
        // { label: "Oct", y: 0 },
        // { label: "Nov", y: 0 },
        // { label: "Dic", y: 0 }
        { label: "Ene", y: 372 },
        { label: "Feb", y: 412 },
        { label: "Mar", y: 572 },
        { label: "Abr", y: 224 },
        { label: "May", y: 246 },
        { label: "Jun", y: 601 },
        { label: "Jul", y: 642 },
        { label: "Ago", y: 590 },
        { label: "Sep", y: 527 },
        { label: "Oct", y: 273 },
        { label: "Nov", y: 251 },
        { label: "Dic", y: 331 }
      ]
    }]
  }

  // VARIABLES
  panelOpenState = true;
  chart: any;
  isButtonVisible = false;
  chartJs: any;
  data = null;


  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  // AUTOCOMPLETE SEARCHING AND DROPDOWN BAR 

  myControl = new FormControl('');
  option: string[] = [];
  subsidiaries: Subsidiary[] = [];
  filteredOptions: Observable<Subsidiary[]>;
  countImages = 0;
  devicePlaces: any;
  subsidiary_id: any;

  myControlFranchise = new FormControl('');
  twoControl = new FormControl('');
  franchiseOptions: string[] = ['Nombre Franquicia 1', 'Nombre Franquicia 2', 'Nombre Franquicia 3', 'Nombre Franquicia 4'];
  subsidiaryOptions: string[] = ['Nombre Tienda 1', 'Nombre Tienda 2', 'Nombre Tienda 3', 'Nombre Tienda 4'];
  filteredOptionsTwo: Observable<string[]>;


  // NOTIFICATIONS FOR THE BELL ICON SECTION
  notifications = [
    { title: 'Nuevo cambio en iphone X', timestamp: '2 hours ago' },
    { title: 'Actualizar Equipos', timestamp: '1 day ago' },
    { title: 'Actualizar Dispositivos', timestamp: '2 days ago' },
    { title: 'Actualizar Dispositivos', timestamp: '2 days ago' }
  ];



  @Input() spinner: boolean = true;
  contDashboard = 0;
  showCard = false;
  showCharts = false;
  totCountElement = 0;
  flagData = true;
  actualStatSelector: any;
  metricForm = new FormControl(null);
  showMetricForm = false;
  metricID = null;
  metricSelected = false;
  lastUpdate;
  lastUpdateFlag = false;
  generalMark = 0;
  emptyPositions = 0;
  outOfPortfolio = 0;
  totalOrderExhibition = 0;
  exhibitionObject;
  dataSourceIncidence: TableData[] = [];
  exhibitionOrder = 0;
  showTableTooltip: boolean = false;
  totalExhibitionIncidences = 0;


  // CONSTRUCTOR
  constructor(
    private metricData: Subjects,
    private metricApi: ApiService,
    private dialog: MatDialog,
    private services: ApiService,
    private _snackBar: MatSnackBar,
    private auth: AuthenticationService,
  ) { }


  ngOnInit(): void {

    this.getDevicesPlaces();
    // this.calculateChartColors();
    this.calculateFranchiseChartColors();

    this.metricApi.getActualStat().subscribe(
      data => {
        console.log("month stats = ", data);
        this.actualStatSelector = data;
        this.showMetricForm = true;
        this.metricForm.setValue(this.actualStatSelector[0].name)
      }
    )

    this.auth.saveUser('/dashboard');


    this.metricForm.valueChanges.subscribe(
      data => {
        // const $ = this
        let idMetricStat = _.find(this.actualStatSelector, function (obj) {
          const value = data;
          if (obj.name === value) {
            return obj
          }
        });
        if (idMetricStat) {
          this.metricID = idMetricStat.id;
        }
        console.group("metric id", this.metricID)
        this.myControl.setValue('')
        this.showCard = false;
        this.getSubsidiary();
      }
    )



    // this.metricData.userPermission$.subscribe(
    //   data => {
    //     if (this.flagData) {
    //       if (data) {
    //         let validation: string = data.dashboard_view;
    //         if (validation.includes("FRANQ")) {

    //           // let franchise = _.find(this.backupOptions, {
    //           //   id: data.franchise
    //           // })
    //           // this.filterEnabled = false;
    //           // this.optionSelected(null, franchise.name);
    //         }
    //       }
    //       this.flagData = false;
    //     }
    //   }
    // )

    this.metricData.metricDataSubject$.subscribe(
      data => {
        if (data.subsidiary) {
          this.data = data;
          if (this.data.custom_date == null) {
            this.lastUpdate = "Sin modificaciones";
          } else {
            // this.lastUpdate = moment(this.data.last_update).format('DD-MM-YYYY hh:mm');
            this.lastUpdate = this.data.custom_date;
          }
          console.log("DATA FROM ", data);
          this.exhibitionObject = data;
          this.setExhibitionIncidences(data);
          this.setIncidenceTable(data);
          this.showImages();
          this.spinner = false;
        } else {
          this.spinner = true;
        }
      }
    )

    this.metricData.contDashboardTable$.subscribe(
      data => {
        console.log("DASHBOARD TABLE", data);
        if (data) {
          console.log("DASHBOARD cont", this.contDashboard);
          this.contDashboard += data;
          console.log("dashboard += data after", this.contDashboard);
        }
      }
    )
  }

  setExhibitionIncidences(data) {
    console.log("seting", data);
    let accessory_outOfPortolio = 0;
    let accessory_emptyPosition = 0;
    let device_outOfPortolio = 0;
    let device_emptyPosition = 0;
    let order_exh_acc = 0;
    let order_exh_device = 0;
    this.totalOrderExhibition = 0;


    if (data) {
      let accesory_obj = data.accessory;
      let device_obj = data.device;
      console.log("seting accesories", accesory_obj);

      if (!this.objIsEmpty(accesory_obj)) {
        accessory_outOfPortolio = accesory_obj.out_of_portafolio;
        accessory_emptyPosition = accesory_obj.empty_position;
        console.log("order ex acc = ", accesory_obj.exhibition_order);
        if (accesory_obj.exhibition_order && ((accesory_obj.exhibition_order != ""))) {
          order_exh_acc = accesory_obj.exhibition_order;
        } else {
          order_exh_acc = 0;
        }
        console.log("order ex acc  outing= ", order_exh_acc);
      }

      if (!this.objIsEmpty(device_obj)) {
        device_outOfPortolio = device_obj.out_of_portafolio;
        device_emptyPosition = device_obj.empty_position;
        console.log("order ex device = ", device_obj.exhibition_order);
        if (device_obj.exhibition_order && ((device_obj.exhibition_order != ""))) {
          order_exh_device = device_obj.exhibition_order;
        } else {
          order_exh_device = 0;
        }
        console.log("order ex device  outing= ", order_exh_device)

      }

      this.outOfPortfolio = +accessory_outOfPortolio + +device_outOfPortolio;
      this.emptyPositions = +accessory_emptyPosition + +device_emptyPosition;

      console.log("order ex device = ", order_exh_device)
      if (order_exh_device != 0) {
        this.totalOrderExhibition += 1;
      }
      if (order_exh_acc != 0) {
        this.totalOrderExhibition += 1;
      }
      console.log("total order exh", this.totalOrderExhibition);
      console.log("emptyPositions", this.emptyPositions);
      console.log("cont before ", this.contDashboard);
      this.totalExhibitionIncidences = this.emptyPositions + this.outOfPortfolio + this.totalOrderExhibition;
      this.contDashboard += this.totalExhibitionIncidences;
      console.log("cont after ", this.contDashboard);

    }


    console.warn("exb setted", this.emptyPositions, ' ', this.outOfPortfolio);

  }

  objIsEmpty(objeto: object): boolean {
    return Object.keys(objeto).length === 0;
  }

  getSubsidiary() {
    this.spinner = true;
    let params = {
      'stat_programming': this.metricID
    }
    this.metricApi.getEntelSubsidiaryData(params).subscribe(
      data => {
        if (data) {
          console.log("calling servce", data.results.pdv);
          let subsidiaryList = data.results.pdv;
          console.log("this user permissions in subjedct -> ", this.metricData.userPermission$.getValue());
          let permissions = this.metricData.userPermission$.getValue();
          let validation: string = permissions.dashboard_view;

          if (validation.includes("FRANQ")) {
            subsidiaryList = _.filter(data.results.pdv, {
              franchise: permissions.franchise
            })
          }

          if (permissions.profile == "JEFE DE TIENDA") {
            console.warn("JEFE TIENDA ");
            let franchisesArray: string[] = permissions.dashboard_view.split(',');
            console.warn("FRANCHISE ARRAY ", franchisesArray);
            subsidiaryList = _.filter(data.results.pdv, subsidiary => {
              return franchisesArray.includes(subsidiary.internal_id);
            });

            console.warn("JSUB LIST ", subsidiaryList);

          }
          this.subsidiaries = subsidiaryList;
          this.initFiltering();
          this.spinner = false;
        }
      },
      error => {
        //console.log("Metric Data Failed...", error);
      }
    )
  }

  calculateChartColors() {
    for (let i = 0; i < this.markHistory.data.length; i++) {
      this.markHistory.data[i].dataPoints.forEach((dataPoint) => {
        if (dataPoint.y > 31.5) {
          dataPoint.color = this.greenColor;
        } else if (dataPoint.y >= 23.5 && dataPoint.y <= 31.4) {
          dataPoint.color = this.yellowColor;
        } else if (dataPoint.y < 23.5) {
          dataPoint.color = this.redColor;
        }
      });
    }

    // for (int i=0; i<)
    // if (this.markHistory.data[0].dataPoints[0].y > 31.5) {
    //   this.markHistory.data[0].dataPoints[0].color = this.greenColor;
    // } else if (this.markHistory.data[0].dataPoints[0].y >= 23.5 && this.markHistory.data[0].dataPoints[0].y <= 31.4) {
    //   this.markHistory.data[0].dataPoints[0].color = this.yellowColor;
    // } else if (this.markHistory.data[0].dataPoints[0].y < 23.5) {
    //   this.markHistory.data[0].dataPoints[0].color = this.redColor;
    // }
  }

  calculateFranchiseChartColors() {
    for (let i = 0; i < this.chartOptionsJs.data.length; i++) {
      this.chartOptionsJs.data[i].dataPoints.forEach((dataPoint) => {
        if (dataPoint.y > 99.45) {
          // dataPoint.color = this.greenColor;
          dataPoint.color = '#10069f';
        } else if (dataPoint.y >= 69.45 && dataPoint.y <= 99.45) {
          // dataPoint.color = this.yellowColor;
          dataPoint.color = '#10069f';
        } else if (dataPoint.y < 69.45) {
          // dataPoint.color = this.redColor;
          dataPoint.color = '#10069f';
        }
      });
    }
  }

  initFiltering() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subsidiaries.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  //SEARCH BAR
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    //this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

  handleClick(event: Event) {
    this.chart.options = this.newVSReturningVisitorsOptions;
    this.chart.options.data = this.options["New vs Returning Visitors"];
    this.chart.render();
    this.isButtonVisible = false;
  }

  getChartInstance(chart: object) {
    this.chart = chart;
    this.chart.options = this.newVSReturningVisitorsOptions;
    this.chart.options.data = this.options["New vs Returning Visitors"];
    this.chart.render();
  }

  // SERVICES
  getDevicesPlaces() {
    this.metricApi.getPlaces().subscribe(
      data => {
        this.metricData.allPlaces$.next(data);
        console.log("places --> ", this.metricData.allPlaces$.getValue());

      }, error => {
      }
    )
  }

  // FILTER METHOD BY 
  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.franchiseOptions.filter(option => option.toLowerCase().includes(filterValue));
  // }

  // FILTER METHOD BY 
  private _filterTwo(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.subsidiaryOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  showImages() {
    this.currentImages = [];
    let array = ['devices', 'accessories', 'homes', 'images']
    for (let i = 0; i < 4; i++) {
      let elements = this.getElements(array[i]);
      this.showAllImages(elements);
    }
  }

  showAllImages(elements) {
    this.countImages = 0;
    for (let item of elements) {
      if (item.images) {
        for (let img of item.images) {
          let img_: any = img
          img_.id = this.countImages++;
          img_.place = item.place_id
          this.currentImages.push(...[img]);
        }
      }
    }
  }

  getElements(type) {
    let elements: Tab[] = _.cloneDeep(this.data[type]);
    return elements;
  }

  // DIALOGS
  openImageDialog(imageUrl: string, comment: string, place: any) {
    this.dialog.open(ImageDialogComponent, {
      data: { imageUrl, comment, place },
      width: 'auto',
    });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.contDashboard = 0;
    this.totCountElement = 0;
    this.emptyPositions = 0;
    this.outOfPortfolio = 0;
    this.totalOrderExhibition = 0;
    this.subsidiary_id = Number(event.option.id);
    this.fillSubsidiary(this.subsidiary_id);
    // console.log("subsidiary id choosed ", this.subsidiary_id);
    this.getChartSubsidiary(this.subsidiary_id)
    this.getTotalElements(this.subsidiary_id);
    this.setMetricTable(this.subsidiary_id);
  }

  setIncidenceTable(data) {
    if (data) {
      let device;
      let accessory;
      let qty_emptyPositions_device = 0;
      let qty_outOfPortfolio_device = 0;
      let exh_order_device = 0;
      let qty_emptyPositions_acc = 0;
      let qty_outOfPortfolio_acc = 0;
      let exh_order_acc = 0;
      this.dataSourceIncidence = [];
      let exhibition_mark_device = 0;
      let exhibition_mark_acc = 0;


      if (data.device) {
        device = data.device;
        qty_emptyPositions_device = device.empty_position >= 0 ? device.empty_position : 0;
        qty_outOfPortfolio_device = device.out_of_portafolio >= 0 ? device.out_of_portafolio : 0;
        // if (device.exhibition_order == "") {
        //   exh_order_device = 0;
        // } else {
        //   exh_order_device = device.exhibition_order >= 0 ? device.exhibition_order : 0;
        // }
        // this.dataSourceIncidence.push({ section: 'EQUIPOS', emptyPosition: qty_emptyPositions_device, outOfPortfolio: qty_outOfPortfolio_device, exhibitionOrder: exh_order_device });

        if (device.exhibition_mark == "") {
          exhibition_mark_device = 0;
        } else {
          exhibition_mark_device = device.exhibition_mark >= 0 ? device.exhibition_mark : 0;
          exhibition_mark_device = Number(Math.floor(exhibition_mark_device));
        }

        this.dataSourceIncidence.push({ section: 'EQUIPOS', emptyPosition: qty_emptyPositions_device, outOfPortfolio: qty_outOfPortfolio_device, exhibitionOrder: exhibition_mark_device });

        // if (qty_emptyPositions_device > 0 || qty_outOfPortfolio_device > 0 || exh_order_device > 0) {
        //   console.log("data = empty device ", qty_emptyPositions_device);
        //   console.log("data = qty_outOfPortfolio_device device ", qty_outOfPortfolio_device)
        //   console.log("data = exh_order_device device ", exh_order_device)

        //   this.dataSourceIncidence.push({ section: 'EQUIPOS', emptyPosition: qty_emptyPositions_device, outOfPortfolio: qty_outOfPortfolio_device, exhibitionOrder: exh_order_device });
        // }
      }
      if (data.accessory) {
        accessory = data.accessory;
        qty_emptyPositions_acc = accessory.empty_position >= 0 ? accessory.empty_position : 0;
        qty_outOfPortfolio_acc = accessory.out_of_portafolio >= 0 ? accessory.out_of_portafolio : 0;
        // if (accessory.exhibition_order == "") {
        //   exh_order_acc = 0;
        // } else {
        //   exh_order_acc = accessory.exhibition_order >= 0 ? accessory.exhibition_order : 0;
        // }
        if (accessory.exhibition_mark == "") {
          exhibition_mark_acc = 0;
        } else {
          exhibition_mark_acc = accessory.exhibition_mark >= 0 ? accessory.exhibition_mark : 0;
          exhibition_mark_acc = Number(Math.floor(exhibition_mark_acc));
        }
        this.dataSourceIncidence.push({ section: 'ACCESORIOS', emptyPosition: qty_emptyPositions_acc, outOfPortfolio: qty_outOfPortfolio_acc, exhibitionOrder: exhibition_mark_acc });

        // if (qty_emptyPositions_acc > 0 || qty_outOfPortfolio_acc > 0 || exh_order_acc > 0) {
        //   this.dataSourceIncidence.push({ section: 'ACCESORIOS', emptyPosition: qty_emptyPositions_acc, outOfPortfolio: qty_outOfPortfolio_acc, exhibitionOrder: exh_order_acc });
        // }
      }

    }
  }

  setMetricTable(subsidiary_id) {
    let subsidiary = _.find(this.subsidiaries, s => {
      return s.id == subsidiary_id;
    })

    this.generalMark = subsidiary['final_mark'];

    this.dataTable = [{
      devices: subsidiary['device_final_mark'],
      home: subsidiary['home_final_mark'],
      accesories: subsidiary['accessory_final_mark'],
      image: subsidiary['image_final_mark'],
      mark: subsidiary['final_mark']

    }]

    console.log("subsidiary from metric table", subsidiary);
  }

  getTotalElements(sub_id: any) {
    let params = {
      subsidiary: sub_id,
      'stat_programming': this.metricID
    }
    this.metricApi.getTotalElementSub(params).subscribe(
      data => {
        if (data) {
          console.log("count elements data ", data);
          this.totCountElement = data.count;
        }
      }
    )
  }


  fillSubsidiary(subsidiary_id: number) {
    let params = { id: subsidiary_id, 'stat_programming': this.metricID }
    this.metricApi.getStatSubsidiary(params).subscribe(
      data => {
        // console.log("getStatSubsidiary : ", data);
        // first time passing the subsidiary data to the metric data subject
        this.metricData.metricDataSubject$.next(data);
        // this.showImages();
        this.lastUpdateFlag = true;
      },
      error => {
        //console.log("error : ", error);
      }
    )
  }

  getChartSubsidiary(subsidiary_id: number) {
    let params = {
      subsidiary: subsidiary_id,
      'stat_programming': this.metricID
    }
    this.metricApi.getChartSubsidiary(params).subscribe(
      data => {
        if (data) {
          console.log("get chart sub : ", data);
          this.fillCharts(data)
        }

      },
      error => {
        // console.log("error : ", error);
      }
    )
  }

  fillCharts(data: any) {
    // historic mark
    this.fillHistoricMarkChart(data.history_notes);
    this.fillIncidencesChart(data.history_incidents);
    this.fillFranchiseChart(data.history_franchise_notes);
    this.showCard = true;
  }

  fillHistoricMarkChart(data: any) {

    let dataPoint = [];
    for (let item of data) {
      let obj = {
        label: item.month_name,
        y: Math.floor(Number(item.final_mark)),
        color: "#005cff",
        borderRadius: 15,
      }
      dataPoint.push(obj);
    }

    this.markHistory.data = [{
      type: "column",
      yValueFormatString: "#,##0",
      dataPoints: dataPoint,
      indexLabelPlacement: "inside", // Position the numbers inside the columns
      indexLabelFontColor: "#0128ff", // Font color of the numbers
      indexLabelFontWeight: "bold", // Font weight of the numbers
      indexLabelFormatter: function (e) {
        return e.dataPoint.y;
      }
    }]
    this.calculateChartColors();
    this.showCharts = true;
  }

  fillIncidencesChart(data: any) {

    let dataPoint = [];
    for (let item of data) {
      let obj = {
        label: item.month_name,
        y: Number(item.count),
        color: "#005cff"
      }
      dataPoint.push(obj);
    }

    this.incidenceHistory.data = [{
      type: "column", //change type to bar, line, area, pie, etc
      yValueFormatString: "#,##0",
      color: "#005cff",
      dataPoints: dataPoint
    }]
    this.showCharts = true;
  }

  fillFranchiseChart(data: any) {
    console.log("fill franchise chart", data);
    let chart = data;
    let dataPoints = []
    // SHOW ALL ITEMS
    for (let item of chart) {
      let obj = {
        label: item.internal_id,
        y: Number(item.final_mark),
        color: "#005cff",
      }
      dataPoints.push(obj);
    }

    // THIS SET A MAXIMUM ITEMS TO SHOW
    // let maxItemsToShow = 6;
    // for (let i = 0; i < chart.length && i < maxItemsToShow; i++) {
    //   let item = chart[i];
    //   let obj = {
    //     label: item.internal_id,
    //     y: Number(item.final_mark),
    //     color: "#005cff",
    //   };
    //   dataPoints.push(obj);
    // }

    this.chartOptionsJs.data = [{
      type: "column",
      showInLegend: false,
      name: null,
      color: "#10069f",
      axisYType: "secondary",
      yValueFormatString: "#,###",
      indexLabelPlacement: "inside",
      indexLabelFontColor: "#fff",
      indexLabelFontWeight: "bold",
      indexLabelFormatter: function (e) { return e.dataPoint.y; },
      dataPoints: dataPoints,
    }]
    this.calculateFranchiseChartColors();
    this.showCharts = true;
  }


  clear() {
    this.myControl.setValue('');
    this.showCard = false;
    this.lastUpdateFlag = false;
    this.contDashboard = 0;
    this.totCountElement = 0;
    this.emptyPositions = 0;
    this.outOfPortfolio = 0;
    this.totalOrderExhibition = 0;

  }
}




