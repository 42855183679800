import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';


export interface TableData {
  section: string;
  emptyPosition;
  outOfPortfolio;
  exhibitionOrder;
}

@Component({
  selector: 'app-table-incidence',
  templateUrl: './table-incidence.component.html',
  styleUrls: ['./table-incidence.component.scss']
})


export class TableIncidenceComponent implements OnInit, OnChanges {
  @Input() subsidiary = null;
  @Input() spinner: boolean = false;
  @Input() metricID: any;
  @Input() exhibitionObject;

  dataSource7: TableData[] = [];
  
  exhibitionOrder = 0;
  showTableTooltip: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['subsidiary']) {
      if (this.exhibitionObject) {
        console.log("exhibition object ", this.exhibitionObject);
        let device;
        let accessory;
        let qty_emptyPositions_device = 0;
        let qty_outOfPortfolio_device = 0;
        let exh_order_device = 0;
        let qty_emptyPositions_acc = 0;
        let qty_outOfPortfolio_acc = 0;
        let exh_order_acc = 0;

        if (this.exhibitionObject.device) {
          device = this.exhibitionObject.device;
          qty_emptyPositions_device = device.empty_position >= 0 ? device.empty_position : 0;
          qty_outOfPortfolio_device = device.out_of_portafolio >= 0 ? device.out_of_portafolio : 0;
          if (device.exhibition_order == "") {
            exh_order_device = 0;
          } else {
            exh_order_device = device.exhibition_order >= 0 ? device.exhibition_order : 0;
          }
          if (qty_emptyPositions_device > 0 || qty_outOfPortfolio_device > 0 || exh_order_device > 0) {
            this.dataSource7.push({ section: 'EQUIPOS', emptyPosition: qty_emptyPositions_device, outOfPortfolio: qty_outOfPortfolio_device, exhibitionOrder: exh_order_device });
          }
        }
        if (this.exhibitionObject.accessory) {
          accessory = this.exhibitionObject.accessory;
          qty_emptyPositions_acc = accessory.empty_position >= 0 ? accessory.empty_position : 0;
          qty_outOfPortfolio_acc = accessory.out_of_portafolio >= 0 ? accessory.out_of_portafolio : 0;
          if (accessory.exhibition_order == "") {
            exh_order_acc = 0;
          } else {
            exh_order_acc = accessory.exhibition_order >= 0 ? accessory.exhibition_order : 0;
          }
          if (qty_emptyPositions_acc > 0 || qty_outOfPortfolio_acc > 0 || exh_order_acc > 0) {
            this.dataSource7.push({ section: 'ACCESORIOS', emptyPosition: qty_emptyPositions_acc, outOfPortfolio: qty_outOfPortfolio_acc, exhibitionOrder: exh_order_acc });
          }
        }
      }

    }

  }


  ngOnInit(): void {

  }

}
