<!-- <button mat-icon-button class="dropImageButton" (click)="openDialog()">
  <div class="showImageFound"
    [ngStyle]="{'background-image': 'url('+ ({'element': element.id, 'plural_context': plural_context, 'actualPlaceId': actualPlaceId.value} | safe:'status-image') +')'}">
  </div>
</button> -->


<div style="display: flex; align-items: center;">
  <!-- <img src="assets/resources/Recurso-imagen.jpeg" class="logo-login" (click)="openDialog()"> -->
  <button mat-icon-button class="dropImageButton" (click)="openDialog()">
    <div class="showImageFound"
      [ngStyle]="{'background-image': 'url('+ object?.image +')'}">
    </div>
  </button>
  <div *ngIf="object != null" style="margin-left: 10px; color: #007bff; cursor: pointer;">
    <mat-icon class="action-icon" 
      (click)="openImageDialog(object)">
      visibility</mat-icon>
  </div>
  <div *ngIf="object == null" style="margin-left: 10px; color: #bebebe;">
    <mat-icon class="action-icon">
      visibility</mat-icon>
  </div>

  <div *ngIf="object != null" style="margin-left: 10px; color: #007bff; cursor: pointer;">
    <mat-icon class="action-icon" 
      (click)="deteleImage(object)">
      delete</mat-icon>
  </div>
  <div *ngIf="object == null" style="margin-left: 10px; color: #bebebe;">
    <mat-icon class="action-icon">
      delete</mat-icon>
  </div>
</div>