<div *ngIf="permission">
    <mat-card>
        <div class="container db-card">
            <!-- Card Title -->
            <div class="row card-title">
                <div class="col m12 left-align">
                    <h6>EVALUACIÓN FOTO DE ÉXITO</h6>
                </div>
                <br>
            </div>
            <!-- Filter Stat Programming -->
            <div class="row" *ngIf="showMetricForm">
                <div class="col m1 field-text">
                    <h3>ESCOGE <b>MEDICIÓN</b></h3>
                </div>
                <div class="col m11">
                    <form>
                        <mat-form-field class="formField" appearance="fill">
                            <input type="text" placeholder="Escoge Medición" aria-label="Ingresa" matInput
                                [formControl]="metricForm" [matAutocomplete]="auto2">
                            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                                <mat-option *ngFor="let element of actualStatSelector" [value]="element.name">
                                    {{element.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon matSuffix (click)="metricForm.setValue('')" (click)="clear()">clear</mat-icon>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <!-- Filter PDV -->
            <div class="row" *ngIf="showFilterSubs">
                <div class="col m1 field-text">
                    <h3>ESCOGE <b>PDV/TIENDA</b></h3>
                </div>
                <div class="col m11">
                    <form>
                        <mat-form-field class="formField" appearance="fill">
                            <input class="chooseSubsidiaryInput" type="text" placeholder="Escoge Tienda"
                                aria-label="Ingresa" matInput [formControl]="myControl" [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                (optionSelected)="optionSelected($event)">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name"
                                    [id]="option.id">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon matSuffix (click)="myControl.setValue('')" (click)="clear()">clear</mat-icon>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <hr>
            <!-- Tabs -->
            <div class="row" [ngStyle]="{'display': showTabs ? 'block' : 'none'}">
                <mat-tab-group preserveContent animationDuration="200ms" (selectedTabChange)="onTabChange($event)">
                    <!-- <mat-tab-group> -->
                    <mat-tab label="EQUIPOS">
                        <!-- <app-devices *ngIf="deviceTab" context="device" plural_context="devices"></app-devices> -->
                        <app-devices typeOfSubsidiary="{{typeOfSubsidiary}}" context="device" plural_context="devices"
                            [metricID]="metricID" (saveClicked)="onDeviceSaveClicked()">
                        </app-devices>
                    </mat-tab>
                    <mat-tab label="ACCESORIOS">
                        <!-- <app-devices *ngIf="accesoriesTab" context="accessory" plural_context="accessories"></app-devices> -->
                        <app-devices typeOfSubsidiary="{{typeOfSubsidiary}}" context="accessory"
                            plural_context="accessories" [metricID]="metricID" (saveClicked)="onDeviceSaveClicked()"></app-devices>
                        <!-- <app-accessories></app-accessories> -->
                    </mat-tab>
                    <mat-tab label="HOGAR">
                        <app-devices typeOfSubsidiary="{{typeOfSubsidiary}}" context="home" plural_context="homes"
                            [metricID]="metricID" (saveClicked)="onDeviceSaveClicked()">
                        </app-devices>
                        <!-- <app-home></app-home> -->
                    </mat-tab>
                    <mat-tab label="IMAGEN">
                        <app-devices typeOfSubsidiary="{{typeOfSubsidiary}}" context="image" plural_context="images"
                            [metricID]="metricID" (saveClicked)="onDeviceSaveClicked()">
                        </app-devices>
                        <!-- <app-image></app-image> -->
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>chat_bubble</mat-icon>
                        </ng-template>
                        <div class="date-picker">
                            <mat-form-field>
                                <mat-label>Escoge fecha</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="dateTimeSelected" (dateInput)="onDateSelected($event)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="toggle-notification-container">
                            <p style="margin-right: 15px;">MARCAR SUCURSAL COMO EVALUADA</p>
                            <mat-slide-toggle class="toggle" [(ngModel)]="isEvaluatedToggle"
                                [disabled]="isEvaluatedToggleReSend">
                            </mat-slide-toggle>
                        </div>
                        <div class="btn-notification-container">
                            <button mat-raised-button class="btn notify-button"
                                *ngIf="isEvaluatedToggle && !isEvaluatedToggleReSend"
                                (click)="onToggleNotificationChange()">Notificar</button>
                            <button mat-raised-button class="btn notify-button" *ngIf="isEvaluatedToggleReSend"
                                (click)="onToggleNotificationChange()">Reenviar notificación</button>
                        </div>
                        <div class="ckeditor">
                            <ckeditor [(ngModel)]="valueEditor" [editor]="Editor" [config]="Config"></ckeditor>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>
            <!-- Buttons -->
            <!-- <div class="row" *ngIf="showTabs">
                    <div *ngIf="disabledBtn">
                        <button class="btn postInfoBtn" (click)="openConfirmDialog()"> Guardar</button>
                    </div>
                </div> -->
            <div class="row postInfoBtn" *ngIf="showTabs">
                <button class="btn" (click)="openConfirmDialog()"> Guardar</button>
            </div>
        </div>
        <div class="postInfoBtnFloating" *ngIf="showTabs">
            <button class="btn" (click)="openConfirmDialog()"> Guardar</button>
        </div>
    </mat-card>
</div>

<!-- Permission denied block -->
<div *ngIf="permission===false">
    <div class="forbidden">
        <h2>Acceso Denegado</h2>
    </div>
</div>

<!-- Loading -->
<app-spinner [spinner]="spinner"></app-spinner>