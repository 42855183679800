import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from 'src/environments/environment';
import { Subjects } from '../utils/subjects';
import { ApiService } from './services.service';
import * as moment from 'moment';
import { userInfo } from 'os';


@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private apiService: ApiService,
    private subject: Subjects
  ) {}
  private baseUrl = environment.localUrlBase + 'api/pdv/api-token-auth/';
  //private baseUrl = this.globalService.baseUrl + 'hxc/api/login_token_v2/';
  isLoading: boolean = false;

  login(valueForm: any): Observable<any> {
    return this.http.post<any>(this.baseUrl, valueForm).pipe(
      map((user) => {
        this.isLoading = true;
        console.log(' ONLY USER', user);
        console.log('results??', user.token);

        if (user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          console.log('set userInfo');
          this.saveUser('/login', valueForm.username);
        }
        console.log('return token');
        return user.token;
        // return user.results[0];
      })
    );
  }

  saveUser(path, userName = null) {
    let userInfoLocal;
    try {
      userInfoLocal = JSON.parse(localStorage?.getItem('userInfo'))['user'];
    } catch (ex) {}
    userName = userName != null ? userName : userInfoLocal;

    if (userName == null) {
      console.log("There's not user name");
      return;
    }
    this.apiService.getUserPermission().subscribe((data) => {
      if (data) {
        const userInfo = {
          '@timestamp': moment().format(),
          user: userName,
          permission: data,
          info: this.getBrowserInfo(),
          path: path,
          module: 'pdv',
        };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        setTimeout(() => {
          try {
            this.apiService.setUserinfoToSend(userInfo);
          } catch (error) {
            console.error(
              'Error al enviar la información del usuario: ',
              error
            );
          }
        }, 0);

        // this.apiService.setUserinfoToSend(userInfo);
        this.subject.userPermission$.next(data);
        this.isLoading = false;
      }
    });
  }

  // login(valueForm: any): Observable<any> {
  //   return this.http.post<any>(this.baseUrl, valueForm).pipe(
  //     map((user) => {
  //       this.isLoading = true;
  //       console.log('ONLY USER', user);
  //       console.log('results??', user.token);

  //       if (user.token) {
  //         // store user details and jwt token in local storage to keep user logged in between page refreshes
  //         localStorage.setItem('currentUser', JSON.stringify(user));
  //         console.log('set userInfo');
  //         return user;
  //       } else {
  //         throw new Error('No token received');
  //       }
  //     }),
  //     tap((user) => this.saveUser('/login', valueForm.username))
  //   );
  // }

  // saveUser(path, userName = null): Observable<any> {
  //   return new Observable(observer => {
  //     let userInfoLocal;
  //     try {
  //       userInfoLocal = JSON.parse(localStorage?.getItem('userInfo'))?.['user'];
  //     } catch (ex) {
  //       console.error('Error parsing userInfo from localStorage:', ex);
  //     }
  //     userName = userName != null ? userName : userInfoLocal;

  //     if (userName == null) {
  //       console.log("There's no user name");
  //       observer.error('No user name available');
  //       observer.complete();
  //       return;
  //     }

  //     this.apiService.getUserPermission().subscribe(
  //       (data) => {
  //         if (data) {
  //           const userInfo = {
  //             '@timestamp': moment().format(),
  //             user: userName,
  //             permission: data,
  //             info: this.getBrowserInfo(),
  //             path: path,
  //             module: 'pdv',
  //           };
  //           localStorage.setItem('userInfo', JSON.stringify(userInfo));

  //           setTimeout(() => {
  //             try {
  //               this.apiService.setUserinfoToSend(userInfo);
  //             } catch (error) {
  //               console.error('Error al enviar la información del usuario: ', error);
  //             }
  //           }, 0);
  //           this.subject.userPermission$.next(data);
  //           this.isLoading = false;
  //           observer.next(data);
  //           observer.complete();
  //         } else {
  //           observer.error('No permissions data received');
  //           observer.complete();
  //         }
  //       },
  //       (error) => {
  //         console.error('Error al obtener permisos del usuario:', error);
  //         observer.error(error);
  //         observer.complete();
  //       }
  //     );
  //   });
  // }

  getBrowserInfo() {
    const userAgent = window.navigator.userAgent;
    const browsers = {
      chrome: /chrome/i,
      safari: /safari/i,
      firefox: /firefox/i,
      ie: /internet explorer/i,
    };

    // for (const key in browsers) {
    // 	if (browsers[key].test(userAgent)) {
    // 		return key;
    // 	}
    // };

    let userPlatform = navigator.platform;
    let userLanguage = navigator.language;
    let userCookiesEnabled = navigator.cookieEnabled;
    let userOnlineStatus = navigator.onLine;

    return {
      userAgent: userAgent,
      userPlatform: userPlatform,
      userLanguage: userLanguage,
      userCookiesEnabled: userCookiesEnabled,
      userOnlineStatus: userOnlineStatus,
    };
  }
}
