<!-- RESUME OF THE SUBSIDIARY WITH IMAGES -->
<h4 class="section-title">DOCUMENTACIÓN GENERAL DE EVIDENCIAS</h4>
<div class="card">
    <div class="row content_image">
        <button mat-stroked-button class="dropImageButton" (click)="openDialog()" [disabled]="btnDropImageDisabled">
            <h5 style="color: rgb(126, 126, 130)">+</h5>
        </button>
        <div *ngFor="let photo of currentImages.reverse(); let i=index" style="position: relative; margin-left: 20px;">
            <img style="cursor: pointer; height: 100%; width: 130px; border-radius: 10px;" [src]="photo.image"
                (click)="openImageDialog(photo.image, photo.comment, photo.place)">
            <button class="deleteButton" mat-icon-button>
                <mat-icon aria-label="Delete" (click)="deleteImage(photo)">close</mat-icon>
            </button>
        </div>
    </div>
</div> 


<!-- RESUME GENERAL MARKS -->
<!-- <div>
    Element count: {{ element_count }}
</div> -->
<h4 *ngIf="subsidiaryInfo" class="section-title">RESUMEN TOTAL DE {{nameContext}} / TIENDA {{subsidiaryName}} / TIPO
    {{typeOfSubsidiary}}</h4>

<div class="card total-1">
    <div class="row">

        <div class="col m2">EXHIBIDO:<b>{{totQtyDisplayed}}/{{totQtyDisplayedFinal}}</b></div>
        <div class="col m3">
            <mat-progress-bar class="custom-progress-bar" mode="determinate" [value]="totQtyDisplayedPercent">
            </mat-progress-bar>
        </div>

        <div class="col m1"></div>

        <div class="col m2">TARJETÓN:<b>{{totQtyCard}}/{{totQtyCardFinal}}</b></div>
        <div class="col m3">
            <mat-progress-bar class="custom-progress-bar" mode="determinate" [value]="totQtyCardPercent">
            </mat-progress-bar>
        </div>

    </div>
</div>

<!-- RESUME GENERAL MARKS -->
<div class="card total-2">
    <div class="row">
        <div class="col m4" *ngIf="validate">PARCIAL TOTAL:<b>{{totQtyFinal}}%</b></div>
        <div class="col m4" *ngIf="validate">CRITERIOS DE EXHIBICIÓN:<b>{{totalExhibitionPercent}}%</b></div>

        <div class="col m4">NOTA FINAL:
            <b *ngIf="validate">{{totQtyFinalMark}}%</b>
            <b *ngIf="validate_mark">{{totQtyFinal}}%</b>
        </div>
    </div>

</div>

<!-- EXHIBITION MARKS -->
<h4 class="section-title" *ngIf="validate">TIENDA {{subsidiaryName}} / MESA {{tabName}}</h4>

<div *ngIf="validate" class="card total-3 form-white">
    <div class="row">
        <div class="col m3">
            TOTAL POSICIONES:
            <mat-form-field appearance="fill" [ngStyle]="{ 'border-color': !emptyPositions ? 'red' : '' }">
                <!-- <mat-label>Cantidad</mat-label> -->
                <input matInput type="number" pattern="[0-9]*" [(ngModel)]="totalPositions">
            </mat-form-field>
        </div>
        <div class="col m3">
            POSICIONES VACÍAS:
            <mat-form-field appearance="fill">
                <!-- <mat-label>Cantidad</mat-label> -->
                <input matInput type="number" pattern="[0-9]*" [(ngModel)]="emptyPositions">
            </mat-form-field>
        </div>
        <div class="col m3">
            FUERA DE PORTAFOLIO:
            <mat-form-field appearance="fill">
                <!-- <mat-label>Cantidad</mat-label> -->
                <input matInput type="number" pattern="[0-9]*" [(ngModel)]="outOfPortafolio">
            </mat-form-field>
        </div>
        <div class="col m3" style="display: block;">
            <span>ORDEN DE EXHIBICIÓN:</span>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="%" aria-label="Ingresa" matInput
                       [formControl]="exhibitionMarkControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="exhibitionPercentSelected($event)">
                  <mat-option *ngFor="let option of percentageOptions" [value]="option">
                    {{option}}%
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="exhibitionMarkControl.setValue('')">clear</mat-icon>
              </mat-form-field>              
        </div>
    </div>
    <div class="row">
        <div class="col m12">
            <button class="btn postInfoBtn" (click)="postExhibition()">Calcular Criterio Exhibición</button>
        </div>
    </div>

</div>

<!-- <div class="row" style="display: flex; justify-content:center">
    <div class="row postInfoBtnSave">
        <button class="btn" (click)="onSaveClick()">Guardar</button>
      </div>      
</div> -->


<!-- SEARCH TABLE  -->
<div class="row initial">
    <div class="col m1 field-text">
        <h3 class="title">ESCOGE <b>{{choosePlaceName}}</b></h3>
    </div>
    <div class="col m11">
        <form *ngIf="showPlacesOption">
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Escoge {{choosePlaceName}}" aria-label="Ingresa" matInput
                    [formControl]="placeControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="optionSelected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="placeControl.setValue('')" (click)="clear()">clear</mat-icon>
            </mat-form-field>
        </form>
    </div>
</div>
<hr>
<div *ngIf="showTable">

    <h4 class="section-title">RESUMEN {{nameContext}}</h4>

    <div class="card total-3 h100">
        <div class="">
            <div class="col m6">
                <!-- progress bar -->
                <div class="row padd0">
                    <div class="col">
                        EXHIBIDO:<b>{{totQtyPlaceDisplayed}}/{{totQtyDisplayedPlaceMark -
                            totQtyYellowPointDisplayed -
                            totQtyBluePointDisplayed}}</b></div>
                    <div class="col">
                        <mat-progress-bar class="custom-progress-bar" mode="determinate"
                            [value]="totQtyPlaceDisplayedPercent">
                        </mat-progress-bar>
                    </div>
                </div>

                <div class="row padd0">
                    <div class="col" *ngIf="validate">
                        TARJETÓN:<b>{{totQtyPlaceCard}}/{{totQtyCardPlaceMark -
                            totQtyYellowPointCard -
                            totQtyBluePointCard}}</b></div>
                    <div class="col" *ngIf="validate">
                        <mat-progress-bar class="custom-progress-bar" mode="determinate"
                            [value]="totQtyPlaceCardPercent">
                        </mat-progress-bar>
                    </div>
                </div>
                <!-- /progress bar -->
            </div>
            <div class="col col-2 m6">
                <!-- circles -->
                <div class="row">
                    <div class="col m1 point-container">
                        <div class="point black-color"></div>
                        <div class="text">{{
                            (totQtyPlace*totQtyPlaceTotal) - (totQtyGreenPoint + totQtyYellowPoint + totQtyRedPoint +
                            totQtyBluePoint)
                            }}/{{totQtyPlace*totQtyPlaceTotal}}</div>
                    </div>
                    <div class="col m1 point-container">
                        <div class="point green"></div>
                        <div class="text">{{totQtyGreenPoint}}/{{totQtyPlace*totQtyPlaceTotal}}</div>
                    </div>
                    <div class="col m1 point-container">
                        <div class="point yellow"></div>
                        <div class="text">{{totQtyYellowPoint}}/{{totQtyPlace*totQtyPlaceTotal}}</div>
                    </div>
                    <div class="col m1 point-container">
                        <div class="point red"></div>
                        <div class="text">{{totQtyRedPoint}}/{{totQtyPlace*totQtyPlaceTotal}}</div>
                    </div>
                    <div class="col m1 point-container">
                        <div class="point blue"></div>
                        <div class="text">{{totQtyBluePoint}}/{{totQtyPlace*totQtyPlaceTotal}}</div>
                    </div>
                </div>
                <!-- /circles -->
            </div>

        </div>
    </div>

    <!-- RESUME MARKS -->
    <div class="card total-1 h50">
        <div class="row padd0">
            <div class="col m12">
                PARCIAL <b> {{totQtyPlaceProm}}%</b>
            </div>
        </div>
    </div>
</div>

<app-generic-table [causes]="causes" [actualPlaceId]="actualPlaceId$" [plural_context]="plural_context"
    [context]="context" (countElement)="setCountElement($event)" *ngIf="showTable" [placeID]="placeID"
    [metricID]="metricID" [subsidiary]="subsidiary">
</app-generic-table>


<app-spinner [spinner]="spinner"></app-spinner>