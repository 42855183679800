import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { HttpParams } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import {  AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import 'firebase/database';
import * as moment from 'moment';

@Injectable()
export class ApiService {

  public parameters:any
  
  currentUser: JSON;
  public list_shops_service = [];
  public list_shops_service_left = [];
  //urlBase: string = 'https://rinnolab.cl/';
  urlBase: string = environment.urlBase;
  // urlElasticSearch: string = environment.urlElasticSearch;
  urlElasticSearch: string = environment.urlElasticSearch;

  /* Videos */
  path_node = 'dynamic-v3/videos'
  videoRef: AngularFireList<any>;
  videos: Observable<any[]>

  public queryParams = new HttpParams();
  

  constructor(
    private http: HttpClient, 
    private globalService: GlobalService,
    private db: AngularFireDatabase
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    /** Upload Videos */
    this.videoRef = this.db.list(this.path_node);
    // Use snapshotChanges().map() to object the key
    this.videos = this.videoRef.snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }


  //new Services----------------------------------


  getSubsidiarynames(sales_channel?, internal_id__gt?): Observable<any> {
        
    let url = 'catalogue/api/subsidiary_names/'
    this.parameters = {
      'ordering': 'name'
    };
    if(sales_channel != undefined){
      this.parameters['sales_channel'] = sales_channel
    }
    if(internal_id__gt != undefined){
      this.parameters['internal_id__gt'] = internal_id__gt
    }
    //'organization': '3',
    this.queryParams = new HttpParams({ fromObject:  this.parameters }); 
    return this.http.get(this.urlBase  + url ,{params:this.queryParams});
 }

//https://rinnolab.cl/catalogue/api/subsidiary_names/?organization=3&ordering=name&internal_id__gt=0&sales_channel=1

    getBrands(): Observable<any> {
      return this.http.get(this.urlBase  + 'catalogue/api/provider_names/');
    }


    getModels(id): Observable<any> {
      let url = 'catalogue/api/product_names/'
      this.parameters = {
        'ordering': 'name',
        'brand': id
      };
      this.queryParams = new HttpParams({ fromObject:  this.parameters }); 
      return this.http.get(this.urlBase  + url ,{params:this.queryParams});
    }

    //device_even
    getDeviceEvent(objectDeviceEvent): Observable<any> {
     // console.log(objectDeviceEvent)
      const url = 'devices/api/device_event/list/';
      this.parameters = {
        'limit': objectDeviceEvent.limit,
        'event': objectDeviceEvent.event,
        'info_facets' : objectDeviceEvent.info_facets,
        'parent_equipment_id__isnull':false,
        'segment_id__isnull':false,
        'subsidiary_id__isnull':false
      };
   

      if (objectDeviceEvent.segment_id__in) {
       // console.log('segment_id__in')
        this.parameters['segment_id__in'] = objectDeviceEvent.segment_id__in
       // console.log('parameters',   this.parameters);
      }
        if (objectDeviceEvent.channel_id_in) {
         // console.log('channel_id_in')
          this.parameters['channel_id__in'] = objectDeviceEvent.channel_id_in
         // console.log('parameters',   this.parameters);
        } 

      if (objectDeviceEvent.created__range && objectDeviceEvent.created__range != null) {
        this.parameters['created__range'] = objectDeviceEvent.created__range
       // console.log('parameters',   this.parameters);
      }     
      if(objectDeviceEvent.parent_equipment_id){
        this.parameters['parent_equipment__id'] = objectDeviceEvent.parent_equipment_id
       // console.log('parameters',   this.parameters);
      } 
      if (objectDeviceEvent.model_name) {
        this.parameters['model_name'] = objectDeviceEvent.model_name
       // console.log('parameters',   this.parameters);
      }
      if(objectDeviceEvent.subsidiary_id__in){
        this.parameters['subsidiary_id__in'] = objectDeviceEvent.subsidiary_id__in
       // console.log('parameters',   this.parameters);
      }  
     // console.log(this.queryParams)
      this.queryParams = new HttpParams({ fromObject:  this.parameters }); 
      return this.http.get(this.urlBase + url,{params:this.queryParams});
    }
  
    getEquipmentDetail(objectDeviceEvent:any, id): Observable<any>{
     // console.log(objectDeviceEvent)
      let url = 'devices/api/device_event/list/?limit='+ '1'
      this.parameters = {
        'created__range': objectDeviceEvent.created__range,
        'info_facets' : 'subsidiary_name',
        'ordering' : '-created',
        'event' : 'click',
        'parent_equipment_id__isnull':false,
        'segment_id__isnull':false,
        'subsidiary_id__isnull':false
      };
      if (objectDeviceEvent.channel_id_in != undefined) {
       // console.log('channel_id_in')
        this.parameters['channel_id__in'] = objectDeviceEvent.channel_id_in
       // console.log('parameters',   this.parameters);
      } else {
        let arrayChannelIdString =  JSON.parse(localStorage.getItem('profileUser')).channel.join('__')
        this.parameters['channel_id__in'] = arrayChannelIdString
      }
      if (objectDeviceEvent.segment_id_in != undefined) {
       // console.log('segment_id_in')
        this.parameters['segment_id_in'] = objectDeviceEvent.segment_id_in
       // console.log('parameters',   this.parameters);
      }

      if (objectDeviceEvent.model_name) {
        this.parameters['model_name'] = objectDeviceEvent.model_name
       // console.log('parameters',   this.parameters);
      }
      if (objectDeviceEvent.subsidiary_id__in != undefined) {
        this.parameters['subsidiary_id__in'] = objectDeviceEvent.subsidiary_id__in
       // console.log('parameters',   this.parameters);
      }
      this.queryParams = new HttpParams({ fromObject:  this.parameters }); 
      return this.http.get(this.urlBase + url,{params:this.queryParams});
    }


  //get info user
  getInfoUser(user: any): Observable<any> {
    return this.http.get(this.urlBase + 'assistance/api/employee/?employee=' + user
    ).pipe(
      map((response: Response) => <any>response['results'])
    );
  } 
  getInfoUserToken(): Observable<any> {
    return this.http.get(this.urlBase  + 'assistance/api/employee/?app=agenda'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );
  }

  putEditUserOld(formulario: FormData): Observable<any> {
    return this.http.put<any>(this.urlBase  + 'hxc/api/user_short_edit/' + this.currentUser['user'].id + '/', formulario);
  }

  putEditUser(formulario: FormData): Observable<any> {
    return this.http.post<any>(this.urlBase  + 'hxc/api/user_short_edit_token/', formulario);
  }

  //cambio de contraseña
  changePass(formulario: FormData, idUser): Observable<any> {
    return this.http.put<any>(this.urlBase  + 'hxc/api/change_password/' + idUser + '/', formulario);
  }

  resetPass(formulario: FormData, idUser): Observable<any>  {
    return this.http.put<any>(this.urlBase  + 'hxc/api/reset_password/' + idUser + '/', formulario);
  }

  sendRecoverPassword(body: any) {
    return this.http.post<any>(this.urlBase  + 'hxc/api/password_change_request/', body);
  }

  checkCodeRecover(value: any): Observable<any> {
    return this.http.post<any>(this.urlBase  + 'hxc/api/password_code_check_request/', value);
  }

  getUserData() {
    const api = this.urlBase  + 'hxc/api/user_min/';
    return this.http.get<any>(api).pipe(
      map((response: Response) => <any>response)
    );
  }

  /* Get List Subsidiaries Rest API */
  getSubsidiaries(): Observable<any>{

    return this.http.get(this.urlBase  + 'dynamic/api/subsidiaries_groups/?organization=3&segmentation=1&ordering=-created'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );

  }

  /* Get List Subsidiaries And Trade  Rest API */
  getTradeSubsidiaries(id:number= null): Observable<any>{
    if(id){
      return this.http.get(this.urlBase  + 'dynamic/api/trade/' + id + '/'
      ).pipe(
        map((response: Response) => <any>response)
      );
    }
  }


  /* Get List Subsidiaries And Trade  Rest API */
  getCatalogueSubsidiaries(id:number=null, param: any = null): Observable<any>{
    
    if(param ==  null){
      param = '?q=true'
      param += (id) ? '&catalogue='+id.toString()  : '';
      param += '&is_trade=1';
    }
    
    //// console.log('params', param);
    return this.http.get(this.urlBase  + 'dynamic/api/catalogue_subsidiaries/' + param
    ).pipe(
      map((response: Response) => <any>response)
    );
    
  }

  
  /* Get List Subsidiaries Rest API */
  getGroups(id: number = null, page_size: number = null, is_trade: number = null): Observable<any>{
    let param = '?q=true'
    param += (id) ? '&trade='+id.toString()  : '';
    param += (page_size) ? '&page_size='+ page_size  : '';
    param += (is_trade) ? '&is_trade=1'  : '';
    //// console.log('param', param);
    return this.http.get(this.urlBase  + 'dynamic/api/group/'+param).pipe(
      map((response: Response) => <any>response['results'])
    );
  }

  getGroupsAll(id: any = null, page: number = null, page_size: number= null): Observable<any>{
    
    page = (page) ? page  : 1;
    page_size = (page_size) ? page_size  : 1000;
    id = (id) ? '&catalogue=' + id.toString() : '';
    const param =  '?q=true'+id+'&page='+page+'&page_size='+page_size;

   // console.log('param group', param);
    return this.http.get(this.urlBase  + 'dynamic/api/group/'+param).pipe(
      map((response: Response) => <any>response)
    );

  }

  /* Component Groups*/

  addGroup(form: FormData): Observable<any>{
    return this.http.post<any>(this.urlBase  + 'dynamic/api/group/', form);
  }

  editGroup(id:any, data:any): Observable<any>{
    //console.log('form service', data);
    //console.log('data id',id);
    return this.http.put<any>(this.urlBase  + 'dynamic/api/group/'+id.toString()+'/', data);
  }

  addListShops(name:Array<string>):Array<string>{
    this.list_shops_service = [];
    this.list_shops_service = name;
    return this.list_shops_service;
  }

  getListShops():Array<string>{
    return this.list_shops_service;
  }

  deleteListShops(select_left:Array<string>, select_right:Array<string>):Array<string>{
    this.list_shops_service      = [];
    this.list_shops_service_left = select_left;

    for (var i = 0; i < this.list_shops_service_left.length; ++i) {

      if(select_right.indexOf(this.list_shops_service_left[i][0]) == -1){
        this.list_shops_service.push(this.list_shops_service_left[i]);
      }
    }
    return this.list_shops_service;
  }

  getListGroupRetrive(id:number): Observable<any>{
    const url = this.urlBase  + 'dynamic/api/group/'+id+'/';
    //console.log(url);
    return this.http.get(url
    ).pipe(
      map((response: Response) => <any>response)
    );
  }

  /* Get List Trade Rest API */
  getTrade(): Observable<any>{

    return this.http.get(this.urlBase  + 'dynamic/api/trade/'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );

  }

  /* Get List Trade Rest API */
  getListGroupSubsidiaries(id:any, subsidiaries:any): Observable<any>{
    //console.log('subsidiaries', subsidiaries);
    return this.http.get(this.urlBase  + 'dynamic/api/check_subsidiaries_multi_group/?subsidiaries='+subsidiaries+'&group='+id
    ).pipe(
      map((response: Response) => <any>response)
    );

  }

   /* Component Add-Playlist*/

   addPlaylist(form: any): Observable<any>{
    //console.log('rs_form',form);
    return this.http.post<any>(this.urlBase  + 'dynamic/api/playlist/', form);
  }

  editPlaylist(form: any): Observable<any>{
    
    return this.http.post<any>(this.urlBase  + 'dynamic/api/playlist/', form);
  }

  getItems(params: string = null): Observable<any> {

    const path = this.urlBase  + 'dynamic/api/item/' + params;
    //console.log(path);
    return this.http.get<any>(path);
  }

  /* add ads */
  addItem(form: any): Observable<any>{

    return this.http.post<any>(this.urlBase  + 'dynamic/api/item/', form);
  }

  /* add ads */
  event(param: any): Observable<any>{
    return this.http.get<any>(this.urlBase  + 'dynamic/api/event/' +  param);
  }

  editItem(form: any): Observable<any>{
    //console.log('form', form);
    return this.http.post<any>(this.urlBase  + 'dynamic/api/item/', form);
  }
  /* playlist */
  getPlaylist(id: number = null, page: string = null, page_size: string = null, search_name: string = null, order: string = null): Observable<any> {

    //console.log('id service', id);
    page = (page) ? page  : '';
    page_size = (page_size) ? page_size  : '1000';
    id = (id) ? id  : 0;

    //console.log('id service', id);
    var param = (id) ? ''+id.toString()+'/?page='+page+'&page_size='+page_size  : ((page) ?  '?page='+page+'&page_size='+page_size : (page_size) ? '?page_size='+page_size : '' );
    param = (search_name) ? param+'&name='+search_name+order : param+order;
    
     //console.log(this.urlBase  + 'dynamic/api/playlist/'+ param);
    return this.http.get<any>(this.urlBase  + 'dynamic/api/playlist/' + param);
  }

  getPlaylistLite(id: number = null, page: string = null, page_size: string = null, search_name: string = null, search_id_trade: number = null, order: string = null): Observable<any> {

    //console.log('id service', id);
    page = (page) ? page  : '';
    page_size = (page_size) ? page_size  : '1000';
    id = (id) ? id  : 0;
    order = (order) ? order: '';
    //console.log('search_id_trade', search_id_trade);
    var param = (id) && (page) && (page_size) ? ''+id.toString()+'/?page='+page+'&page_size='+page_size  : ((page) ?  '?page='+page+'&page_size='+page_size : (page_size) ? '?page_size='+page_size : ( (id) ? id.toString() : '' ) );
    //console.log('param1', param);
    param = (search_name) ? param+'&name='+search_name + order : param + order;
    //console.log('param2', param);
    param = (search_id_trade) ? param+'&trade='+search_id_trade : param;
    //console.log('param3', param);
    
    //console.log('url site:  ',this.urlBase  + 'dynamic/api/playlist/'+ param);
    const path = this.urlBase  + 'dynamic/api/playlist_lite/' + param;
    //console.log(path);
    return this.http.get<any>(path);
  }

  getPlaylistAll(): Observable<any> {
    // let param = (id) ? id.toString + '/' : '';
    // return this.http.get<any>(this.urlBase  + 'dynamic/api/playlist/');
    return this.http.get(this.urlBase  + 'dynamic/api/playlist/'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );
  }
  /*   exceptions */
  addExceptions(form: any): Observable<any>{
    return this.http.post<any>(this.urlBase  + 'dynamic/api/exceptions/', form);
  } 

  getExceptions(param: any): Observable<any>{
    return this.http.get<any>(this.urlBase  + 'dynamic/api/exceptions/' + param);
  }

  getPlaylistSubsidiary(id:any): Observable<any>{
    const url = this.urlBase  + 'dynamic/api/playlist_subsidiaries/?playlist=' + id;
    //console.log(url);
    return this.http.get(url
    ).pipe(
      map((response: Response) => <any>response)
    );
  }


  /** templates */

  getTemplates(id: number = null): Observable<any> {
    const param = (id) ? id.toString() + '/' : '';
    //// console.log('param service', param);
    // return this.http.get<any>(this.urlBase  + 'dynamic/api/template/' + id+'/');
    return this.http.get<any>(this.urlBase  + 'dynamic/api/template/' + param);
  }

  /** catalogue */
  /* get list trade rest api */
  getStats(id:number=null): Observable<any>{

    return this.http.get(this.urlBase  + 'catalogue/api/stats_v2/'+ id.toString() + '/'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );

  }

   /** catalogue */
  /* get list trade rest api */
  getStats_(id:number=null): Observable<any>{

    return this.http.get(this.urlBase  + 'catalogue/api/stats_v3/'+ id.toString() + '/'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );

  }

  /** api service videos */
  addItemVideo(obj: any) {
    let key = this.videoRef.push(obj).key;
    //console.log('--  key --');
    //console.log(key);
  }

  updateItem(key: string, params: any) {
    //console.log(key);
    //console.log(params);
    this.videoRef.update(key, params);
  }

  updateObject(key: string, obj: any) {
    //console.log(key);
    //console.log(obj);
    this.videoRef.set(key, obj);
  }
  
  deleteItem(key: string) {
    this.videoRef.remove(key);
  }

  deleteEverything() {
    this.videoRef.remove();
  }

  getCampaign(key: any){
    let query = this.path_node + '/' + key;
    //console.log(query);
    let ref = this.db.object(query).valueChanges();
    return ref;
  }

  /** create video */
  saveVideoLibrary(form: any): Observable<any>{

    return this.http.post<any>(this.urlBase  + 'dynamic/api/video/', form);
  }

  /** get video */
  getVideo(id: number = null): Observable<any> {
    const param = (id) ? id.toString() + '/' : '';
    //// console.log(this.urlBase  + 'dynamic/api/playlist/'+ param);
    return this.http.get<any>(this.urlBase  + 'dynamic/api/video/?ordering=-created');
  }

  deleteVideo(form: any): Observable<any>{

    return this.http.post<any>(this.urlBase  + 'dynamic/api/video/', form);
  }

  /** asociar grupos y playlist */
  savePlaylistGrupo(form: any): Observable<any>{

    return this.http.post<any>(this.urlBase  + 'dynamic/api/group_playlist/', form);
  }

  //duplicate playlist
  duplicatePlaylist(form: any): Observable<any>{
    return this.http.post<any>(this.urlBase  + 'dynamic/api/duplicate_playlist/', form);
  }


  getDevices(filter:string = ''): Observable<any>{
    const url = this.urlBase  + 'devices/api/rinno_device/' + filter;
    //console.log(url);
    return this.http.get<any>(url);
  }

  getDevicesState(filter:string = ''): Observable<any>{
    const url = this.urlBase  + 'devices/api/rinno_device_state/' + filter;
    //console.log(url);
    return this.http.get<any>(url);
  }

  getCatalogue(): Observable<any>{
    const url = this.urlBase  + 'dynamic/api/catalogue_segment/';
    //console.log(url);
    return this.http.get<any>(url);
  }

  //change price
  changePrice(data:any): Observable<any>{
    //console.log('form service', data);
    //console.log('data id',id);
    return this.http.put<any>(this.urlBase  + 'dynamic/api/product_price/', data);
  }

  getApplication(): Observable<any>{
    const url = this.urlBase  + 'hxc/api/application/';
    //console.log(url);
    return this.http.get<any>(url);
  }

  //Global Status Connected
  getElasticSearchAuth(form:any){
    let query = {"grant_type" : "client_credentials"};
    return this.http.post<any>(environment.urlElasticAuth, query);
    // return this.http.post<any>('https://0abd1e5658914e9e9c49a2ea436ce279.us-west1.gcp.cloud.es.io', query);
    
    //// console.log('data elastic', data);

    // return this.http.get<any>(this.urlElasticSearch  + '_search', form);
    // return;
  }

  getElasticSearchData(query:any){
    //return this.http.get(url);    
    //// console.log('url elastic', this.urlElasticSearch, form)
    return this.http.post<any>(this.urlBase  + 'devices/api/elastic_data/', query);

    // return this.http.post<any>(this.urlElasticSearch, form).pipe(map(result => {
    //   return result;
    // }));
  }

  //Get Total Subsidiaries
  
  getTotalSubsidiaries(): Observable<any>{
    let param = '?q=true'
    return this.http.get(this.urlBase  + 'dynamic/api/catalogue_subsidiaries/' + param
    ).pipe(
      map((response: Response) => <any>response)
    );  
  }

  getSegment(value?: any): Observable<any>{
    if(value == 'all'){
      const url = this.urlBase  + 'hxc/api/segment_subsidiaries/?organization';
      return this.http.get<any>(url);
    }else{
      if(value){
       // console.log(value)
        const url = this.urlBase  + 'hxc/api/segment_subsidiaries/?channel='+value+'&ordering=name'; 
        //console.log(url); 'hxc/api/segment_subsidiaries/?channel='+value; 
      return this.http.get<any>(url);
      }else {
        const url = this.urlBase  + 'hxc/api/segment_subsidiaries/?organization='+environment.org+'&ordering=-created';
        //console.log(url);
      return this.http.get<any>(url);
      }
    
    }
  
    
  }

  getBrand(){
    const url = this.urlBase  + 'devices/api/brand/';
    //console.log(url);
    return this.http.get<any>(url);
  }

  getSalesChannel(){
    const url = this.urlBase  + 'hxc/api/sales_channel';
    //console.log(url);
    return this.http.get<any>(url);
  }

  getUpddateDevice(internal_id: any){
    const url = this.urlBase  + 'devices/api/device/'+internal_id;
    //console.log(url);
    return this.http.get<any>(url);
  }

  getDeviceInfo(internal_id: any){
    const url = this.urlBase  + 'devices/api/device_info/'+internal_id;
   // console.log(url);
    return this.http.get<any>(url);
  }

  getUserChannel(){
    const url = this.urlBase  + 'hxc/api/user_channel';
    //console.log(url);
    return this.http.get<any>(url);
  }

  getConfiguration(){
    const url = this.urlBase  + 'hxc/api/application_subsidary2/';

    return this.http.get(url).pipe( map( (resp: any ) => resp.results));
  }

  getDevicesEquipment(filter:string = ''): Observable<any>{
    const url = this.urlBase  + 'devices/api/device_equipment/' + filter;
    //console.log(url);
    return this.http.get<any>(url);
  }



  getDeviceType(){
    const url = this.urlBase  + 'devices/api/device_type/?page_size=1000';

    return this.http.get(url).pipe( map( (resp: any ) => resp.results));
  }

  getSubsidiariesSimply(): Observable<any>{

    return this.http.get(this.urlBase  + 'catalogue/api/subsidiary_simply/?organization=3'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );

  }

  putEditDevice(formulario: any, device_id: any): Observable<any> {
    return this.http.put<any>(this.urlBase  + 'devices/api/device/'+device_id+'/', formulario);
  }

  getStatsCatalogue(id:number=null): Observable<any>{

    return this.http.get(this.urlBase  + 'catalogue/api/stats_v3/'+ id + '/'
    ).pipe(
      map((response: Response) => <any>response['results'])
    );

  }

  // API ENTEL 

  getEntelData(): Observable<any>{
    //https://jsonplaceholder.typicode.com/users
    // const url = this.urlBase  + 'dynamic/api/catalogue_segment/';
    //// console.log(url);
    // return this.http.get<any>(url);
    const url = 'https://rinnolab.cl/ecommerce/api/pdv/';
    // const url = environment.localUrlBase + "api/pdv/";
    return this.http.get<any>(url);
  }

  getEntelSubsidiaryData(params = null): Observable<any>{
    // const url = environment.localUrlBase + 'api/pdv/evaluation_subsidiary/?organization=3';
    const url = environment.localUrlBase + 'api/pdv/evaluation_mark/';
    return this.http.get<any>(url, {params : params});
  }

  getEntelFranchise(): Observable<any>{
    //pdv.rinnolab.cl/api/pdv/evaluation_mark/?franchise=7
    // const url = environment.localUrlBase + 'api/pdv/evaluation_franchise/?organization=3';
    const url = environment.localUrlBase + 'api/pdv/evaluation_mark/';

    return this.http.get<any>(url);
  }

  getFranchise(): Observable<any>{
    const url = environment.localUrlBase + 'api/pdv/franchise/';
    return this.http.get<any>(url);
  }

  // getPdvFranchise(params: any): Observable<any>{
  //   const url = environment.localUrlBase + 'api/pdv/subsidiary/?organization=3&';
  //   return this.http.get<any>(url, {params : params});
  // }


  getEvaluationMark(params: any): Observable<any>{
    const url = environment.localUrlBase + 'api/pdv/evaluation_mark/';
    return this.http.get<any>(url, {params : params});
  }

  getSubsidiaryData(params = null): Observable<any>{
    // const url = 'https://rinnolab.cl/catalogue/api/subsidiary_simply/?organization=3';
    // const url = environment.localUrlBase + "api/pdv/subsidiary/?organization=3";
    const url = environment.localUrlBase + "api/pdv/subsidiary/";

    return this.http.get<any>(url, {params : params});
  }

  getPlaces(params = null){
    const url = environment.localUrlBase + "api/pdv/place/";
   // console.log("api ->", url, params)
    return this.http.get<any>(url, {params : params});
  }

  getCauses(params = null){
    const url = environment.localUrlBase + "api/pdv/cause/";
    return this.http.get<any>(url, {params : params});
  }

  getElementofTable(params : any){
    const url = environment.localUrlBase + "api/pdv/element/";
    return this.http.get<any>(url, {params : params});
  }

  getElementofTableCount(params : any = null){
    const url = environment.localUrlBase + "api/pdv/element/count/";
    return this.http.get<any>(url, {params : params});
  }

  getTotalElementSub(params : any = null){
    const url = environment.localUrlBase + "api/pdv/element/count/"
    return this.http.get<any>(url, {params : params});
  }

  getStatSubsidiary(params: any){
    const url = environment.localUrlBase + "api/pdv/stat/subsidiary/"
    return this.http.get<any>(url, {params : params});
  }

  getChartSubsidiary(params: any){
    const url = environment.localUrlBase + "api/pdv/charts/"
    return this.http.get<any>(url, {params : params});
  }

  postStat(params : any){
    const url = environment.localUrlBase + "api/pdv/stat/";
    return this.http.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  addPlaceImage(params : any){ 
    const url = environment.localUrlBase + "api/pdv/stat/";
    return this.http.post<any>(url, {params : params});
  }

  getIncidences(params: any){
    const url = environment.localUrlBase + "api/pdv/element_check_item/get_incidents/"
    return this.http.get<any>(url,  {params : params});
  }

  updateStat(susbsidiaryID, params){
   console.log("from service params ->", params);
    const url = environment.localUrlBase + "api/pdv/stat/subsidiary_status/" + susbsidiaryID + '/'
    return this.http.post<any>(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getUserPermission(): Observable<any>{
    const url = environment.localUrlBase + 'api/pdv/permission/';
    return this.http.get<any>(url);
  }

  getActualStat(){
    const url = environment.localUrlBase + 'api/pdv/stat_programming'
    return this.http.get<any>(url);
  }

  postNotification(subsidiary: any){ 
    const url = environment.localUrlBase + "api/pdv/notify_subsidiary/";
    console.log("sending notification api ->", url + subsidiary);
    return this.http.post<any>(url, {subsidiary});
  }

  changeStatusIncidenceCheck(itemId: any, isChecked: boolean): Observable<any> {
    const url = environment.localUrlBase + 'api/pdv/element_check_item/' + itemId + '/';
    let params = {
      'is_checked': isChecked
    }
    console.log("URL .> ", url);
    console.log("params ", params);
    return this.http.put<any>(url, params);

  }

  setUserinfoToSend(userInfo){
    console.log("USER INFO: ", userInfo);
    this.sendUserInfo(userInfo).subscribe(
      data => {
        if (data){
          console.log("sending user info to elastic search ", data);
        }
      }, error => {
        console.log("error while sending user info ", error);
      }
    )
  }

  sendUserInfo(params){ 
    const url = environment.localUrlBase + "api/pdv/send_log/";
    return this.http.post<any>(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  


  
}
