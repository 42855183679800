<table mat-table [dataSource]="dataSource7" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="section">
        <th mat-header-cell *matHeaderCellDef> Sección </th>
        <td mat-cell *matCellDef="let element"> {{element.section}} </td>
    </ng-container>

    <ng-container matColumnDef="emptyPosition">
        <th mat-header-cell *matHeaderCellDef> Posiciones Vacías </th>
        <td mat-cell *matCellDef="let element"> {{element.emptyPosition}} </td>
    </ng-container>

    <ng-container matColumnDef="outOfPortfolio">
        <th mat-header-cell *matHeaderCellDef> Fuera de Portafolio </th>
        <td mat-cell *matCellDef="let element"> {{element.outOfPortfolio}} </td>
    </ng-container>

    <ng-container matColumnDef="exhibitionOrder">
        <th mat-header-cell *matHeaderCellDef> Dcto. Orden Exhibición </th>
        <td mat-cell *matCellDef="let element"> {{element.exhibitionOrder}}%</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['section', 'emptyPosition', 'outOfPortfolio', 'exhibitionOrder']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['section', 'emptyPosition', 'outOfPortfolio', 'exhibitionOrder']">
    </tr>
</table>
<div class="info-icon" (mouseover)="showTableTooltip = true" (mouseleave)="showTableTooltip = false">
    <img src="assets/resources/Recurso7@3x.png" alt="Ícono de información" style="width: 30px;">
    <div class="tooltip" *ngIf="showTableTooltip">
        <p style="font-weight: bold;">Equipo:</p>
        <ul>
            <li>- 20% : No cumple orden en todas las mesas.</li>
            <li>- 10% : No cumple orden en una sola mesa.</li>
        </ul>
        <br>
        <p style="font-weight: bold;">Accesorios:</p>
        <ul>
            <li>-  5% : Línea PUA o desorden de exhibición particular.</li>
            <li>- 10% : No cumple orden en todo un panel.</li>
            <li>- 25% : No cumple orden en general.</li>
        </ul>
    </div>
</div>