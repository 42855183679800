<div class="warning-text">
	<p class="">Para una mejor experiencia, por favor navega desde una computadora</p>
</div>
<div class="login">
	<img src="assets/resources/Recurso1@3x.png" class="logo-login">
	<div class="container responsive-title">
		<h1>Foto de <span class="login-text">Éxito</span></h1>
	</div>
	<div class="login-card">
		<form [formGroup]="aFormGroup" name="loginform" id="loginform" (ngSubmit)="login()" action="" method="post"
			ngNativeValidate>
			<!-- <img src="../assets/images/entel-logo.svg" class="login-logo"> -->
			<h4>Credenciales de acceso</h4>
			<!-- <label for="username" class="select-title login-white">Usuario</label> -->
			<!-- <input type="text" formControlName="username" id="username" class="left-login" placeholder="Usuario"> -->
			<div class="custom-input">
				<img src="assets/resources/Recurso11@3x.png" class="logo-log">
				<input type="text" formControlName="username" id="username" class="left-login" placeholder="Usuario">
			</div>

			<!-- <label for="password" class="select-title top login-white">Contraseña</label> -->
			<div class="custom-input">
				<img src="assets/resources/Recurso12@3x.png" class="logo-log">
				<input formControlName="password" class="left-login" type="password" id="password"
					placeholder="Contraseña" value="" #password required>
			</div>

			<div class="row" style="margin-top: 10px;">
				<!-- <div class="column" style="display: flex; align-items: center;">
						<input type="checkbox" id="remember-me" class="check-box" name="remember-me">
						<label for="remember-me">Recuérdame</label>
					</div> -->
				<div class="column">
					<label>
						<input type="checkbox" class="filled-in" checked="checked" (change)="rememberMe($event)" />
						<span class="remember-me">Recuérdame</span>
					</label>
				</div>
				<div class="column">
					<a href="#" class="forgot-password">¿Olvidaste la contraseña?</a>
				</div>
			</div>

			<div class="recuperarPass">
				<!-- <a href="javascript:" routerLink="/recuperar">¿Olvidaste tu contraseña?</a> -->
			</div>
			<div>
				<input formControlName="key" type="hidden" id="key" class="login-input" [(ngModel)]="keyApp" #key
					required>
			</div> <!-- && production === true-->
			<!-- <ngx-recaptcha2 *ngIf="true" #captchaElem [siteKey]="siteKey" (expire)="handleExpire()"
					(success)="handleSuccess($event)" [hl]="'cl'" id="captcha" formControlName="recaptcha"
					class="center-all">
				</ngx-recaptcha2> -->
			<br>
			<br>
			<!-- <button [disabled]="!captchaValid && production === true" type="submit" class="login-btn"
				title="Iniciar Sesión"> Inicia sesión </button> -->
			<button type="submit" class="login-btn"
				title="Iniciar Sesión"> Inicia sesión </button>

			<!-- <button type="submit" class="login-btn" title="Iniciar Sesión"> Iniciar sesión </button> -->
			<p class="alert {{statusClass}}">{{statusService}}</p>

		</form>
	</div>

</div>


<div class="login-hero">
</div>