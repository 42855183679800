import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MetricModel, Subsidiary } from '../utils/metric-data.interface';
import { ApiService } from '../services/services.service';
import { Subjects } from '../utils/subjects';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from 'lodash';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';
import { isPartiallyEmittedExpression } from 'typescript';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router } from '@angular/router';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import 'moment/locale/fr';
import 'moment/locale/es';
import { MatDatepickerModule, MatDatepickerIntl } from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

  ],
})
export class MetricsComponent implements OnInit {

  @Input() spinner: boolean = false;

  myControl = new FormControl('');
  options: string[] = [];
  subsidiaries: Subsidiary[] = [];
  filteredOptions: Observable<Subsidiary[]>;

  showTabs = false;
  showFilterSubs = false;
  flag = true;

  deviceTab = false;
  accesoriesTab = false;
  homeTab = false;
  imageTab = false;
  disabledBtn = false;
  typeOfSubsidiary: string;
  subsidiaryToDelete: number;
  permission = null;
  actualStatSelector: any;
  metricForm = new FormControl(null);
  showMetricForm = false;
  metricID = null;
  isEvaluatedToggle = false;
  isEvaluatedToggleReSend = false;
  subsidiaryIdGlobal;
  dateTimeSelected: Date;


  //CKEDITOR
  public Editor = ClassicEditor;
  public valueEditor = '';
  public Config = {
    toolbar: [
      '|',
      'bold', '|',
      'link', '|',
      'blockQuote', '|',
      'undo', 'redo',
    ],
  };



  constructor(
    private dialog: MatDialog,
    private metricApi: ApiService,
    private snack: MatSnackBar,
    private auth: AuthenticationService,
    private metricData: Subjects,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    dateAdapter.setLocale("en-in");
  }

  ngOnInit(): void {

    this.auth.saveUser('/metrics');

    this.metricData.userPermission$.subscribe(
      data => {
        if (data) {
          let validation: string = data.metrics_view;
          if (validation.includes("TODOS")) {
            this.init()
            this.permission = true;
          } else {
            this.permission = false;
            this.spinner = false;
          }
        }
      }
    )

    this.metricForm.valueChanges.subscribe(
      data => {
        if (data) {
          console.log("daata", data);
          // const $ = this
          let idMetricStat = _.find(this.actualStatSelector, function (obj) {
            const value = data;
            if (obj.name === value) {
              return obj
            }
          });
          this.metricID = idMetricStat.id;
          console.group("metric id", this.metricID)
          this.showTabs = false;
          this.myControl.setValue('');
          this.getSubsidiaries();
        }
      }
    )
  }

  init() {
    this.getCause();
    this.getDevicesPlaces();
    this.metricApi.getActualStat().subscribe(
      data => {
        console.log("month stats = ", data);
        this.actualStatSelector = data;
        this.showMetricForm = true;
        this.metricForm.setValue(this.actualStatSelector[0].name)
      }
    )

    this.metricData.disabledBtn$.subscribe(
      data => {
        this.disabledBtn = data;
      }
    )
  }

  getSubsidiaries() {
    let params = {
      'stat_programming': this.metricID
    }
    this.metricApi.getSubsidiaryData(params).subscribe(
      data => {
        if (data) {
          console.log('metrics init', data);
          this.subsidiaries = data.results;
          console.log("subsidiaries x", this.subsidiaries)
          this.initFiltering();
          this.showFilterSubs = true;
          this.spinner = false;
          console.log('spinner', this.spinner);
        }
      },
      error => {
        //console.log("Metric Data Failed...", error);
      }
    )
  }

  // SERVICES
  getDevicesPlaces() {
    this.metricApi.getPlaces().subscribe(
      data => {
        this.metricData.allPlaces$.next(data);
        console.log("places --> ", this.metricData.allPlaces$.getValue());
      }, error => {
      }
    )
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.resetTabs();
    if (event.index === 0) {
      this.deviceTab = true;
      // console.log("tab changed to -> device");
      // this.metricData.tabManage$.next(true);
    }
    if (event.index === 1) {
      this.accesoriesTab = true;
      // this.metricData.tabManage$.next(true);
      // console.log("tab changed to -> accesories");
    }
    if (event.index === 2) {
      this.accesoriesTab = true;
      this.metricData.tabManage$.next(true);
      // console.log("tab changed to -> home");
    }
    if (event.index === 3) {
      this.accesoriesTab = true;
      this.metricData.tabManage$.next(true);
      // console.log("tab changed to -> image");
    }
  }

  resetTabs(): void {
    // Reset the data or perform any necessary actions to refresh the content
    this.deviceTab = false;
    this.accesoriesTab = false;
  }

  listenerSave() {
    this.metricData.metricDataSubject$.subscribe(
      data => {
        if (data.subsidiary != null) {
          if (this.flag === false) {
            // console.log("primera vez --------->", data);
            this.flag = true;
          } else {
            // console.log("Modify ----------> : ", data);
            // this.postInformation();
          }
        }
      }
    )
  }

  postInformation() {
    this.spinner = true;
    let data = this.metricData.metricDataSubject$.getValue();
    data.comment = this.valueEditor;
    data.is_evaluated = this.isEvaluatedToggle;
    if (this.dateTimeSelected) {
      data.custom_date = this.formatDate(this.dateTimeSelected);
    } else {
      data.custom_date = ""
    }
    const { subsidiary, stat_programming, comment, is_evaluated, device, devices, accessories, accessory, homes, home, images, image, custom_date } = _.cloneDeep(this.metricData.metricDataSubject$.getValue() as MetricModel);
    let postData = { subsidiary, stat_programming, comment, is_evaluated, device, devices, accessories, accessory, homes, home, images, image, custom_date }
    let dataSend = JSON.stringify(postData);
    // delete postData.

    console.log("POST DATAAAA ---->> ", dataSend);
    let element = this.metricData.metricDataSubject$.getValue();
    console.log("metric", element)
    this.metricApi.postStat(dataSend).subscribe(
      data => {
        if (data) {
          this.spinner = false;
          this._toast("Los datos se han guardado correctamente.");
          console.log("DATA SENDED + : ------>>>> ", data);
        }
      }, error => {
        this.spinner = false;
        this._toast("No se han podido guardar los datos");
        // console.log("error sending data : ---->", error);
      }
    );
  }

  getCause() {
    this.metricApi.getCauses().subscribe(
      data => {
        this.metricData.allCauses$.next(data);
      },
      error => {
        //console.log("error getting causes", error);
      }
    )
  }

  initFiltering() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subsidiaries.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    let subsidiary_id = Number(event.option.id);
    this.subsidiaryIdGlobal = subsidiary_id;
    this.subsidiaryToDelete = subsidiary_id;
    // console.log("event ? ", event);
    // console.log("subsidiary number", subsidiary_id);
    let sub = _.find(this.subsidiaries, function (obj) {
      if (obj.id === subsidiary_id) {
        return obj
      }
    });

    this.typeOfSubsidiary = _.get(sub, 'segment', '');

    // console.log("return this segment ", this.typeOfSubsidiary);
    // console.log("return this segment ", sub);
    // this.metricData._nextMetricSub(subsidiary_id);
    this.fillSubsidiary(subsidiary_id, this.metricID);
    this.metricData.currentSubsidiaryName$.next(event.option.value);
    this.showTabs = true;
  }

  fillSubsidiary(subsidiary_id: number, metricId: any) {
    let params = { id: subsidiary_id, stat_programming: metricId }
    this.metricApi.getStatSubsidiary(params).subscribe(
      data => {
        // if you need to reset the data of a subsidiary add this sentence inside the conditional 
        // || params.id == subsidiary_id ;
        if (data == null) {
          data = {
            subsidiary: subsidiary_id,
            stat_programming: metricId,
            devices: [],
            accessories: [],
            homes: [],
            images: [],
            device: {
              partial_mark: 0,
              exhibition_mark: 0,
              final_mark: 0,
            },
            accessory: {
              partial_mark: 0,
              exhibition_mark: 0,
              final_mark: 0,
            },
            home: {
              partial_mark: 0,
              exhibition_mark: 0,
              final_mark: 0,
            },
            image: {
              partial_mark: 0,
              exhibition_mark: 0,
              final_mark: 0,
            },
          }
        }
        console.log("getStatSubsidiary : ", data);
        this.flag = false;
        this.valueEditor = data.comment;
        if (data.custom_date) {
          this.dateTimeSelected = this.setDateFromAPI(data.custom_date);
          console.log("date time setted ", this.dateTimeSelected);
        }
        this.isEvaluatedToggle = data.is_evaluated;
        if (this.isEvaluatedToggle) {
          this.isEvaluatedToggleReSend = true;
        } else {
          this.isEvaluatedToggleReSend = false;
        }
        // first time passing the subsidiary data to the metric data subject
        this.metricData.metricDataSubject$.next(data);
        this.listenerSave();
      },
      error => {
        // console.log("error : ", error);
      }
    )
  }

  _toast(message: string, action: string = null, duration: number = null) {
    action = (action) ? action : 'OK';
    duration = (duration) ? duration : 2500;
    this.snack.open(message, action, {
      duration: duration,
    });
  }

  clear() {
    this.showTabs = false;
    this.dateTimeSelected = null;
  }

  openConfirmDialog() {
    const response = this.dialog.open(NotificationPopupComponent, {
      width: 'auto',
      data: { title: "¿Desea guardar los cambios?" }
    });
    response.afterClosed().toPromise().then(result => {
      if (result === "yes") {
        this.postInformation();
      }
    });
  }

  openNotificationDialog(dialogTitle: any) {
    const response = this.dialog.open(NotificationPopupComponent, {
      width: 'auto',
      data: { title: dialogTitle }
    });
    response.afterClosed().toPromise().then(result => {
      if (result === "yes") {
        this.spinner = true;
        this.metricApi.postNotification(this.subsidiaryIdGlobal).subscribe(
          data => {
            if (data) {
              if (data.status == 'Sent!') {
                this.spinner = false;
                let emailsToSend = "Se ha notificado correctamente a : " + data.target;
                this.postInformation();
                this._toast(emailsToSend, null, 5000);
                console.log("Notification -> ", data);
              } else {
                this._toast("No se ha podido notificar correctamente.");
                console.log("Notification status fail -> ", data);
              }
            }
          }, error => {
            this.spinner = false;
            this._toast("Error al enviar la notificación, contáctese con el administrador");
            console.log("Notification fail -> ", error);
          }
        )
      }
    });
  }

  onToggleNotificationChange() {
    console.log("isEvaluated = ", this.isEvaluatedToggle);
    this.openNotificationDialog("¿Desea notificar que la evaluación de la sucursal ha sido completada? También se guardarán los datos.");
  }

  onToggleRENotificationChange() {
    console.log("isEvaluated = ", this.isEvaluatedToggle);
    this.openNotificationDialog("¿Desea volver a notificar que la evaluación de la sucursal ha sido completada? También se guardarán los datos.");
  }

  onDateSelected(event: any) {
    console.log("before format ", this.dateTimeSelected, event);
    this.dateTimeSelected = event.value;
    this.metricData._nextMetricSubsidiaryDate(this.formatDate(this.dateTimeSelected));
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  setDateFromAPI(date: string): Date {
    const parts = date.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  }

  onDeviceSaveClicked() {
    this.openConfirmDialog();
  }


}


// window.addEventListener("beforeunload", (event) => {
//   console.log("exited?")
//   event.returnValue = true;
// });


