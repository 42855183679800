import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../services/authentication.service';
import { ApiService } from '../services/services.service';
import { Subsidiary } from '../utils/metric-data.interface';
import { Subjects } from '../utils/subjects';
import { MatDialog } from '@angular/material/dialog';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { round } from 'lodash';

export interface TableData {
  id: string;
  name: string;
  isOK: boolean;
  isActive: boolean;
  wasNotified: boolean;
}


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],

})
export class AnalyticsComponent implements OnInit {
  @Input() spinner: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  subsidiaries: Subsidiary[] = [];
  columnsData: string[] = ['code', 'name', 'status', 'isActive', 'wasNotified'];
  dataSource3 = new MatTableDataSource<TableData>();
  countTable = 0;
  setChart = false;
  permission = false;
  flagData = true;
  progress = 0;
  totalChecked = 0;
  totalNotChecked = 0;
  totalItemsShow = 0;

  constructor(
    private metricApi: ApiService,
    private auth: AuthenticationService,
    private metricData: Subjects,
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.auth.saveUser('/analytics');

    this.metricData.userPermission$.subscribe(
      data => {
        if (this.flagData) {
          if (data) {
            // console.log("flag")
            let validation: string = data.analytics_view;
            if (validation.includes("TODOS")) {
              // console.log("includes")
              this.getData();
              this.permission = true;
            } else {
              this.permission = false;
              this.spinner = false;
            }
            this.flagData = false;
          }

        }
      }
    )




  }

  chartOptions = {
    animationEnabled: true,
    title: {
      text: "Evaluación de Tiendas"
    },
    data: [{
      type: "pie",
      startAngle: -90,
      indexLabel: "{name}: {y}",
      yValueFormatString: "#,###.##'%'",
      dataPoints: [
        // { y: 0, name: "" },
        { y: 0, name: "Evaluadas" },
        // { y: 0, name: "" },
        { y: 0, name: "No Evaluadas" },
      ]
    }],
  }

  getData() {
    let params = {
      all : 1
    };
    this.metricApi.getEntelSubsidiaryData(params).subscribe(
      (data) => {
        if (data && data.results.pdv) {
          console.log("calling service analytics", data);
          this.dataSource3.data = data.results.pdv.map((item: any) => {
            const isOK = item.final_mark !== 0;
            if (item.final_mark == 0) {
              this.countTable++;
            }
            const wasNotified = item.notified === 1; 
            return {
              id: item.id,
              name: item.name,
              isOK: isOK,
              isActive: item.is_active,
              wasNotified: item.is_evaluated 
            };
          });
          this.dataSource3.paginator = this.paginator;
          this.dataSource3.sort = this.sort;
          this.spinner = false;
          // Count the number of check_circle icons
          const numberOfCheckIcons = this.dataSource3.data.filter((item: any) => item.isOK).length;
          const totalItems = this.dataSource3.data.length;

          // console.log("evaluadas : ", numberOfCheckIcons);
          // console.log("no evaluadas : ", totalItems - numberOfCheckIcons);
          // console.log("total items :", totalItems);

          let numberOfUncheckedIcons = totalItems - numberOfCheckIcons;
          this.totalChecked = numberOfCheckIcons;
          this.totalNotChecked = totalItems;

          // Calculate percentages
          this.totalItemsShow = this.totalChecked;
          // let checkedPercentage = (numberOfCheckIcons / this.totalChecked) * 100;
          let checkedPercentage = (numberOfCheckIcons / this.totalNotChecked) * 100;
          let uncheckedPercentage = (numberOfUncheckedIcons / this.totalChecked) * 100

          this.progress = round(checkedPercentage);
          // console.log("checked percentage : ", checkedPercentage);
          // console.log("unchecked percentage :", uncheckedPercentage);

          // Update chart dataPoints array
          this.chartOptions.data[0].dataPoints = [
            { y: checkedPercentage, name: "Evaluadas" },
            { y: uncheckedPercentage, name: "No Evaluadas" }
          ];


          // this.chartOptions.data[0].dataPoints = [
          //   { y: numberOfCheckIcons, name: "Evaluadas" },
          //   { y: this.dataSource3.data.length - numberOfCheckIcons, name: "No Evaluadas" }
          // ];
          this.setChart = true;
        }
      },
      (error) => {
        //console.log("Metric Data Failed...", error);
      }
    );
  }

  onSlideToggleChange(event: MatSlideToggleChange, element): void {
    console.warn("checked = ", event.checked);
    let SubsidiaryName = element.name;
    if (event.checked === false && element.isActive === true) {
      console.log("-> ", event.checked);
      const response = this.dialog.open(NotificationPopupComponent, {
        width: 'auto',
        data: { title: "¿Deshabilitar " + SubsidiaryName + '?' }
      });
      response.afterClosed().toPromise().then(result => {
        if (result !== "yes") {
          event.source.checked = true; // Set the toggle back to true if user press "cancel"
          console.log("-> ", event.source.checked);
        } else {
          // USER ACCEPTS TO -> "Deshabilitar"
          this.statusSubsidiary(element.id, event);
          console.log("-> calling service ", element.id, event.checked);
        }
      });
    } else if (event.checked === true) {
      console.log("-> ", event.checked);
      const response = this.dialog.open(NotificationPopupComponent, {
        width: 'auto',
        data: { title: "¿Habilitar Sucursal" + SubsidiaryName + '?' }
      });

      // Prevent the default behavior of the toggle change event if canceled
      response.afterClosed().toPromise().then(result => {
        if (result !== "yes") {
          event.source.checked = false;
          console.log("-> ", event.source.checked);

        } else {
          // USER ACCEPTS TO -> "Habilitar"
          this.statusSubsidiary(element.id, event);
          console.log("-> calling service  ", element.id, event.checked);
        }
      });
    }

  }

  statusSubsidiary(subsidiaryID , statusSubsidiary){
    let params = {
      is_active : statusSubsidiary.checked
    }
    this.metricApi.updateStat(subsidiaryID, params).subscribe(
      data => {
        if (data) {
          let statusText = statusSubsidiary ? 'habilitada!' : 'deshabilitada!';
          this._toast("Sucursal ", statusText);
        }
      },
      error => {
        this._toast("¡ No hemos podido deshabilitar la sucursal !");
        statusSubsidiary.source.checked = true;
      }
    );
  }


  _toast(message: string, action: string = null, duration: number = null) {
    action = (action) ? action : 'OK';
    duration = (duration) ? duration : 2500;
    this.snack.open(message, action, {
      duration: duration,
    });
  }


}
