import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subjects } from 'src/app/utils/subjects';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  public editing = false;
  public editedComment: string;
  public canEdit = false;

  constructor(
    public subject : Subjects,
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      imageUrl: string,
      comment : string,
      place : any,
      plural : any,
      canEdit : boolean,
    }
  ) {
    this.data = data;
    this.editedComment = data.comment;
    if(data.canEdit){
      this.canEdit = data.canEdit;
    }
  }

  ngOnInit(): void {
    console.log("editing value ? ", this.editing);
    console.log("data place form dialog ", this.data.place);
    this.data.place = _.find(this.subject.allPlaces$.getValue(), {
      id : this.data.place
    }).name;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public toggleEdit(): void {
    if (this.editing) {
      this.updateCommentImage()
    }
    this.editing = !this.editing;
  }


  updateCommentImage() {
    let metric = this.subject.metricDataSubject$.getValue();
    for (let item of metric[this.data.plural]) {
      if (item.images) {
        for (let img of item.images) {
          if(this.data.imageUrl == img.image){
            img.comment = this.editedComment;
          }
        }
      }
    }
    console.log("subject ", metric);
  }



}
