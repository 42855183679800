<!-- <img class="image-style" [src]="data.imageUrl">
<div class="content-info">
    <h4>Lugar: {{ data.place }}</h4>
    <h5>Observación: {{ data.comment }}</h5>
</div> -->

<div class="dialog-container">
    <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>

    <img class="image-style" [src]="data.imageUrl">
    <div class="content-info">
        <h4>Lugar: {{ placeName }}</h4>
        <div class="edit-section" [ngClass]="{'editing-mode': editing}">
            <h5>Observación: 
                <span *ngIf="!editing; else editMode">{{ editedComment }}</span>
                <ng-template #editMode>
                    <input [(ngModel)]="editedComment" placeholder="Ingresa nuevo comentario" />
                </ng-template>
            </h5>
            <button *ngIf="canEdit" mat-icon-button class="edit-button" (click)="toggleEdit()">
                <mat-icon>{{ editing ? 'save' : 'edit' }}</mat-icon>
            </button>
        </div>
    </div>
</div>