
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subjects } from 'src/app/utils/subjects';

@Component({
  selector: 'app-edit-image-dialog',
  templateUrl: './edit-image-dialog.component.html',
  styleUrls: ['./edit-image-dialog.component.scss']
})

export class EditImageDialogComponent implements OnInit {

  public editing = false;
  public editedComment: string;
  public canEdit = false;
  public placeName;

  constructor(
    public subject: Subjects,
    public dialogRef: MatDialogRef<EditImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      imageUrl: string,
      comment: string,
      place: any,
      plural: any,
      canEdit: boolean,
    }
  ) {
    this.data = data;
    this.editedComment = data.comment;
    if (data.canEdit) {
      this.canEdit = data.canEdit;
    }
  }

  ngOnInit(): void {
    console.log("editing value ? ", this.editing);
    console.log("data place form dialog ", this.data.place);
    this.placeName = _.find(this.subject.allPlaces$.getValue(), {
      id: this.data.place
    }).name;
    console.log("data place? ", this.placeName);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public toggleEdit(): void {
    if (this.editing) {
      this.updateCommentImage()
    }
    this.editing = !this.editing;
  }


  updateCommentImage() {
    let metric = this.subject.metricDataSubject$.getValue();
    console.log("metric from edit image  item", metric);
    let places = metric[this.data.plural];
    console.log("places from edit image  item", places);
    console.log("data place ", this.data.place);
    let place = _.find(places, item => {
      return item.place_id == this.data.place;
    })
    console.log("place to edit", place);
    for (let item of place.items) {
      if (item.image) {
        console.log("place to edit img", item.image);
        if (this.data.imageUrl == item.image) {
          item.comment = this.editedComment;
        }
      }
    }
    console.log("subject ", metric);
  }



}
